<template>

    <div
        v-if="(stepIsActive || CheckoutStore.checkoutData.steps.payment.data.paymentMethod === paymentMethodId)"
        class="ws-payment-option ws-selector uk-padding-small uk-margin-small-bottom"
        :class="{'selected': CheckoutStore.checkoutData.steps.payment.data.paymentMethod === paymentMethodId, 'inactive': !stepIsActive}"
        @click.prevent="CheckoutStore.checkoutData.steps.payment.data.paymentMethod = paymentMethodId"
    >
        <div class="uk-grid-small uk-flex uk-flex-middle" uk-grid>
            <div class="uk-box-sizing-border ws-width-fixed ws-width-fixed-105" v-html="iconHtml"></div>
            <div class="uk-width-expand"><h3>{{ paymentMethodName }}</h3></div>
        </div>

    </div>
</template>

<script>

import {useCheckoutStore} from "../../../../store/CheckoutStore.js";

export default {
    name: "PaymentOption",
    props: {
        stepIsActive: {
            type: Boolean,
            required: true,
        },
        stepIsFilled: {
            type: Boolean,
            required: true,
        },
        paymentMethodName: {
            type: String,
            required: true,
        },
        paymentMethodId: {
            type: String,
            required: true,
        },
        iconHtml: {
            type: String,
            required: true
        }
    },
    setup() {
        const CheckoutStore = useCheckoutStore()
        return {
            CheckoutStore
        }
    },
}
</script>

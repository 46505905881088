<template>
    <div class="uk-padding-small uk-background-muted ws-cart-summary ws-global-element-border" :uk-sticky="isInCheckout ? 'offset:110px; end: #ws-checkout-cart-summary-sticky-end' : null">
        <h2>{{ $t('text.cart.summary') }}</h2>
        <template v-if="cart.cartPricingRulesInfo && cart.cartPricingRulesInfo.some(info => info.cartInfoText && info.cartInfoText.text.trim() !== '')">
            <div class="uk-padding uk-border-solid uk-border-primary uk-margin-medium-bottom"
                 v-for="(info, index) in cart.cartPricingRulesInfo" :key="index">
                <p
                    class="uk-margin-remove uk-text-small"
                    :class="info.cartInfoText.qaulified ? 'uk-text-success' : 'uk-text-primary'"
                >
                    {{ info.cartInfoText.text }}
                </p>
            </div>
        </template>
        <PriceTotals :cart="this.cart"></PriceTotals>
        <ShippingCountryDropdown />
        <Voucher :cart="this.cart" class="uk-margin-medium-top"></Voucher>
        <div v-if="isInCheckout" class="uk-margin-medium-top">
            <a class="uk-link-text" href="/cart">{{ $t('text.shop.edit_cart') }}</a>
        </div>
        <div class="uk-margin-medium-top">
            <h3>{{ $t('text.shop.payment_types') }}</h3>
            <div class="uk-grid-small uk-child-width-1-4 uk-child-width-1-5@xs uk-child-width-1-6@s uk-child-width-1-8@sm uk-child-width-1-4@m uk-child-width-1-5@l uk-flex uk-flex-middle" uk-grid>
                <div v-html="item.iconHtml" v-for="item in paymentStore.paymentTypes" :key="item.id">
                </div>
            </div>
        </div>
        <div class="uk-margin-medium-top ws-shipping-services">
            <h3>{{ $t('text.shop.shippment_methods') }} ({{ this.cart.deliveryTypeName }})</h3>
            <div v-if="this.cart.ShippingMethods && this.cart.ShippingMethods.length > 0" class="uk-grid-small uk-child-width-1-4 uk-child-width-1-5@xs uk-child-width-1-6@s uk-child-width-1-8@sm uk-child-width-1-4@m uk-child-width-1-5@l uk-flex uk-flex-middle" uk-grid>
                <div v-html="item.iconHtml" v-for="item in this.cart.ShippingMethods" :key="item.id">
                </div>
            </div>
            <div v-else>{{ $t('text.shop.no-cart-items-for-delivery-display') }}</div>
        </div>
        <div v-if="!isInCheckout" class="uk-margin-medium-top">
            <BaseButton :href="'/checkout'" :full-width="true" :id="'toCheckoutButton'">{{ $t('text.shop.checkout') }}</BaseButton>
        </div>
    </div>
</template>

<script>
import BaseButton from "../form/base/BaseButton.vue"
import PriceTotals from "./PriceTotals.vue"
import { usePaymentStore } from "../../store/PaymentStore"
import Voucher from "./Voucher.vue";
import ShippingCountryDropdown from "./ShippingCountryDropdown.vue";

export default {
    name: "Summary",
    components: {
        ShippingCountryDropdown,
        Voucher,
        BaseButton,
        PriceTotals
    },
    created() {
        this.paymentStore.fetchPaymentTypes()
    },
    setup() {
        const paymentStore = usePaymentStore()
        return {
            paymentStore
        }
    },
    props: {
        cart: {
            type: Object,
            required: true
        },
        isInCheckout: {
            type: Boolean,
            default: false
        },

    }
}
</script>

<template>
    <div class="uk-container ws-article-list">
        <div uk-grid>
            <div class="uk-width-1">
                <h1 class="uk-margin-large-top">{{ $t('configurator.articles_list_header') }}</h1>
            </div>
            <ul class="uk-width-1 uk-list uk-margin-bottom">
                <li v-for="article in articlesList" :key="article.Id">
                    <div uk-grid>
                        <div class="uk-width-1-5" v-html="article.ThumbnailHtml"></div>
                        <div class="uk-width-3-5">
                            <h4>{{ article.Bezeichnung }}</h4>
                            <p>{{ article.Artikelnummer }}</p>
                            <p>
                                <i class="fa-solid fa-circle" :class="'ws-' + article.DeliveryState"></i> {{article.DeliveryMessage}}
                            </p>
                            <p>
                                <a href="#" @click.prevent="ConfiguratorStore.postConfiguratorArticleList(article.category, article.Id, 0)">
                                    <i class="fa-solid fa-trash uk-margin-right"></i>{{ $t('configurator.remove_article') }}</a>
                            </p>
                        </div>
                        <div class="uk-width-1-5 uk-text-right uk-position-relative">
                            <p class="uk-h3">
                                <span v-if="article.Count > 1">{{ article.Count }} x</span> {{ article.SinglePriceString }}
                            </p>
                            <p v-if="article.Count > 1">{{ article.TotalPriceString }}</p>
                            <p v-html="$t(article.PriceInfoDescription)"></p>
                            <select
                                v-if="article.multiselect_count > 1"
                                class="uk-select ws-card-article-order-count-select uk-position-bottom-right"
                                @change="ConfiguratorStore.postConfiguratorArticleList(article.category, article.Id, $event.target.value)">
                                <option
                                    v-for="n in 10"
                                    :key="n"
                                    :value="n"
                                    :selected="article.Count === n"
                                >
                                    {{ n }}
                                </option>
                            </select>
                        </div>
                    </div>
                </li>
            </ul>
        </div>

    </div>
</template>

<script>
import {useConfiguratorStore} from "../../store/ConfiguratorStore";

export default {
        name: 'ConfiguratorArticles',
        data() {
            return {
                ConfiguratorStore: useConfiguratorStore(),
                productQuantitySelector: {},

            }
        },
        created() {
            this.productQuantitySelector = { ...this.productCounts };

        },
        computed: {
            articlesList() {
                let products = [];
                for (let category in this.ConfiguratorStore.getArticleList) {
                    const productsWithCategory = this.ConfiguratorStore.getArticleList[category].map(product => {
                        const completeCategory = this.ConfiguratorStore.getCategories.find(c => c.identifier === category)?? null;
                        return {
                            ...product,
                            category: category,
                            multiselect: completeCategory ? completeCategory.multiselect : null,
                            multiselect_count: completeCategory ? completeCategory.multiselect_count : null
                        }
                    });
                    products = products.concat(productsWithCategory);
                }
                return products
            }
        },
        watch: {
            productCounts: {
                handler(newCounts) {
                    this.productQuantitySelector = { ...newCounts };
                },
                deep: true
            },
            ConfiguratorStore: {
                handler(newStore) {
                    this.articleList = {...newStore.articleList };
                },
                deep: true
            }
        }
    }
</script>
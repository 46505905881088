<template>
    <hr class="uk-hr">
    <div>
        <div class="uk-grid-medium" uk-grid>
            <div class="uk-width-1-1 uk-width-1-2@m uk-width-2-3@l">
                <p class="uk-text-bold">{{ $t('text.user.profile.order.order-from-date') }} {{ order.OrderDate }}</p>
                <p>{{ $t('text.user.profile.order.order-number') }}: <span class="uk-text-uppercase">{{ order.OrderNumber }}</span></p>
                <p>
                    {{ $t('text.user.profile.order.order-state') }}:
                    <span
                        :class="'ws-order-state-' + order.OrderState"
                    >
                        {{ $t(String(order.OrderStateText)) }}
                    </span>
                </p>
            </div>
            <div class="uk-width-1-1 uk-width-1-2@m uk-width-1-3@l">
                <p class="uk-text-bold">{{ $t('text.user.profile.order.total-price')}} {{ order.TotalPriceString }}</p>
                <ul class="uk-flex">
                    <li class="ws-order-list--order-item-list--item uk-inline uk-width-1-3" v-for="(item, index) in getItemImageList()">
                        <span v-html="item"></span>
                        <span
                            class="uk-margin-remove uk-position-medium uk-position-cover uk-overlay uk-overlay-default uk-flex uk-flex-center uk-flex-middle ws-additional-order-items"
                            v-if="showAdditionalItemsHint(index) > index"
                        >
                            <span class="uk-text-nowrap uk-text-bold">+ {{ showAdditionalItemsHint(index) }}</span>
                        </span>
                    </li>
                </ul>
            </div>
        </div>
        <div class="uk-flex uk-flex-wrap uk-flex-wrap-around uk-child-width-1-1 uk-child-width-1-3@m uk-child-width-1-4@l">
            <template v-if="order.TrackingLinks">
                <div
                    v-for="(trackingLink, index) in order.TrackingLinks"
                    :key="index"
                    class="uk-margin-medium-top"
                >
                    <a
                        :href="trackingLink"
                        class="uk-link uk-text-secondary"
                        target="_blank"
                    >
                        <i class="fa-light fa-truck-clock"></i>
                        {{ $t('text.user.profile.tracking.tracking_link_name') }}
                    </a>
                </div>
            </template>
            <div v-if="false" class="uk-margin-medium-top">
                <a :href="order.BillUrl">
                    <i class="fa-light fa-file-arrow-down"></i>{{ $t('text.user.profile.order.download-bill') }}
                </a>
            </div>
            <div v-if="usersManuals" class="uk-margin-medium-top">
                <a @click.prevent="openOrder()" class="uk-link uk-text-secondary">
                    <i class="fa-light fa-file-arrow-down uk-margin-small-right"></i>{{ $t('text.user.profile.order.users-manual_download') }}
                </a>
            </div>
            <div class="uk-margin-medium-top">
                <Return :order="order" />
            </div>
            <div class="uk-margin-medium-top">
                <Cancel :order="order" />
            </div>
        </div>
        <div class="uk-margin-large">
            <BaseButton :href="'#'" @click.prevent="openOrder()">{{ $t('text.user.profile.order.view-full-order') }}</BaseButton>
        </div>
    </div>
</template>

<script>
import { useUserProfileStore} from "../../../../store/UserProfileStore";
import OrderItem from "./OrderItem.vue";
import BaseButton from "../../../form/base/BaseButton.vue";
import Return from "../partials/Return.vue";
import Cancel from "../partials/Cancel.vue";
import Tracking from "../partials/Tracking.vue";

export default {
    name: 'Order',
    components: {Cancel, Return, BaseButton, OrderItem, Tracking},
    props: {
        order: {
            type: Object,
            required: true
        },
        isOnOrderList: {
            type: Boolean,
            default: true
        },
        itemLimit: {
            type: Number,
            default: 3
        }
    },
    setup() {
        const userProfileStore = useUserProfileStore()

        return {
            userProfileStore
        }
    },
    computed: {
        usersManuals: function () {
            if ( typeof this.order.Items === 'object') {
                return this.order.Items.filter(item => item.UserManuals.length).length
            } else {
                return false
            }
        }
    },
    methods: {
        scrollToTop() {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        },
        openOrder() {
            if (!this.isOnOrderList) {
                this.userProfileStore.setCurrentTab('order-list')
            }
            this.userProfileStore.setCurrentOrderId(this.order.OrderId)
            this.scrollToTop()
        },

        getItemImageList() {

            if (!this.order.Items) {
                return []
            }

            let images = []

            this.order.Items.forEach(

                (item) => {
                    if (item.Thumbnail && images.length < this.itemLimit) {
                        images.push(item.Thumbnail)
                    }
                }

            )

            return images

        },

        showAdditionalItemsHint(index) {

            if (this.order.Items && this.order.Items.length > this.itemLimit) {

                if ((index + 1) === this.itemLimit) {
                    return (this.order.Items.length - (this.itemLimit - 1))
                }

            }

            return 0
        }
    }
};
</script>

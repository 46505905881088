<template>
    <h2 v-if="headerTest">{{ headerTest }}</h2>
    <hr />
</template>
<script>
export default {
    name: "SubHeader",
    props: {
        headerTest: String
    }
}
</script>
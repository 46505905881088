<template>
    <div class="Contact">
        <form @submit="formSubmit">
            <BaseRadio
                v-model="salutation"
                :options="salutationOptions"
                :error-message="errors.salutation"
                class="uk-margin-medium-bottom uk-margin-remove-top"
                @change="salutationChange"
            />

            <FloatingLabelInput
                v-model="firstname"
                :label="$t('label.contact-form-firstname')"
                @change="firstnameChange"
                type="input"
                :error-message="errors.firstname"
            />

            <FloatingLabelInput
                v-model="lastname"
                :label="$t('label.contact-form-lastname')"
                @change="lastnameChange"
                type="input"
                :error-message="errors.lastname"
            />

            <FloatingLabelInput
                v-model="email"
                :label="$t('label.contact-form-email')"
                @change="emailChange"
                type="input"
                :error-message="errors.email"
            />

            <FloatingLabelInput
                v-model="phone"
                :label="$t('label.contact-form-phone')"
                :required="false"
                @change="phoneChange"
                type="input"
                :error-message="errors.phone"
                :hint="$t('label.contact-form-phone-hint')"
            />

            <BaseSelect
                v-model="requestType"
                :label="$t('label.contact-form-request-type.text')"
                :model-value="requestType"
                :options="requestTypeOptions"
                @change="requestTypeChange"
                :error-message="errors.requestType"
                v-if="showTypeSelect"
            />

            <FloatingLabelInput
                v-model="subject"
                :read-only="subjectReadonly"
                :model-value="subject"
                :label="$t('label.contact-form-subject')"
                @change="subjectChange"
                type="input"
                :error-message="errors.subject"
            />

            <BaseTextarea
                v-model="message"
                :label="$t('label.contact-form-message')"
                @change="messageChange"
                :error-message="errors.message"
            />

            <div v-if="showSubmit" class="uk-margin-small-top">
                <div v-if="isLoading" class="uk-width-1-1 uk-background-muted uk-padding-small uk-margin-medium-top uk-text-center">
                    <span class="uk-preserve" uk-spinner="ratio:1"></span>
                </div>
                <button v-else class="uk-button uk-button-default uk-width-1-1 uk-margin-medium-top" type="submit">
                    <span>{{ $t('label.send-contact-form') }}</span>
                </button>
            </div>
            <div v-else :class="['uk-margin-small-top', resultSuccess ? 'uk-alert-success' : 'uk-alert-danger']" uk-alert>
                <p v-html="resultMmessage"></p>
            </div>
        </form>
    </div>
</template>

<script>
import FloatingLabelInput from "./base/FloatingLabelInput.vue";
import BaseButton from "./base/BaseButton.vue";
import BaseSelect from "./base/BaseSelect.vue";
import BaseRadio from "./base/BaseRadio.vue";
import BaseTextarea from "./base/BaseTextarea.vue";

import {useField, useForm} from "vee-validate"
import {object, string, email, required} from "yup"
import {useI18n} from "vue-i18n";
import ApiService from "../../service/ApiService";

export default {
    name: 'Contact',
    components: {
        BaseSelect,
        BaseButton,
        BaseRadio,
        BaseTextarea,
        FloatingLabelInput
    },
    props: {
        showTypeSelect: {
            type: Boolean,
            default: true
        },
        subjectReadonly: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            isLoading: false,
            showSubmit: true,
            resultMmessage: '',
            resultSuccess: false,
            requestTypeOptions: [
                {
                    value: '',
                    name: 'text.option.request-type.select-type'
                }, {
                    value: 'request-type-product-support',
                    name: 'text.option.request-type.product-support'
                }, {
                    value: 'request-type-order',
                    name: 'text.option.request-type.order'
                }
            ],
            salutationOptions: [
                {
                    value: 'male',
                    name: 'text.option.salutation.male'
                }, {
                    value: 'female',
                    name: 'text.option.salutation.female'
                }, {
                    value: 'diverse',
                    name: 'text.option.salutation.diverse'
                }
            ]
        }
    },
    setup() {

        const {t} = useI18n({})

        const validationSchema = object({
            salutation: string().required(t("text.error.input.required")),
            firstname: string().required(t("text.error.input.required")),
            lastname: string().required(t("text.error.input.required")),
            email: string().required(t("text.error.input.required")).email(t("text.error.input.invalid-email")),
            phone: string(),
            csrfTokenField: string(),
            additionalFormDataField: string(),
            requestType: string().required(t("text.error.input.required")),
            subject: string().required(t("text.error.input.required")),
            message: string().required(t("text.error.input.required"))
        })
        const {handleSubmit, errors} = useForm({
            validationSchema
        })
        const {value: firstname, handleChange: firstnameChange} = useField('firstname')
        const {value: lastname, handleChange: lastnameChange} = useField('lastname')
        const {value: email, handleChange: emailChange} = useField('email')
        const {value: salutation, handleChange: salutationChange} = useField('salutation')
        const {value: phone, handleChange: phoneChange} = useField('phone')
        const {value: requestType, handleChange: requestTypeChange} = useField('requestType')
        const {value: subject, handleChange: subjectChange} = useField('subject')
        const {value: message, handleChange: messageChange} = useField('message')
        const {value: csrfTokenField} = useField('csrfToken')
        const {value: additionalFormDataField} = useField('additionalFormData')

        const sendForm = handleSubmit(values => {
            return ApiService.sendContactMail(values)
        })

        return {
            salutation,
            firstname,
            lastname,
            email,
            phone,
            message,
            requestType,
            subject,
            sendForm,
            errors,
            firstnameChange,
            lastnameChange,
            emailChange,
            salutationChange,
            phoneChange,
            requestTypeChange,
            messageChange,
            subjectChange,
            csrfTokenField,
            additionalFormDataField
        }
    },
    methods: {
        prefillData(token, requestType, subject, additionalData) {
            this.csrfTokenField = token
            this.additionalFormDataField = additionalData
            this.requestType = requestType
            this.subject = subject
            this.showSubmit = true
            this.resultMmessage = ''
            return ApiService.getUserData(true).then(response => {
                if (response.data.user) {
                    let user = response.data.user
                    if (user.loggedIn) {
                        this.salutation = user.Gender ?? ''
                        this.firstname = user.Firstname ?? ''
                        this.lastname = user.Lastname ?? ''
                        this.email = user.Email ?? ''
                        this.phone = user.Phone ?? ''
                    }
                }
            })
        },
        formSubmit(values) {
            this.isLoading = true
            let sendFormPromise = this.sendForm(values)
            if (sendFormPromise) {
                sendFormPromise.then((response) => {
                    if(response.data) {
                        this.resultMmessage = response.data.message
                        this.showSubmit = false
                        this.resultSuccess = response.data.success
                    }
                }).finally(() => {
                    this.isLoading = false
                })
            } else {
                this.isLoading = false
            }
        }
    }
};
</script>

<template>
    <div>
        <div class="uk-grid-small" uk-grid>
            <div class="uk-width-expand uk-flex uk-flex-bottom" v-html="$t('text.shop.subtotal')"></div>
            <div class="uk-width-auto ws-price-default uk-flex uk-flex-bottom">{{ cart.SubTotalPriceString }} *</div>
        </div>
        <div v-if="hasPriceModifications">
            <div v-for="mod in priceModifications" class="uk-grid-small uk-margin-remove-top" uk-grid>
                <div class="uk-width-expand uk-flex uk-flex-bottom ws-price-cart-discount-name" v-html="mod.description"></div>
                <div class="uk-width-auto uk-flex uk-flex-bottom ws-price-cart-discount-price">{{ mod.discount }} *</div>
            </div>
        </div>
        <div class="uk-grid-small" uk-grid>

            <div v-if="cart.FreeShipping" class="uk-width-expand uk-flex uk-flex-bottom">
                {{ cart.FreeShippingName }} {{ $t('text.until') }} {{ (new Date(cart.FreeShippingUntil)).toLocaleDateString(cart.siteLocale.toUpperCase()) }}
            </div>
            <div v-else class="uk-width-expand uk-flex uk-flex-bottom" v-html="$t('text.shop.shipment')"></div>
            <div class="uk-width-auto uk-flex uk-flex-bottom">{{ cart.Shipping }} *</div>
        </div>

        <hr>
        <div class="uk-grid-small" uk-grid>
            <div class="uk-width-expand uk-text-bold uk-flex uk-flex-bottom">{{ $t('text.shop.grandtotal') }}</div>
            <div class="uk-width-auto ws-price-default uk-flex uk-flex-bottom">{{ cart.TotalPriceString }} *</div>
        </div>
        <div class="uk-grid-small" uk-grid>
            <div class="uk-width-expand uk-flex uk-flex-bottom" v-html="$t(String(cart.TaxTotalPriceDescription))"></div>
            <div class="uk-width-auto uk-flex uk-flex-bottom">{{ cart.TaxTotalPriceString }}</div>
        </div>
    </div>
</template>
<script>
import {useCartStore} from "../../store/CartStore";

export default {
    name: "PriceTotals",
    props: {
        cart: {
            type: Object,
            required: true
        }
    },
    setup() {
        const cartStore = useCartStore()
        return {
            cartStore
        }
    },
    computed: {
        priceModifications() {
            if(this.cartStore.cartData.PriceModifications) {
                return this.cartStore.cartData.PriceModifications
            }
            return [];
        },
        hasPriceModifications() {
            if(this.cartStore.cartData.PriceModifications) {
                return this.cartStore.cartData.PriceModifications.length > 0
            }
            return false
        }
    }
}
</script>

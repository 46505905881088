import {createApp} from "vue"
import {createPinia} from "pinia"
import CartMenuIcon from "./components/cart/CartMenuIcon.vue"
import CartButton from "./components/cart/ButtonInput.vue";
import QuestionAboutArticle from "./components/product/QuestionAboutArticle.vue";
import CartFullView from "./components/cart/CartFullView.vue"
import CheckoutView from "./components/checkout/CheckoutView.vue";
import UserMenu from "./components/user/MenuIcon.vue";
import AccountApp from "./components/user/AccountApp.vue";
import SearchAutocomplete from "./components/search/SearchAutocomplete.vue";
import CartDialog from "./components/cart/CartDialog.vue";
import DynamicNavigation from "./components/navigation/DynamicNavigation.vue";
import Spinner from "./components/website/Spinner.vue";
import UserprofileApp from "./components/user/UserprofileApp.vue"
import WishlistButton from "./components/cart/WishlistButton.vue";
import WishlistMenuIcon from "./components/cart/WishlistMenuIcon.vue";
import ShareProductPanel from "./components/product/ShareProductPanel.vue";
import ContactForm from "./components/contact/ContactForm.vue";
import ContactSection from "./components/user/UserProfile/ContactSection.vue";

import "./custom/main.js";

import router from "./router/index.js"

import ConfiguratorApp from "./components/configurator/ConfiguratorApp.vue";

const pinia = createPinia()

import i18n from "./service/Translation.js"

const loadingSpinner = createApp(Spinner).mount('#ws-spinner')

const cId = ((id) => {
    return document.getElementById(id)
})

let userAppElement = cId('user-app');
if(userAppElement) {
    let userApp = createApp(AccountApp);
    userApp.config.globalProperties.csrfToken = userAppElement.getAttribute('data-csrf-token');
    userApp.use(i18n).use(pinia).mount('#user-app');
}

if(cId('shop-cart-menu')) {
    createApp(CartMenuIcon).use(i18n).use(pinia).mount('#shop-cart-menu');
}

if(cId('shop-wishlist-menu')) {
    createApp(WishlistMenuIcon).use(i18n).use(pinia).mount('#shop-wishlist-menu');
}

if(cId('cart')) {
    let cartApp = createApp(CartFullView).use(i18n).use(pinia)
    cartApp.config.globalProperties.loadingSpinner = loadingSpinner
    cartApp.mount('#cart')
}

if(cId('checkout')) {
    let checkoutApp = createApp(CheckoutView).use(i18n).use(pinia)
    checkoutApp.config.globalProperties.loadingSpinner = loadingSpinner;
    checkoutApp.mount('#checkout');
}

if(cId('shop-user-menu')) {
    createApp(UserMenu).use(pinia).mount('#shop-user-menu');
}

if (cId('user-profile-app')) {
    createApp(UserprofileApp).use(i18n).use(pinia).mount('#user-profile-app');
}

if(cId('searchAutocomplete')) {
    let searchAutocomplete = createApp(SearchAutocomplete)
    searchAutocomplete.config.globalProperties.namespace = 'desktop';
    searchAutocomplete.use(i18n).mount('#searchAutocomplete');
}
if(cId('searchAutocompleteMobile')) {
    let searchAutocomplete = createApp(SearchAutocomplete)
    searchAutocomplete.config.globalProperties.namespace = 'mobile';
    searchAutocomplete.use(i18n).mount('#searchAutocompleteMobile');
}

if(cId('contact-form-app')) {
    let tmpContactForm = createApp(ContactForm);
    tmpContactForm.use(i18n);
    tmpContactForm.mount('#contact-form-app');
}


if(cId('navigation-app')) {
    let tmpDynamicNavigation = createApp(DynamicNavigation);
    tmpDynamicNavigation.config.globalProperties.rid = document.getElementById('navigation-app').getAttribute('data-rid');
    tmpDynamicNavigation.use(i18n);
    tmpDynamicNavigation.mount('#navigation-app');
}

if(cId('configurator-app')) {
    const configuratorApp = createApp(ConfiguratorApp);
    configuratorApp.config.globalProperties.configuratorIdentifier = document.getElementById('configurator-app').getAttribute('data-identifier');
    configuratorApp.config.globalProperties.configuratorProductUniqueIdentifier = document.getElementById('configurator-app').getAttribute('data-productIdentifier');
    configuratorApp.use(router).use(pinia).use(i18n).mount('#configurator-app');
}

const ElementListCartButtons = [...document.querySelectorAll('.cart-button')];
ElementListCartButtons.forEach(ElementCartButton => {
    const pid = ElementCartButton.getAttribute('data-pid');

    let showAmountInput = true;

    if(ElementCartButton.hasAttribute('data-show-amount-input')){
        showAmountInput = (ElementCartButton.getAttribute('data-show-amount-input') === "1");
    }

    let tmpApp = createApp(CartButton, { pid, showAmountInput });
    tmpApp.config.globalProperties.singlemode = ElementCartButton.hasAttribute('data-single');
    tmpApp.config.globalProperties.textmode = ElementCartButton.getAttribute('data-textmode');
    tmpApp.config.globalProperties.loadingSpinner = loadingSpinner;
    tmpApp.use(pinia).use(i18n)
    tmpApp.mount(ElementCartButton);
});

const ElementListWishlistButtons = [...document.querySelectorAll('.ws-bookmark-me')];
ElementListWishlistButtons.forEach(ElementWishlistButton => {
    const pid = ElementWishlistButton.getAttribute('data-pid');
    const itemInWishlist = (ElementWishlistButton.getAttribute('data-in-wishlist') === "1");
    const tmpApp = createApp(
        WishlistButton,
        {
            pid: pid,
            itemInWishlist: itemInWishlist
        }
    );
    tmpApp.use(pinia).use(i18n)
    tmpApp.mount(ElementWishlistButton);
});

const ElementListQuestionButtons = [...document.querySelectorAll('.article-question-widget')];
ElementListQuestionButtons.forEach(ElementQuestionButton => {
    let tmpAppQuestionButton = createApp(QuestionAboutArticle);
    tmpAppQuestionButton.config.globalProperties.pid = ElementQuestionButton.getAttribute('data-pid');
    tmpAppQuestionButton.config.globalProperties.csrfToken = ElementQuestionButton.getAttribute('data-csrf-token');
    tmpAppQuestionButton.use(i18n)
    tmpAppQuestionButton.mount(ElementQuestionButton);
});

const ElementShareProductPanel = [...document.querySelectorAll('.share-product-panel')];
ElementShareProductPanel.forEach(element => {
    let tmpAppShareProductPanel = createApp(ShareProductPanel);
    tmpAppShareProductPanel.config.globalProperties.productName = element.getAttribute('data-product-name');
    tmpAppShareProductPanel.use(i18n)
    tmpAppShareProductPanel.mount(element);
});

window.addEventListener("pageshow", function (event) {
    var historyTraversal = event.persisted || (typeof window.navigation != "undefined" && window.navigation.type === 2);
    if (historyTraversal) {
        store.dispatch('fetchCartData')
        store.dispatch('fetchUserData')
    }
})

if(cId('cartDialog')) {
    createApp(CartDialog).use(i18n).use(pinia).mount('#cartDialog');
}

if(cId('contact-section')) {
    createApp(ContactSection).use(i18n).use(pinia).mount('#contact-section');
}
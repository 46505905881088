<template>
    <div>
        <button @click.prevent="cancelEditAddress()" class="uk-modal-close-default uk-float-right" type="button" uk-close></button>
        <h1 class="uk-text-primary uk-text-bolder">{{ $t('text.user.profile.address-data.address_edit') }}</h1>
        <div uk-grid>
            <BaseRadio
                v-model="addressModel.gender"
                :error-message="errors.gender"
                :options="genderOptions"
                :class="['uk-width-1-1']"
            />
            <FloatingLabelInput
                v-model="addressModel.firstname"
                :label="$t('text.user.profile.address-data.firstname_label')"
                :error-message="errors.firstname"
                :class="['uk-width-1-1 uk-margin-small-bottom']"
            />
            <FloatingLabelInput
                v-model="addressModel.lastname"
                :label="$t('text.user.profile.address-data.lastname_label')"
                :error-message="errors.lastname"
                :class="['uk-width-1-1 uk-margin-small-top uk-margin-small-bottom']"
            />
            <FloatingLabelInput
                v-model="addressModel.company"
                :label="$t('text.user.profile.address-data.company_label')"
                :error-message="errors.company"
                :class="['uk-width-1-1 uk-margin-small-top uk-margin-small-bottom']"
                :required="false"
            />
            <FloatingLabelInput
                v-model="addressModel.phone"
                :label="$t('text.user.profile.address-data.phone_label')"
                :error-message="errors.phone"
                :class="['uk-width-1-1 uk-margin-small-top uk-margin-small-bottom']"
                :required="false"
            />
            <BaseSelect
                :label="$t('text.user.profile.address-data.country_label')"
                :error-message="errors.country"
                v-model="addressModel.country"
                :options="userStore.userData.countries"
                :class="['uk-width-1-1 uk-margin-small-bottom']"
            />
        </div>
        <div v-if="addressTypes.length > 1">
            <p class="uk-text-bolder">
                {{ $t('text.user.profile.address-data.address_type') }}
            </p>
            <BaseRadio
                :label="$t('text.user.profile.addressModel-data.address_type_label')"
                :error-message="errors.addressType"
                v-model="addressModel.addressType"
                :options="addressTypes"
                :class="['uk-margin-medium-bottom']"
            />
        </div>
        <div v-if="addressModel.addressType !== 'packstation'" uk-grid="">
            <FloatingLabelInput
                v-model="addressModel.street"
                :custom-element-id="googleAutocompleteInput"
                @vue:mounted="initGoogleAutocompleteService"
                :label="$t('text.user.profile.address-data.street_label')"
                :error-message="errors.street"
                :class="['uk-width-3-4 uk-margin-small-top uk-margin-small-bottom']"
            />
            <FloatingLabelInput
                v-model="addressModel.streetNumber"
                :label="$t('text.user.profile.address-data.streetNumber_label')"
                :error-message="errors.streetNumber"
                :class="['uk-width-1-4 uk-margin-small-top uk-margin-small-bottom']"
            />
            <FloatingLabelInput
                v-model="addressModel.zip"
                :label="$t('text.user.profile.address-data.zip_label')"
                :error-message="errors.zip"
                :class="['uk-width-1-3 uk-margin-small-top uk-margin-small-bottom']"
            />
            <FloatingLabelInput
                v-model="addressModel.city"
                :label="$t('text.user.profile.address-data.city_label')"
                :error-message="errors.city"
                :class="['uk-width-2-3 uk-margin-small-top uk-margin-small-bottom']"
            />
        </div>
        <div class="uk-margin-medium-bottom" v-else-if="addressModel.addressType === 'packstation'">
            <FloatingLabelInput
                v-model="dhlLocation"
                :label="$t('text.user.profile.address-data.dhl_location')"
                :error-message="errors.zip"
                :class="['uk-width-1-1 uk-margin-small-top uk-margin-small-bottom']"
                @input="onInput"
            />
            <div v-if="dhlLocationsList && dhlLocationsList.length > 0" class="uk-background-muted uk-padding-small">
                <p class="uk-text-bolder">
                    {{ $t('text.user.profile.address-data.locker_select_header') }}
                </p>
                <div class="uk-height-medium uk-overflow-auto">
                    <div
                        class="uk-card uk-card-default uk-child-width-1-1 uk-link uk-card-body uk-margin-small uk-margin-remove-bottom"
                        :class="{'uk-card-primary': selectedLocker.id === item.id}"
                        v-for="item in dhlLocationsList" :key="item.id"
                        @click.prevent="selectLocker(item)"
                    >
                        <div class="uk-text-bold">
                            {{ item.name }}
                            <span v-if="(item.containedInPlace != '')"> | {{ item.containedInPlace }}</span> </div>
                        <div :data-id="item.id">{{ item.streetReal }}, {{ item.zip }} {{ item.city }}</div>
                    </div>
                </div>
            </div>
            <div class="uk-height-medium uk-overflow-auto uk-grid-collapse spinner uk-position-relative" v-if="spinner">
                <div uk-spinner="ratio: 3" class="uk-position-center"></div>
            </div>

            <div uk-alert class="uk-alert uk-alert-warning uk-margin-bottom" v-if="dhlLocationsList.length === 0 && !fresh">
                {{ $t('text.user.profile.address-data.no-service-points-found') }}
            </div>
            <div uk-alert class="uk-alert uk-alert-info uk-margin-bottom" v-if="dhlLocationsList.length === 0 && fresh">
                {{ $t('text.user.profile.address-data.type_in_zip_code_text') }}
            </div>
        </div>
        <div class="uk-margin-remove-top" uk-grid>
            <FloatingLabelInput
                v-model="addressModel.streetExtra"
                :label="$t('text.user.profile.address-data.streetExtra_label')"
                :error-message="errors.streetExtra"
                :class="['uk-width-1-1 uk-margin-small-top uk-margin-small-bottom']"
                :required="false"
            />
        </div>
        <div class="uk-margin-remove-top" uk-grid>
            <p>* {{ $t('text.error.input.required') }}</p>
        </div>
        <div class="uk-margin-medium-top" uk-grid>
            <div class="uk-width-expand">
                <BaseCheckbox
                    v-model="addressModel.defaultDeliveryAddress"
                    :label="$t('text.user.profile.address-data.default_delivery_address_label')"
                />
            </div>
            <div class="uk-width-auto">
                <button
                    type="submit" class="uk-button uk-button-default uk-width-1-1"
                    href=""
                    @click.prevent="saveAddresses()"
                >
                    <span>{{ $t('text.user.profile.address-data.save-edit-link') }}</span>
                </button>
            </div>
        </div>
    </div>
</template>
<script>
import {useUserStore} from '../../../../store/UserStore';
import {useCheckoutStore} from '../../../../store/CheckoutStore';
import {notification} from 'uikit';

import {required} from '@vee-validate/rules';

import BaseSelect from '../../../form/base/BaseSelect.vue';
import BaseCheckbox from '../../../form/base/BaseCheckbox.vue';
import BaseRadio from '../../../form/base/BaseRadio.vue';
import FloatingLabelInput from '../../../form/base/FloatingLabelInput.vue';
import googleAutocompleteMixin from "../../../../mixins/googleAutocompleteMixin";

export default {
    name: "addressEditForm",
    mixins: [googleAutocompleteMixin],
    components: {
        BaseCheckbox,
        BaseSelect,
        BaseRadio,
        FloatingLabelInput
    },
    data() {
        return {
            genderOptions: [
                { value: 'male', name: this.$t('text.customer.salutation.male') },
                { value: 'female', name: this.$t('text.customer.salutation.female') },
                { value: 'diverse', name: this.$t('text.customer.salutation.diverse') },
            ],
            userStore: useUserStore(),
            checkoutStore: useCheckoutStore(),
            addressModel: { ...this.address },
            dhlLocation: null,
            dhlLocationsList: [],
            selectedLocker: {},
            spinner: false,
            saved: false,
            errors: {},
            fresh: true,
            debounceTimeout: null,
            googleAutocompleteInput: 'google-autocomplete-address',
        };
    },
    props: {
        address: {
            type: Object,
            required: true
        },
        index: 0
    },
    computed: {
        isAddressTypePackstation() {
            return this.addressModel.addressType !== 'packstation';
        },
        addressTypes() {
            return this.userStore.userData.addressTypes.filter(at => {
                if (at.value !== 'empty' &&
                    this.addressHasPackstation()) {
                    return at;
                }
            });
        },
        googleAutocompleteSelectedCountryCode() {
            return this.addressModel.country;
        },
    },
    watch: {
        'addressModel.addressType': 'handleAddressTypeChange',
        'addressTypes': 'handleAddressTypesChange',
        googleAutocompleteSelectedCountryCode(countryCode) {
            if (this.googelAutocompleteAvailable && countryCode) {
                this.googleAutocompleteChangeCountryCode(countryCode)
            }
        }
    },
    created() {
        this.scrollToTop();
        this.loadAddressModel();
    },
    methods: {
        googleAutocompleteClearAddressForm() {
            this.addressModel.street = null;
            this.addressModel.streetNumber = null;
            this.addressModel.city = null;
            this.addressModel.zip = null;
        },
        googleAutocompleteUpdateAddress() {
            this.addressModel.street = this.googleAutocompleteAddress.street;
            this.addressModel.streetNumber = this.googleAutocompleteAddress.streetNumber;
            this.addressModel.city = this.googleAutocompleteAddress.city;
            this.addressModel.zip = this.googleAutocompleteAddress.zip;
        },
        handleAddressTypesChange(newVal, oldVal) {
            if (newVal.length < 2 && oldVal.length > 0) {
                this.clearAddressFields()
                this.addressModel.addressType = 'standard'
            }
        },
        addressHasPackstation() {
            let dhlPackstation = null;
            for (const countryCode in this.userStore.userData.countries) {
                const country = this.userStore.userData.countries[countryCode];
                if (country.value === this.addressModel.country) {
                    dhlPackstation = country.dhlPackstation;
                    break;
                }
            }
            return dhlPackstation;
        },
        async loadAddressModel() {
            this.addressModel = { ...this.address };

            if (this.addressModel.ServicePoint && this.addressModel.ServicePoint.ServicePointId) {
                this.dhlLocation = this.addressModel.zip;

                await this.loadDhlServicePoints();

                this.selectLockerFromList();
            }
        },
        selectLockerFromList() {
            this.dhlLocationsList.forEach((locker) => {
                if (locker.id === this.addressModel.ServicePoint.ServicePointId) {
                    this.selectedLocker = locker;
                    this.saved = true;
                }
            });
        },
        handleAddressTypeChange(newVal, oldVal) {
            if (newVal !== 'packstation') {
                this.clearSelectedLocker();
            }
        },
        clearSelectedLocker() {
            if (this.saved) {
                this.selectedLocker = {};
                this.dhlLocation = null;
                this.dhlLocationsList = [];
                this.clearAddressFields();
            }
        },
        clearAddressFields() {
            this.addressModel.street = null;
            this.addressModel.streetNumber = null;
            this.addressModel.zip = null;
            this.addressModel.city = null;
            this.addressModel.ServicePoint.ServicePointName = null;
            this.addressModel.ServicePoint.ServicePointId = null;
            this.addressModel.ServicePoint.ServicePointComment = null;
        },
        selectLocker(item)
        {
            this.selectedLocker = item
        },
        onInput() {
            clearTimeout(this.debounceTimeout);
            this.debounceTimeout = setTimeout(this.loadDhlServicePoints, 500);
        },
        async loadDhlServicePoints() {
            this.spinner = true
            this.fresh = false
            try {
                this.dhlLocationsList = await this.checkoutStore.getDhlLocationList({
                    countryCode: 'DE',
                    zip: this.dhlLocation,
                });
            } catch (e) {
                this.dhlLocationsList = []
            } finally {
                this.spinner = false;
            }
        },
        scrollToTop() {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        },
        showNotification(message, status = "success") {
            const timeout = 5000
            notification({
                message: this.$t(message),
                status: status,
                pos: 'top-center',
                timeout: timeout
            })
            setTimeout(() => this.privacyError = false, timeout)
        },
        validateField(fieldName, value, rule) {
            if (!rule(value)) {
                this.errors[fieldName] = this.$t('text.user.profile.address-data.validation_error', { field: this.$t('text.user.profile.address-data.'+fieldName+'_label')});
                return false;
            } else {
                this.errors[fieldName] = null;
                return true;
            }
        },
        cancelEditAddress() {
            this.$emit('cancelEditAddress')
        },
        async saveAddresses() {
            const fieldsToValidate = [
                { name: 'gender', rule: required },
                { name: 'firstname', rule: required },
                { name: 'lastname', rule: required },
                { name: 'zip', rule: required },
                { name: 'city', rule: required },
                { name: 'country', rule: required }
            ];

            if (this.addressModel.addressType === 'packstation') {
                this.saved = true
                this.addressModel.street = this.selectedLocker.streetLabel
                this.addressModel.streetNumber = this.selectedLocker.streetNumber
                this.addressModel.zip = this.selectedLocker.zip
                this.addressModel.city = this.selectedLocker.city
                this.addressModel.country = this.selectedLocker.countryCode
                this.addressModel.ServicePoint.ServicePointName = this.selectedLocker.name
                this.addressModel.ServicePoint.ServicePointId = this.selectedLocker.id
                this.addressModel.ServicePoint.ServicePointComment = this.selectedLocker.containedInPlace
            } else {
                fieldsToValidate.push({ name: 'street', rule: required });
                fieldsToValidate.push({ name: 'streetNumber', rule: required });
            }

            const areAllFieldsValid = fieldsToValidate.every(({ name, rule }) => this.validateField(name, this.addressModel[name], rule));

            if (areAllFieldsValid) {
                try {
                    await this.userStore.saveUserAddress({ index: this.index, ...this.addressModel });
                    this.$emit('cancelEditAddress', 'text.user.profile.address-data.saved-notification');
                } catch (error) {
                    this.$emit('cancelEditAddress', 'text.user.profile.address-data.error_occured', 'warning');
                }
            }
        }
    }
}
</script>
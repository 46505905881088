<template>
    <ul class="uk-breadcrumb">
        <template v-for="tab in tabs">
            <li @click="selectTab(tab.id)" v-if="tab" class="uk-link uk-text-secondary">{{ tab.header }}</li>
        </template>
    </ul>
</template>

<script>

export default {
    name: "BreadCrumb",
    props: {
        tabs: {
            type: Array,
            required: true
        }
    },
    methods: {
        selectTab(id) {
            this.$emit('select-tab', id);
        }
    }
}
</script>
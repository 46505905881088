<template>
    <a @click.prevent="openTracking()" class="uk-link uk-text-secondary">
        <i class="fa-light fa-truck-clock uk-margin-small-right"></i>{{ $t('text.user.profile.tracking.tracking_link_name') }}
    </a>
    <div class="uk-flex-top" uk-modal :id="'trackingOrder'+this.order.OrderId">
        <div class="uk-modal-dialog uk-margin-auto-vertical uk-modal-body">
            <div class="uk-modal-title uk-modal-body">
                <h2>
                    {{ $t('text.user.profile.tracking.order_tracking_header') }}
                </h2>
            </div>
            <div class="uk-modal-body">
                <div v-for="(tracking, index) in trackingItems" :key="index">
                    <pre>{{ tracking }}</pre>
                </div>
            </div>
            <div class="uk-modal-footer uk-child-width-1-2" uk-grid>
                <div class="uk-margin-small-top">
                    <button class="uk-modal-close uk-button uk-button-default uk-button-small" @click.prevent="closeModal()"
                            type="button">
                        {{ $t('text.user.profile.tracking.close_modal') }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import { modal, notification } from 'uikit';
import ApiService from "../../../../service/ApiService";

export default {
    name: 'Tracking',
    data() {
        return {
            trackingItems: []
        }
    },
    props: {
        order: {
            type: Object,
            required: true
        }
    },
    methods: {
        showNotification(message, status = "success") {
            const timeout = 5000
            notification({
                message: this.$t(message),
                status: status,
                pos: 'top-center',
                timeout: timeout
            })
            setTimeout(() => this.privacyError = false, timeout)
        },
        async openTracking() {
            try {
                const response = await this.getOrderTracking()
                if (response.status === 200) {
                    if (response.data.externalTrackingLink) {
                        window.open(response.data.externalTrackingLink, '_blank')
                    } else {
                        this.trackingItems = response.data.trackings
                        modal('#trackingOrder' + this.order.OrderId).show()
                    }
                }
            } catch (error) {
                this.showNotification(error, 'error')
            }
        },
        closeModal() {
            modal('#trackingOrder' + this.order.OrderId).hide();
        },
        async getOrderTracking() {
            return ApiService.getOrderTracking({ 'orderId': this.order.OrderId });
        }
    }
}
</script>
<template>
    <div id="offcanvas-flip" class="ws-cart-offcanvas" uk-offcanvas="flip: true; overlay: false">
        <div class="uk-offcanvas-bar">
            <button class="uk-offcanvas-close" type="button" uk-close></button>

            <div v-if="lastAddedItem" class="uk-grid-medium uk-child-width-1-1" uk-grid>
                <div class="uk-text-center">
                    <div class="uk-margin-medium-top uk-margin-medium-bottom">
                        <i class="ws-hint-icon-big fal fa-cart-circle-check"></i></div>
                    <p class="ws-hint-big">{{ $t('text.shop.added-to-cart') }}</p>
                </div>
                <div class="uk-text-center uk-visible@m">
                    <span v-html="lastAddedItem.PreviewImageHtml"></span>
                </div>
                <div>
                    <h4>{{ lastAddedItem.Bezeichnung }}</h4>
                    <p>{{ lastAddedItem.Artikelnummer }}</p>
                </div>
                <div>
                    <div class="uk-grid-small uk-child-width-1-1 uk-child-width-1-2@m" uk-grid>
                        <div class="uk-text-right uk-text-left@m">{{ $t('text.shop.itemcount') }}: {{ lastAddedItem.Count }}</div>
                        <div class="uk-text-right">
                            <div v-if="lastAddedItem.Count > 1" class="ws-price-light">
                                {{ lastAddedItem.PriceString }} *
                            </div>
                            <div v-else class="ws-price-default">{{ lastAddedItem.TotalPriceString }} *</div>
                        </div>
                    </div>
                    <div v-if="lastAddedItem.Count > 1" class="ws-price-default uk-text-right">{{ lastAddedItem.TotalPriceString }} *</div>

                </div>
                <div>
                    <BaseButton :full-width="true" @click="closeDialog">{{ $t('text.shop.continue-shopping') }}</BaseButton>
                </div>
                <div>
                    <BaseButton :href="cartStore.cartData.cartRoute" :full-width="true">{{ $t('text.shop.to_cart') }}</BaseButton>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {useCartStore} from "../../store/CartStore";
import { offcanvas } from "uikit";
import BaseButton from "../form/base/BaseButton.vue";
import { debounce } from 'lodash';

export default {
    name: "CartDialog",
    data() {
        return {
            lastAddedItem: {},
            timeoutId: null,
            cartStore: useCartStore()
        };
    },
    components: {
        BaseButton
    },
    watch: {
        "cartStore.lastAddedItemTimestamp": {
            handler: function() {
                this.lastAddedItem = this.cartStore.getLastAddedItem;

                const offcanvasElement = offcanvas("#offcanvas-flip");

                if (offcanvasElement) {
                    offcanvasElement.show();
                    this.debouncedHideOffcanvas();
                }
            },
            immediate: true
        }
    },
    methods: {
        closeDialog() {
            const offcanvasElement = offcanvas("#offcanvas-flip");

            if (offcanvasElement) {
                offcanvasElement.hide();
            }
        },
        debouncedHideOffcanvas: debounce(function() {
            const offcanvasElement = offcanvas("#offcanvas-flip");

            if (offcanvasElement) {
                offcanvasElement.hide();
            }
        }, 5000)
    }
}
</script>
import { defineStore } from 'pinia'
import axios from "axios";
import {modal} from "uikit";
import i18n from "../service/Translation.js"

import router from "../router/index.js"

export const useConfiguratorStore = defineStore('ConfiguratorStore', {

    state: () => ({
        configuratorProductId: null,
        configuratorProductUniqueIdentifier: null,
        configuratorIdentifier: null,
        categoriesDone: {},
        alertMessage: {
            success: null,
            message: null
        },
        categories: [],
        non3D: [],
        spinnerButton: {},
        configuratorProduct: {
            Artikelnummer: null,
            Bezeichnung: null,
            DiscountName: null,
            DiscountMessage: null,
            DiscountPriceString: null,
            HasDiscount: null,
            DiscountPercent: null,
            TotalPriceString: null,
            OriginalPriceString: null,
            PriceInfoDescription: null,
            Currency: null,
            Identifier: null,
            Id: null,
            UniqueIdentifier: null
        },
        listImages: [],
        productsAdded: {},
        productsAddedRaw: [],
        articleList: {},
        existingArticleList: {},
        articleListIds: [],
        existingArticleListIds: [],
        entireArticleListIds: [],
        entireArticleListModels: [],
        articlePriceList: {}
    }),

    getters: {
        getConfiguratorProductId: (state) => state.configuratorProductId,
        getconfiguratorProductUniqueIdentifier: (state) => state.configuratorProductUniqueIdentifier,
        getCategoriesDone: (state) => state.categoriesDone,
        alert: (state) => state.alertMessage,
        addedProducts: (state) => state.productsAdded,
        addedProductsRaw: (state) => state.productsAddedRaw,
        getArticleList: (state) => state.articleList,
        getArticleListIds: (state) => state.articleListIds,
        getExistingArticleListIds: (state) => state.existingArticleListIds,
        getEntireArticleListIds: (state) => state.entireArticleListIds,
        getExistingArticleList: (state) => state.existingArticleList,
        getEntireArticleListModels: (state) => state.entireArticleListModels,
        getProductCountFromCategory: (state) => {
            const counts = {}

            for (const [category, products] of Object.entries(state.articleList)) {
                if (!counts[category]) counts[category] = 0
                counts[category] += products.length
            }

            for (const [category, products] of Object.entries(state.existingArticleList)) {
                if (!counts[category]) counts[category] = 0
                counts[category] += products.length
            }

            return counts
        },
        getConfiguratorProductDiscountMessage: (state) => state.configuratorProduct.DiscountMessage,
        getConfiguratorProductDiscountName: (state) => state.configuratorProduct.DiscountName,
        getConfiguratorProductBezeichnung: (state) => state.configuratorProduct.Bezeichnung,
        getConfiguratorProductArtikelnummer: (state) => state.configuratorProduct.Artikelnummer,
        getConfiguratorProductDiscountPriceString: (state) => state.configuratorProduct.DiscountPriceString,
        getConfiguratorProductHasDiscount: (state) => state.configuratorProduct.HasDiscount,
        getConfiguratorProductDiscountPercent: (state) => state.configuratorProduct.DiscountPercent,
        getConfiguratorProductTotalPriceString: (state) => state.configuratorProduct.TotalPriceString,
        getConfiguratorProductOriginalPriceString: (state) => state.configuratorProduct.OriginalPriceString,
        getConfiguratorProductPriceInfoDescription: (state) => state.configuratorProduct.PriceInfoDescription,
        getConfiguratorProductCurrency: (state) => state.configuratorProduct.Currency,
        getListImages: (state) => state.listImages,
        getCategories: (state) => state.categories,
        getNon3D: (state) => state.non3D,
        getAllAvailableConfiguratorProducts: (state) => {
            return state.categories.map(
                category => category.articles.map(
                    article => article.Id
                )
            ).flat()
        },
        getNextUnDoneCategory: (state) => {
            for (let category of state.categories) {
                if (!state.categoriesDone[category.identifier]) {
                    return category.identifier;
                }
            }
            return null;
        },
        getIsCategoryEditable: (state) => (categoryIdentifier) => {
            return true;
            // @todo: just deactivated the category check for staging
            let foundCategory = false;
            for (let category of state.categories) {
                if (category.identifier === categoryIdentifier) {
                    foundCategory = true;
                    break;
                }
                if (!state.categoriesDone[category.identifier]) {
                    return false;
                }
            }
            return foundCategory;
        },
        getLastCategoryIdentifier() {
            if (this.categories.length === 0) {
                return null;
            }
            return this.categories[this.categories.length - 1].identifier;
        },
        getSpinnerButton: (state) => state.spinnerButton,
        getConfiguratorIdentifier: (state) => state.configuratorIdentifier,
        getConfiguratorProductUniqueIdentifier: (state) => state.configuratorProductUniqueIdentifier,
        getArticlePriceList: (state) => state.articlePriceList
    },
    actions: {
        setArticlePriceList(priceList) {
            this.articlePriceList = priceList
        },
        setConfiguratorProduct(product) {
            this.configuratorProduct.Artikelnummer = product.Artikelnummer
            this.configuratorProduct.Bezeichnung = product.Bezeichnung
            this.configuratorProduct.DiscountName = product.DiscountName
            this.configuratorProduct.DiscountMessage = product.DiscountMessage
            this.configuratorProduct.DiscountPriceString = product.DiscountPriceString
            this.configuratorProduct.TotalPriceString = product.TotalPriceString
            this.configuratorProduct.OriginalPriceString = product.OriginalPriceString
            this.configuratorProduct.PriceInfoDescription = product.PriceInfoDescription
            this.configuratorProduct.Currency = product.Currency
            this.configuratorProduct.HasDiscount = product.HasDiscount
            this.configuratorProduct.DiscountPercent = product.DiscountPercent
            this.configuratorProduct.Identifier = product.Identifier
            this.configuratorProduct.Id = product.Id
            this.configuratorProduct.UniqueIdentifier = product.UniqueIdentifier
        },
        setConfiguratorProductId(id) {
            this.configuratorProductId = id
        },
        setConfiguratorProductUniqueIdentifier(uniqueIdentifier) {
            this.configuratorProductUniqueIdentifier = uniqueIdentifier
        },
        setConfiguratorIdentifier(identifier) {
            this.configuratorIdentifier = identifier
        },
        setCategoriesDone(categoriesDone) {
            this.categoriesDone[categoriesDone] = categoriesDone
        },
        removeCategoriesDone(category) {
            this.categoriesDone = {
                ...this.categoriesDone,
                [category]: false
            }
        },
        setAlertMessage(success, message = null) {
            this.alertMessage.success = success
            this.alertMessage.message = message

            setTimeout(() => {
                this.unsetAlertMessage(success, message)
            }, 5000);
        },
        unsetAlertMessage() {
            this.alertMessage.success = null
            this.alertMessage.message = null
        },
        updateProductsAdded(category, productId) {
            if (this.productsAdded[category]) {
                const updatedCategoryProducts = this.productsAdded[category].filter(id => id !== productId)
                this.productsAdded = {
                    ...this.productsAdded,
                    [category]: updatedCategoryProducts
                };
            }
            const indexToRemove = this.productsAddedRaw.findIndex(id => id === productId)
            if (indexToRemove > -1) {
                this.productsAddedRaw.splice(indexToRemove, 1)
            }
        },
        setProductsAdded(category, productId) {
            if (typeof this.productsAdded[category] === 'undefined') {
                this.productsAdded = { ...this.productsAdded, [category]: [] }
            }
            this.productsAdded = {
                ...this.productsAdded,
                [category]: [...this.productsAdded[category], productId]
            };
            this.productsAddedRaw = [...this.productsAddedRaw, productId]
        },
        unsetProductsAdded() {
            this.productsAdded = {}
            this.productsAddedRaw = []
        },

        unsetAll()
        {
            this.productsAdded = {}
            this.productsAddedRaw = []
            this.articleList = {}
            this.existingArticleList = {}
            this.articleListIds = []
            this.existingArticleListIds = []
            this.entireArticleListIds = []
            this.entireArticleListModels = []
        },
        setCategories(categories) {
            this.categories = [...categories]
        },
        setNon3D(non3D) {
            this.non3D = [...non3D]
        },
        setArticleList(articleList) {
            this.articleList = {...articleList}
        },
        setExistingArticleList(existingArticleList) {
            this.existingArticleList = {...existingArticleList}
        },
        addModelToArticleList(model) {
            this.entireArticleListModels.push(model)
        },
        addArticleToArticleListIds(articleId, count = 1) {
            //for (let i = 0; i < count; i++) {
                this.articleListIds.push(articleId)
                if (!this.entireArticleListIds.includes(articleId)) {
                    this.entireArticleListIds.push(articleId)
                }
            //}
        },
        removeArticleFromArticleListIds(articleId) {
            const index = this.articleListIds.indexOf(articleId)
            if (index > -1) {
                this.articleListIds.splice(index, 1)
            }
            if (!this.articleListIds.includes(articleId) && !this.existingArticleListIds.includes(articleId)) {
                const entireIndex = this.entireArticleListIds.indexOf(articleId)
                if (entireIndex > -1) {
                    this.entireArticleListIds.splice(entireIndex, 1)
                }
            }
        },
        removeAllFromArticleListIds(articleId) {
            this.articleListIds = this.articleListIds.filter(id => id !== articleId)
            if (!this.existingArticleListIds.includes(articleId)) {
                this.entireArticleListIds = this.entireArticleListIds.filter(id => id !== articleId)
            }
        },
        addArticleToExistingArticleListIds(articleId, count = 1) {
            //for (let i = 0; i < count; i++) {
                this.existingArticleListIds.push(articleId)
                if (!this.entireArticleListIds.includes(articleId)) {
                    this.entireArticleListIds.push(articleId)
                }
            //}
        },
        removeArticleFromExistingArticleListIds(articleId) {
            const index = this.existingArticleListIds.indexOf(articleId)
            if (index > -1) {
                this.existingArticleListIds.splice(index, 1)
            }
            if (!this.articleListIds.includes(articleId) && !this.existingArticleListIds.includes(articleId)) {
                const entireIndex = this.entireArticleListIds.indexOf(articleId)
                if (entireIndex > -1) {
                    this.entireArticleListIds.splice(entireIndex, 1)
                }
            }
        },
        removeAllFromExistingArticleListIds(articleId) {
            this.existingArticleListIds = this.existingArticleListIds.filter(id => id !== articleId)
            if (!this.articleListIds.includes(articleId)) {
                this.entireArticleListIds = this.entireArticleListIds.filter(id => id !== articleId)
            }
        },
        createFormData(category, productId, articleCount) {
            const formData = new FormData();
            formData.append('category', category);
            formData.append('productId', productId);
            formData.append('articleCount', articleCount);
            return formData;
        },

        handleArticleListResponse(product) {
            this.unsetAll()
            this.setConfiguratorProduct(product)
            if (product.ArticleList) {
                this.setupArticleList(product.ArticleList, 'setArticleList', 'addArticleToArticleListIds');
            }
            if (product.ExistingArticleList) {
                this.setupArticleList(product.ExistingArticleList, 'setExistingArticleList', 'addArticleToExistingArticleListIds');
            }
            this.spinnerButton = {}
            this.setAlertMessage(true, i18n.global.t('configurator.configurator_successfully_saved'))
        },

        setupArticleList(articleList, setterFunction, adderFunction) {
            switch (setterFunction) {
                case'setArticleList':
                    this.setArticleList(articleList)
                    break;
                default:
                    this.setExistingArticleList(articleList)
            }
            Object.keys(articleList).forEach(category => {
                articleList[category].forEach(article => {
                    switch (adderFunction) {
                        case 'addArticleToArticleListIds':
                            this.addArticleToArticleListIds(article.Id);
                            this.addModelToArticleList(article.Artikelnummer)
                            break;
                        default:
                            this.addArticleToExistingArticleListIds(article.Id)
                            this.addModelToArticleList(article.Artikelnummer)
                    }
                    this.setProductsAdded(category, article.Id);
                });
                this.setCategoriesDone(category);
            });
        },

        async redirect(path) {
            try {
                await router.push(path);
            } catch (err) {
                console.error('Router Error:', err);
            }
        },

        async postConfiguratorData(type, category, productId, articleCount = 0) {
            try {
                const response = await axios.post(
                    `/api/configurator/${this.configuratorIdentifier}/${type}/${this.configuratorProductUniqueIdentifier}`,
                    this.createFormData(category, productId, articleCount),
                    {
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded'
                        }
                    }
                );

                if (response.status === 200) {
                    if (!this.configuratorProductUniqueIdentifier) {
                        this.setConfiguratorProductUniqueIdentifier(response.data.UniqueIdentifier)
                        await this.redirect(`/configurator/${this.configuratorIdentifier}/${response.data.UniqueIdentifier}`);
                    }
                    this.handleArticleListResponse(response.data);
                } else {
                    this.setAlertMessage(false, i18n.global.t('configurator.an_error_occurred'));
                }

            } catch (error) {
                console.error('API Call Error:', error);
                this.setAlertMessage(false, i18n.global.t('configurator.an_error_occurred'));
            }
        },

        postConfiguratorExistingArticleList(category, productId, articleCount = 0) {
            this.spinnerButton[productId] = true;
            this.postConfiguratorData('existing-article-list', category, productId, articleCount);
        },

        postConfiguratorArticleList(category, productId, articleCount = 0) {
            this.spinnerButton[productId] = true;
            this.postConfiguratorData('article-list', category, productId, articleCount);
        },

        postConfiguratorRequiredArticleList(id, category, productId, articleCount = 0) {
            this.spinnerButton[productId] = true;
            this.postConfiguratorData('article-list', category, productId, articleCount)
                .then(() => {
                    modal('#req-'+id).hide()
                }).catch(err => {
                console.error(err)
            })
        },

        postConfiguratorRequiredExistingArticleList(id, category, productId, articleCount = 0) {
            this.postConfiguratorData('existing-article-list', category, productId, articleCount)
                .then(() => {
                    modal('#req-'+id).hide()
                }).catch(err => {
                console.error(err)
            })
        },

        async postConfiguratorReturnProductPrices(articlesPayload) {
            try {
                const response = await axios.post(
                    `/api/configurator/${this.configuratorIdentifier}/product-prices`,
                    articlesPayload
                )

                if (response.status === 200) {
                    this.setArticlePriceList(response.data)
                }
            } catch (e) {

            }
        }
    }
})
<template>
    <div class="user-app-newsletter">
        <div class="uk-width-1-1">
            <h1>{{ $t('text.user.profile.account-balance.headline') }}</h1>
        </div>
        <div class="uk-width-1-1">
            <p class="uk-width-1-1">
                {{ $t('text.user.profile.account-balance.explanation.text') }}
            </p>
            <div class="uk-margin uk-margin-large-top" uk-margin uk-grid="">
                <div class="uk-width-1-1 uk-width-1-4@m">
                    <BaseButton
                        :href="'#'"
                        @click="requestAccountBalance"
                        :full-width="true"
                        :loading="loading"
                    >
                        {{ $t('text.user.profile.account-balance.action.request') }}
                    </BaseButton>
                </div>
                <div class="uk-width-1-1">
                    <label>
                        <input class="uk-checkbox" type="checkbox" v-model="privacy">
                        <span
                            class="uk-margin-small-left"
                            v-html="$t('text.user.profile.account-balance.privacy.accept-text')"
                        ></span>
                    </label>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import BaseButton from "../../form/base/BaseButton.vue";
import FloatingLabelInput from "../../form/base/FloatingLabelInput.vue";
import {useUserStore} from "../../../store/UserStore";
import {notification} from "uikit";

export default {
    name: "AccountBalance",
    components: {FloatingLabelInput, BaseButton},
    data() {
        return {
            privacy: false,
            userStore: useUserStore(),
            privacyError: false,
            loading: false
        }
    },
    methods: {
        requestAccountBalance() {
            if (this.privacy) {
                this.loading = true
                this.userStore.requestAccountBalance()
                    .then(() => {
                        this.privacyError = false;
                        this.showNotification('text.user.profile.account-balance.account-balance.request-success.text')
                    })
                    .catch((e) => {
                       console.log(e);
                    })
                    .finally(() => {
                        this.loading = false
                    })
            } else {
                this.privacyError = true;
                this.showNotification('text.user.profile.account-balance.privacy.not_accepted.text', "danger")
            }
            this.privacy = false
        },
        showNotification(message, status = "success") {
            const timeout = 5000
            notification({
                message: this.$t(message),
                status: status,
                pos: 'top-center',
                timeout: timeout
            })
            setTimeout(() => this.privacyError = false, timeout)
        }
    }
}
</script>
<template>
    <div class="uk-margin-medium-bottom">
        <div class="ws-input-floating-label uk-padding-small uk-padding-remove-vertical ws-global-element-border" :class="{'error': !!errorMessage}">
            <select
                :value="modelValue"
                class="uk-select uk-margin-small-top uk-margin-small-bottom"
                :id="uuid"
                :aria-describedby="errorMessage ? uuid + '-error' : null"
                :aria-invalid="errorMessage ? true : null"
                v-bind="{
                    ...$attrs,
                    onChange: ($event) => { $emit('update:modelValue', $event.target.value)}
                }"
            >
                <option v-if="includePleaseChooseOption" value="" disabled selected>{{ $t(pleaseChooseOptionLabel) }}</option>
                <option
                    v-for="option in options"
                    :selected="option.value === modelValue"
                    :key="option.value"
                    :value="option.value"
                >{{ $t(option.name) }}</option>
            </select>
            <label :for="uuid"  class="uk-padding-small uk-padding-remove-vertical">{{ label }}<span v-if="required"><sup>*</sup></span></label>
            <i class="fa fa-chevron-down"></i>
        </div>
        <p
            class="ws-checkout-error-field uk-text-danger"
            v-if="errorMessage"
            :id="uuid + '-error'"
            aria-live="assertive"
        >
            {{ $t(errorMessage) }}
        </p>
    </div>
</template>

<script>
import UniqueID from "../../../includes/UniqueID.js";

export default {
    name: 'BaseSelect',
    props: {
        label: {
            type: String,
            default: ''
        },
        required: {
            type: Boolean,
            default: true
        },
        modelValue: {
            type: [String, Number],
            default: ''
        },
        options: {
            type: Array,
            required: true
        },
        errorMessage: {
            type: String,
            default: ''
        },
        includePleaseChooseOption: {
            type: Boolean,
            default: false
        },
        pleaseChooseOptionLabel: {
            type: String,
            default: 'text.form.select.please-choose'
        }
    },
    setup() {

        const uuid = UniqueID().getID()

        return {
            uuid
        }
    }
};
</script>

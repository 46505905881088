<template>
    <div :class="$attrs.class">
        <div
            class="uk-border-solid uk-border-pill uk-float-left uk-margin-small-right" style="width: 50px"
            :class="{
                'uk-border-muted uk-background-muted': modelValue === 'no',
                'uk-border-primary uk-background-primary': modelValue === 'yes'
            }"
            @click="$emit('update:modelValue',modelValue === 'no' ? 'yes' : 'no')"
        >
            <div
                class="uk-width-1-2 uk-border-circle uk-background-default"
                :class="{
                    'uk-float-left': modelValue === 'no',
                    'uk-float-right': modelValue === 'yes'
                }"
            >
                &nbsp;
            </div>
        </div>
        <label
            v-if="label"
            v-html="label"
            :for="uuid"
            :class="'ws-checkbox-label ' + labelClass"
        ></label>
    </div>
    <p
        class="ws-checkout-error-field uk-text-danger"
        v-if="errorMessage"
        :id="uuid + '-error'"
        aria-live="assertive"
    >
        {{ $t(errorMessage) }}
    </p>
</template>

<script>
import UniqueID from "../../../includes/UniqueID";

export default {
    name: 'BaseSwitch',
    props: {
        label: {
            type: String,
            default: '',
        },
        modelValue: {
            type: String,
            default: false,
        },
        errorMessage: {
            type: String,
            default: '',
        },
        inputClass: {
            type: String,
            default: '',
        },
        labelClass: {
            type: String,
            default: '',
        }
    },
    setup() {
        const uuid = UniqueID().getID()
        return {
            uuid
        }
    }
};
</script>

<template>
    <div
        v-if="CheckoutStore.checkoutData.current === 'confirmation' && mobileSummaryVisible"
        class="uk-width-1-1 uk-position-bottom uk-position-z-index uk-position-fixed uk-background-default uk-hidden@m uk-animation-slide-bottom"
        :id="'mobileSummarySection'"
        :key="fadeKey"
    >
        <div uk-grid class="uk-child-width-1-1">
            <hr>
            <div uk-grid="" class="uk-child-width-1-2 uk-grid uk-grid-margin uk-margin-small">
                <div class="">
                    <p class="uk-margin-small-left uk-text-bolder">{{ $t('text.shop.grandtotal') }}</p>
                </div>
                <div class="uk-text-right">
                    <p class="uk-text-bolder">{{ cart.TotalPriceString }}</p>
                </div>
            </div>
            <hr class="uk-margin-remove">
            <div class="uk-margin-small">
                <div class="uk-margin-small-left">
                    <BaseCheckbox
                        :label="$t('text.checkout.input.terms-accepted')"
                        v-model="CheckoutStore.checkoutData.steps.confirmation.data.termsAccepted"
                    />
                </div>
            </div>
        </div>
        <BaseButton
            :full-width="true"
            :href="'#'"
            @click.prevent="$emit('orderSubmit')"
            class="uk-margin-medium-top"
        >
            {{ $t('text.checkout.button.buy-now') }}
        </BaseButton>
    </div>
</template>

<script>
import {useCartStore} from "../../store/CartStore";
import BaseCheckbox from "../form/base/BaseCheckbox.vue";
import BaseButton from "../form/base/BaseButton.vue";
import {useCheckoutStore} from "../../store/CheckoutStore";

export default {
    name: 'MobileSummary',
    components: {BaseCheckbox, BaseButton},
    props: {
        cart: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            cartStore: useCartStore(),
            CheckoutStore: useCheckoutStore(),
            stepName: 'confirmation',
            mobileSummaryVisible: false,
            fadeKey: 'key'
        }
    },
    mounted() {
        window.addEventListener('scroll', this.checkPosition);
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.checkPosition);
    },
    methods: {
        checkPosition() {
            const buyButtonElement = document.querySelector('#orderConfirmButton');
            if (!buyButtonElement) return;

            const buyButtonPosition = buyButtonElement.getBoundingClientRect();

            this.mobileSummaryVisible = !(buyButtonPosition.top >= 0 && buyButtonPosition.bottom <= window.innerHeight);
        },
    }
}
</script>
<template>

    <div>
        <div class="uk-card uk-card-default uk-card-hover uk-grid-collapse uk-margin ws-card-article" uk-grid>

            <div class="uk-card-body uk-width-1-1 ws-card-article-body">

                <div class="uk-width-1-1 uk-grid-small" uk-grid>
                    <div class="uk-width-expand">
                        <span v-if="wishlistItem.hasDiscount" class="ws-discount ws-discount--percentage">%</span>
                    </div>
                    <div class="uk-width-auto">
                        <WishlistButton
                            :item-in-wishlist="true"
                            :wishlist-view="true"
                            :pid="wishlistItem.Id"
                        ></WishlistButton>
                    </div>
                </div>

                <div class="uk-cover-container uk-width-1-1 uk-flex uk-flex-middle ws-article-thumbnail">
                    <span v-if="wishlistItem.ListImageHtml" v-html="wishlistItem.ListImageHtml"></span>
                    <a v-if="wishlistItem.ListImageHtml" :href="wishlistItem.DetailUrl" class="uk-position-cover" :title="wishlistItem.bezeichnung"></a>
                </div>

                <div class="uk-width-1-1">
                    <a class="uk-link-reset" :href="wishlistItem.DetailUrl" :title="wishlistItem.bezeichnung">
                        <div>
                            <h4>{{ wishlistItem.bezeichnung }}</h4>

                            <div
                                v-if="wishlistItem.BeschreibungKurz"
                                class="ws-article-description-short"
                                v-html="wishlistItem.BeschreibungKurz"
                            ></div>

                        </div>
                    </a>
                </div>

            </div>

            <div class="uk-width-1-1 ws-card-article-order-info">

                <div>
                    <div v-if="wishlistItem.deliveryState">

                        <i class="fa-solid fa-circle" :class="'ws-' + wishlistItem.deliveryState"></i> <span v-if="wishlistItem.deliveryMessage" class="ws-available-info" v-html="wishlistItem.deliveryMessage"></span>

                    </div>
                </div>

                <div class="uk-flex uk-flex-bottom uk-grid-small" uk-grid>
                    <div class="uk-width-1-1 uk-width-expand@l">

                        <div v-if="wishlistItem.hasDiscount" class="ws-price-reduced">
                            {{ wishlistItem.OriginalPriceString }}
                        </div>

                        <div class="ws-price-default">
                            {{ wishlistItem.PriceString }}
                        </div>

                    </div>
                    <div class="ws-wishlist-button-container uk-width-auto@l cart-button">
                        <ButtonInput :pid="wishlistItem.Id" :is-in-wishlist="true"></ButtonInput>
                    </div>

                </div>

            </div>

        </div>
    </div>

</template>

<script>

import ButtonInput from "../../../cart/ButtonInput.vue";
import WishlistButton from "../../../cart/WishlistButton.vue";

export default {
    name: 'WishlistSingleItemCardView',
    components: {WishlistButton, ButtonInput},

    props: {
        wishlistItem: {
            type: Object,
            required: true,
        }
    },
};

</script>

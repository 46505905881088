<template>
    <div class="uk-cover-container">
        <p class="uk-text-center">
            <i class="fa-light fa-cart-shopping fs-lg"></i>
            <span v-if="cartStore.cartItemsCount > 0" class="uk-badge">{{ cartStore.cartItemsCount }}</span>
            <br />
            <span class="uk-text-small uk-visible@m" v-html="$t('text.menu-subtext.cart')"></span>
        </p>
        <a :href="cartStore.cartData.cartRoute" class="uk-position-cover"></a>
    </div>
    <SmallOverview />
</template>

<script>
import SmallOverview from "./SmallOverview.vue"
import {useCartStore} from "../../store/CartStore"
import {useCheckoutStore} from "../../store/CheckoutStore";

export default {
    name: "CartMenuIcon",
    data() {
        return {
            cartStore: useCartStore(),
            checkoutStore: useCheckoutStore()
        }
    },
    created() {
        this.cartStore.fetchCartData().then((data) => {
        }).catch(error => {
            console.log(error)
        })
    },
    setup() {
        const cartStore = useCartStore()
        return {
            cartStore
        }
    },
    components: {SmallOverview}
}
</script>
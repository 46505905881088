<template>
    <div v-for="item in this.itemList" :key="item.Id" class="uk-grid-medium" uk-grid>
        <hr>
        <div class="uk-width-1-5">
            <a :href="item.DetailUrl" class="uk-link-reset" v-html="item.ListImageHtml">
            </a>
        </div>
        <div class="uk-width-4-5" uk-grid>
            <div class="uk-width-3-4">
                <a :href="item.DetailUrl" class="uk-link-reset">
                    <h4 class="uk-text-small@s">{{ item.Bezeichnung }}</h4>
                    <ul v-if="item.productType === ProductConstants.productType.configurator" class="uk-list">
                        <li v-for="subItem in item.articleList" :key="subItem.Id">
                            <div class="uk-grid-small" uk-grid>
                                <div class="uk-width-auto">{{subItem.count}} x</div>
                                <div class="uk-width-expand">{{ subItem.Bezeichnung }}</div>
                            </div>
                        </li>
                    </ul>
                    <p v-if="item.productType === ProductConstants.productType.standard">{{ item.Artikelnummer }}</p>
                    <p>{{ item.GewichtBrutto }}</p>
                    <div class="uk-width-1-1 ws-card-article-order-info">
                        <div>
                            <i class="fas fa-circle uk-margin-small-right" :class="['ws-' + item.OSAvailabilityInfo.deliveryState]" />
                            <span class="uk-text-small uk-margin-medium-right" v-html="item.OSAvailabilityInfo.deliveryMessage"></span>
                            <br class="uk-hidden@l" />
                            <span class="uk-text-small uk-text-danger" v-if="item.DeliveryType && item.DeliveryType !== CartConstants.DeliveryStandard">
                                <i v-if="item.DeliveryType === CartConstants.DeliveryBulk" class="fa-light fa-dolly" />
                                <i v-else class="fa-light fa-truck" />
                                {{ item.DeliveryTypeHint }}
                            </span>
                        </div>
                    </div>
                </a>
            </div>
            <div class="uk-width-1-4">
                <div class="uk-visible@m">
                    <div v-if="item.hasDiscount" class="uk-text-right@s uk-margin-small-bottom">
                        <div v-for="ruleName in item.PriceRules" class="uk-margin-small-bottom">
                            <span class="ws-discount ws-global-element-border">{{ ruleName }}</span>
                        </div>
                        <span class="ws-price-reduced">{{ item.OriginalPriceString }}</span> *
                    </div>
                    <div class="ws-price-default uk-text-right@s">{{ item.TotalPriceString }} *</div>
                    <div v-if="item.Count > 1" class="ws-price-light uk-text-right@s">
                        {{ item.PriceString }} *
                    </div>
                </div>
                <div class="uk-hidden@m">
                    <button class="uk-button uk-button-text uk-width-auto@s uk-text-right" @click="removeFromCart(item.ItemKey)"><i
                        class="fa-light fa-trash-alt"></i><span
                        class="ws-spacer-text uk-visible@m"></span><span class="uk-visible@m">{{ $t('text.shop.remove_article') }}</span>
                    </button>
                    <button class="uk-button uk-button-text uk-width-auto@s uk-text-right"><i class="fal fa-bookmark"></i><span
                        class="ws-spacer-text uk-visible@m"></span><span class="uk-visible@m">{{ $t('text.shop.to_wishlist') }}</span>
                    </button>
                    <button v-if="item.productType === ProductConstants.productType.configurator"
                            class="uk-button uk-button-text uk-width-auto@s uk-text-right"
                            @click="openConfigurator(item)">
                        <i class="fa-light fa-pencil"></i>
                        <span class="ws-spacer-text uk-visible@m"></span><span class="uk-visible@m">{{ $t('text.edit') }}</span>
                    </button>
                </div>
            </div>
            <div class="uk-width-1-2 uk-width-expand@m">
                <div class="uk-visible@m">
                    <button class="uk-button uk-button-text uk-width-auto@s uk-text-right uk-margin-medium-right@m" @click="removeFromCart(item.ItemKey)"><i
                        class="fa-light fa-trash-alt"></i><span
                        class="ws-spacer-text uk-visible@m"></span><span class="uk-visible@m">{{ $t('text.shop.remove_article') }}</span>
                    </button>
                    <button class="uk-margin-medium-left uk-button uk-button-text uk-width-auto@s uk-text-right"><i class="fal fa-bookmark"></i><span
                        class="ws-spacer-text uk-visible@m"></span><span class="uk-visible@m">{{ $t('text.shop.to_wishlist') }}</span>
                    </button>
                    <button v-if="item.productType === ProductConstants.productType.configurator"
                            class="uk-margin-medium-left uk-button uk-button-text uk-width-auto@s uk-text-right"
                            @click="openConfigurator(item)">
                        <i class="fa-light fa-pencil"></i>
                        <span class="ws-spacer-text uk-visible@m"></span><span class="uk-visible@m">{{ $t('text.edit') }}</span>
                    </button>
                </div>
                <div class="uk-text-right@s uk-hidden@m">
                    <AmountInput
                        :initial-count="item.Count"
                        @onChange="(count) => { updateCart(item.Id, count) }"
                    />
                </div>
            </div>
            <div class="uk-width-1-2 uk-width-1-4@m uk-text-right uk-text-left@m">
                <div class="uk-text-right@s uk-visible@m">
                    <AmountInput
                        :initial-count="item.Count"
                        :set-text-mode="true"
                        @onChange="(count) => { updateCart(item.Id, count) }"
                    />
                </div>
                <div class="uk-hidden@m">
                    <div v-if="item.hasDiscount" class="uk-text-right@s uk-margin-small-bottom">
                        <div v-for="ruleName in item.PriceRules" class="uk-margin-small-bottom">
                            <span class="ws-discount ws-global-element-border">{{ ruleName }}</span>
                        </div>
                        <span class="ws-price-reduced">{{ item.OriginalPriceString }}</span> *
                    </div>
                    <div class="ws-price-default uk-text-right@s">{{ item.TotalPriceString }} *</div>
                    <div v-if="item.Count > 1" class="ws-price-light uk-text-right@s">
                        {{ item.PriceString }} *
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-if="this.itemList === undefined" class="uk-text-center">
        <div uk-spinner="ratio: 3"></div>
    </div>
    <hr v-else>
</template>

<script>
import {useCartStore} from "../../store/CartStore.js";
import CartConstants from "../../constants/cartConstants";
import ProductConstants from "../../constants/productConstants";
import AmountInput from "./AmountInput.vue";

export default {
    name: "ItemList",
    components: {AmountInput},
    setup() {
        const CartStore = useCartStore()
        return {
            CartStore
        }
    },
    props: {
        itemList: {
            type: Array,
            required: true
        }
    },
    methods: {
        removeFromCart(pid) {
            this.CartStore.removeCartItem({pid: pid, type: 'cart'});
        },
        updateCart(pid, itemCount) {
            this.CartStore.updateCartItem({pid: pid, cartItemsCount: itemCount, type: 'cart'}).then(() => {
                this.CartStore.fetchCartData()
            })
        },
        openConfigurator(item) {
            window.open(item.DetailUrl, '_blank');
        },
        isItemSelected(value, current) {
            return (value === current)
        }
    },
    computed: {
        CartConstants() {
            return CartConstants
        },
        ProductConstants(){
            return ProductConstants;
        }
    },
}
</script>

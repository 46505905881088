import {defineStore} from "pinia"
import ApiService from "../service/ApiService"
import TrackingService from "../service/TrackingService";

export const useCheckoutStore = defineStore('CheckoutStore', {
    state: () => ({
        checkoutData: {
            'current': '',
            'steps': {
                'address': {
                    'data': {},
                    'options': {},
                    'isFilled': false
                },
                'delivery': {
                    'data': {},
                    'options': {},
                    'isFilled': false
                },
                'payment': {
                    'data': {},
                    'options': {},
                    'isFilled': false
                },
                'confirmation': {
                    'data': {},
                    'options': {},
                    'isFilled': false
                }
            },
            'hasPayment': true,
            'isLoggedIn': false,
            'isFinished': false,
            'error': '',
            'deliveryAddressId': null,
            'deliveryAddressUuid': null
        },
        showCheckoutForm: false,
        showLoginForm: true,
        lockerLocationList: [],
        selectedLockerId: 0,
        isLoading: false,
        catalogueImage: '',
    }),
    actions: {
        setSelectedAddressUuid(uuid) {
            this.checkoutData.deliveryAddressUuid = uuid
        },
        setSelectedAddressIndex(index) {
            this.checkoutData.deliveryAddressId = index
        },
        setCheckoutStep(payload) {
            if (payload.step) {
                this.checkoutData.current = payload.step
            }
        },
        async fetchCheckoutData() {
            return ApiService.getCheckoutData()
                .then(
                    response => {
                        this.setCheckoutData(response.data)

                        return response
                    }
                )
        },
        async retrieveCatalogueImage() {
            return ApiService.getCatalogueImage().then(
                response => {
                    this.catalogueImage = response.data
                }
            )
        },
        getDeliveryServices(payload) {
            return ApiService.getCheckoutDeliveryServices(payload)
        },
        sendCheckoutData(payload) {
            this.isLoading = true

            var sendData = Object.assign({}, this.checkoutData.steps[payload.step].data);
            sendData._token = payload.token;

            let apiPromise = ApiService.sendCheckoutData({step: payload.step, data: sendData})

            apiPromise.then(
                    (response) => {
                        this.setCheckoutData(response.data)

                        if (response.data.isFinished && response.data.hasOwnProperty('redirectTo')) {
                            window.location.href = response.data.redirectTo;
                        }
                    }
                ).finally(
                    () => {
                        this.isLoading = false
                    }
                );

            return apiPromise
        },
        setCheckoutData(data) {
            TrackingService.trackCheckout(data);
            this.isLoading = true
            this.checkoutData = data
            if (data.isLoggedIn || this.isAnyStepAlreadyFilled()) {
                this.showCheckoutForm = true
                this.isLoading = false
            }
        },
        isAnyStepAlreadyFilled() {
            for(let stepData in this.checkoutData.steps) {
                if (this.checkoutData.steps[stepData].isFilled) {
                    return true
                }
            }
            return false
        },
        getDhlLocationList(payload) {
            return ApiService.getDhlLocationList(payload)
                .then((response) => {
                    if(response.data.success) {
                        this.lockerLocationList = response.data.data
                    }
                    return this.lockerLocationList
                })
        },
        getDhlLocationDataById(id) {
            for (let index in this.lockerLocationList) {
                if (this.lockerLocationList[index].id === id) {
                    return this.lockerLocationList[index]
                }
            }
        },
        getToken(stepName) {
            return ApiService.getCheckoutCsrfToken(stepName)
        },
        setGender(gender) {
            this.checkoutData.steps.delivery.data.gender = gender
        },
        setFirstName(firstName) {
            this.checkoutData.steps.delivery.data.firstName = firstName
        },
        setLastName(lastName) {
            this.checkoutData.steps.delivery.data.lastName = lastName
        },
        setCompany(company) {
            this.checkoutData.steps.delivery.data.company = company
        },
        setDepartment(department) {
            this.checkoutData.steps.delivery.data.department = department
        },
        setCountry(country) {
            this.checkoutData.steps.delivery.data.country = country
        },
        setZip(zip) {
            this.checkoutData.steps.delivery.data.zip = zip
        },
        setCity(city) {
            this.checkoutData.steps.delivery.data.city = city
        },
        setStreet(street) {
            this.checkoutData.steps.delivery.data.street = street
        },
        setStreetNumber(streetNumber) {
            this.checkoutData.steps.delivery.data.streetNumber = streetNumber
        },
        setStreetExtra(streetExtra) {
            this.checkoutData.steps.delivery.data.streetExtra = streetExtra
        },
        setAddressType(addressType) {
            this.checkoutData.steps.delivery.data.addressType = addressType
        }
    },
    getters: {
        deliveryGender(state) {
            return state.checkoutData.steps.delivery.data.gender ?? ''
        },
        deliveryName(state) {
            return (state.checkoutData.steps.delivery.data.firstName ?? '') + ' ' + (state.checkoutData.steps.delivery.data.lastName ?? '')
        },
        deliveryCompany(state) {
            return state.checkoutData.steps.delivery.data.company ?? ''
        },
        deliveryDepartment(state) {
            return state.checkoutData.steps.delivery.data.department ?? ''
        },
        deliveryPackstation(state) {
            return state.checkoutData.steps.delivery.data.packstationData ?? ''
        },
        deliveryCountryCode(state) {
            return state.checkoutData.steps.delivery.data.country ?? ''
        },
        deliveryZip(state) {
            return state.checkoutData.steps.delivery.data.zip ?? ''
        },
        deliveryCity(state) {
            return state.checkoutData.steps.delivery.data.city ?? ''
        },
        deliveryStreet(state) {
            return state.checkoutData.steps.delivery.data.street ?? ''
        },
        deliveryStreetNumber(state) {
            return state.checkoutData.steps.delivery.data.streetNumber ?? ''
        },
        deliveryStreetExtra(state) {
            return state.checkoutData.steps.delivery.data.streetExtra ?? ''
        },
        getSelectedAddressIndex(state) {
            return state.checkoutData.deliveryAddressId?? null
        },
        getSelectedAddressUuid(state) {
            return state.checkoutData.deliveryAddressUuid?? null
        },
        getAddressType(state) {
            return state.checkoutData.steps.delivery.data.addressType?? ''
        }
    }
})
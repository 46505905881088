<template>

    <div v-if="stepIsActive">

        <div class="uk-background-muted uk-padding-small">
            <label class="uk-text-bolder">{{ $t('text.apply-voucher-code') }}</label>
            <Voucher :cart="this.cart" class="uk-margin-small-top"></Voucher>
        </div>

        <div class="uk-background-muted uk-padding-small uk-margin-medium-top">
            <h2>{{ $t('text.cart.summary') }}</h2>
            <template v-for="(items, deliveryState, index) in CartStore.cartItems" :key="index">
                <ItemList :item-list="items"></ItemList>
            </template>
            <div v-if="CartStore.giftItems.length > 0">
                <h2>{{ $t('text.shop.giftItem') }}</h2>
                <GiftItemList :item-list="CartStore.giftItems"></GiftItemList>
            </div>
            <PriceTotals :cart="CartStore.cartData"></PriceTotals>
        </div>

        <hr class="uk-margin-medium-top uk-margin-medium-bottom">

        <div uk-grid>
            <div class="uk-width-auto"><span v-html="catalogueImage"></span></div>
            <BaseCheckbox
                :label="$t('text.checkout.input.get-catalogue')"
                :error-message="retrieveError('getCatalogue')"
                v-model="CheckoutStore.checkoutData.steps.confirmation.data.getCatalogue"
                class="uk-width-expand"
            />
        </div>

        <hr class="uk-margin-medium-top uk-margin-medium-bottom">

        <div class="uk-margin-medium-bottom">
            <BaseTextarea
                :placeholder="$t('text.checkout.input.comment')"
                :error-message="retrieveError('comment')"
                v-model="CheckoutStore.checkoutData.steps.confirmation.data.comment"
                type="input"
            />
        </div>

        <p v-html="$t('text.checkout.revocation-hint')"></p>

        <div>
            <BaseCheckbox
                :label="$t('text.checkout.input.terms-accepted')"
                :error-message="retrieveError('termsAccepted')"
                v-model="CheckoutStore.checkoutData.steps.confirmation.data.termsAccepted"
            />
        </div>

        <div
            class="ws-checkout-error-general uk-text-danger"
            v-if="CheckoutStore.checkoutData.error.general && (CheckoutStore.checkoutData.current === this.stepName)"
        >
            {{ $t('text.checkout.payment.error.'+CheckoutStore.checkoutData.error.general) }}
        </div>

        <BaseButton
            :full-width="true"
            :href="'#'"
            @click.prevent="sendForm()"
            class="uk-margin-medium-top"
            :id="'orderConfirmButton'"
        >
            {{ $t('text.checkout.button.buy-now') }}
        </BaseButton>

    </div>


</template>

<script>
import {useCheckoutStore} from "../../../store/CheckoutStore.js";
import BaseButton from "../../form/base/BaseButton.vue";
import BaseCheckbox from "../../form/base/BaseCheckbox.vue";
import BaseTextarea from "../../form/base/BaseTextarea.vue";
import FloatingLabelInput from "../../form/base/FloatingLabelInput.vue";
import Voucher from "../../cart/Voucher.vue";
import {useCartStore} from "../../../store/CartStore";
import ItemList from "../../cart/ItemList.vue";
import GiftItemList from "../../cart/GiftItemList.vue";
import Summary from "../../cart/Summary.vue";
import PriceTotals from "../../cart/PriceTotals.vue";

export default {
    name: "MobileConfirmation",
    data() {
        return {
            showCreateAccountForm: false,
            CheckoutStore: useCheckoutStore(),
            stepName: 'confirmation',
            CartStore: useCartStore()
        }
    },
    components: {
        PriceTotals,
        Summary,
        GiftItemList,
        ItemList,
        Voucher,
        BaseButton,
        BaseCheckbox,
        BaseTextarea,
        FloatingLabelInput,
    },
    props: {
        stepIsActive: {
            type: Boolean,
            required: true,
        },
        catalogueImage: {
            type: String,
            required: true,
        },
        submitOrder: {
            type: Boolean,
            required: false,
        }
    },
    watch: {
        submitOrder(newVal, oldVal) {
            if (newVal) {
                this.sendForm()
            }
        }
    },
    methods: {
        sendForm() {
            this.$parent.showSpinner(this.$t('text.loading-spinner.commit-order'), true)
            this.CheckoutStore.getToken(this.stepName).then(
                (result) => {
                    if (result.hasOwnProperty('data') && result.data.hasOwnProperty('token')) {
                        this.CheckoutStore.sendCheckoutData({ step: this.stepName, token: result.data.token })
                    }
                }
            )
        },
        retrieveError(field) {
            if (this.CheckoutStore.checkoutData.error.confirmation && this.CheckoutStore.checkoutData.error.confirmation[field]) {
                this.$parent.hideSpinner()
                return this.CheckoutStore.checkoutData.error.confirmation[field]
            }

            return '';
        },
    }
}
</script>

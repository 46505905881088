import axios from "axios";

const apiClient = axios.create({
    baseUrl: baseUrl,
    withCredentials: false,
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
    }
});
const cartTypeDefault = 'cart';
const cartTypeWishlist = 'wishlist';
const cartTypes = [cartTypeDefault, cartTypeWishlist];

export default {
    cartTypeWishlist,
    cartTypeDefault,
    addCartItem(id, count, type) {

        if (!cartTypes.includes(type)) {
            type = cartTypeDefault
        }

        return apiClient.post(
            '/' + type + '/add/',
            { id: id , count: count}
        );

    },
    updateCartItem(id, count, type) {

        if (!cartTypes.includes(type)) {
            type = cartTypeDefault
        }
        return apiClient.post(
            '/' + type + '/update/',
            { id: id , count: count}
        );
    },
    removeCartItem(id, type) {

        if (!cartTypes.includes(type)) {
            type = cartTypeDefault
        }
        return apiClient.post(
            '/' + type + '/remove/',
            {id: id}
        );
    },
    updateCartShipment(id) {
        return apiClient.post(
            '/cart/update-shipment/',
            {id: id}
        );
    },
    applyVoucherCode(code) {
        return apiClient.post(
            '/cart/apply-voucher',
            {vouchercode: code}
        );
    },
    removeVoucherCode(code) {
        return apiClient.post(
            '/cart/remove-voucher',
            {vouchercode: code}
        );
    },
    getCartData(payload, type) {

        if (!cartTypes.includes(type)) {
            type = cartTypeDefault
        }

        return apiClient.post(
            '/' + type + '/data',
            payload
        );
    },
    getUserData(minimalData) {
        let queryData = '';
        if(minimalData) {
            queryData = '?min=1';
        }
        return apiClient.get(
            '/user/userdata' + queryData
        );
    },
    getNewsletterStatus()
    {
        return apiClient.get(
            '/user/newsletter'
        );
    },
    loadOrderList(page, limit) {
        let params = ''
        if (page) {
            params = 'p=' + page
        }
        if (limit) {
            if (params !== '') {
                params = params + '&'
            }
            params = params + 'l=' + limit
        }
        if (params !== '') {
            params = '?' + params
        }
        return apiClient.get(
            '/user/orderlist' + params
        );
    },
    loadOrderInvoicePdf(orderId){
        return apiClient.get(
            '/user/order/invoice/' + orderId + '/pdf',
            {responseType: 'blob'}
        );
    },
    loadWishlist(page, limit) {
        let params = ''
        if (page) {
            params = 'p=' + page
        }
        if (limit) {
            if (params !== '') {
                params = params + '&'
            }
            params = params + 'l=' + limit
        }
        if (params !== '') {
            params = '?' + params
        }
        return apiClient.get(
            '/user/wishlist' + params
        );
    },
    setDeliveryCountry(code) {
        return apiClient.post(
            '/checkout/setdeliverycountry',
            {
                country: code
            }
        )
    },
    setDeliveryService(id) {
        return apiClient.post(
            '/checkout/setdeliveryserviceprovider',
            {
                dspId: id
            }
        )
    },
    getUserRegisterData() {
        return apiClient.get(
            '/user/data/register'
        )
    },
    loginUser(email, password, redirecturl) {
        return apiClient.post(
            '/user/loginxhr',
            {
                username: email,
                password: password,
                redirectUrl: redirecturl
            }
        )
    },
    registerUser(payload) {
        return apiClient.post(
            '/user/register',
            {
                customerType: payload.customerType,
                gender: payload.gender,
                firstname: payload.firstname,
                lastname: payload.lastname,
                email: payload.email,
                emailConfirm: payload.emailConfirm,
                password: payload.password,
                passwordConfirm: payload.passwordConfirm,
                phone: payload.phone,
                zip: payload.zip,
                city: payload.city,
                street: payload.street,
                streetNumber: payload.streetNumber,
                country: payload.country,
                company: payload.company,
                department: payload.department,
                vatId: payload.vatId,
                csrfToken: payload.csrfToken,
            }
        )
    },
    fetchUserAddresses() {
        return apiClient.get(
            '/user/addresses'
        )
    },
    deleteUserAddress(index) {
        return apiClient.delete('/user/address/' + index)
    },
    sendUserAddresses(payload) {
        return apiClient.post(
            '/user/addresses',
            payload
        )
    },
    saveUserAddress(payload) {
        return apiClient.post(
            '/user/address',
            payload
        )
    },
    sendPaymentPreference(payload) {
        return apiClient.post(
            '/user/payment-preference',
            payload
        )
    },
    fetchPaymentMethods() {
        return apiClient.get(
            '/user/payment-methods'
        )
    },
    setUserDeliveryAddressSelection(selection) {
        return apiClient.post(
            '/user/set-delivery-address-selection',
            { selection: selection}
        )
    },
    getPaymentTypes(payload) {

        let queryString = ''

        if (payload && payload.hasOwnProperty('country') && payload.country) {
            queryString = '?country=' + payload.country
        }

        return apiClient.get(
            '/shop/paymenttypes' + queryString
        );
    },
    getShippmentList() {
        return apiClient.get(
            '/shop/shippmentlist'
        );
    },
    getCheckoutData() {
        return apiClient.get(
            '/checkout/data'
        );
    },
    getCatalogueImage() {
        return apiClient.get(
            '/checkout/catalogue-image'
        )
    },
    getCheckoutDeliveryServices(payload) {
        return apiClient.get('/checkout/delivery-services?country=' + payload.country + '&addressType=' + payload.addressType)
    },
    sendCheckoutData(payload) {
        return apiClient.post('/checkout/' + payload.step, payload.data)
    },
    getProductSearchResult(payload) {
        return apiClient.post('/search?autocomplete=1&term=' + payload.term)
    },
    getDhlLocationList(payload) {
        return apiClient.get('/dhlapi/findlocker?' + new URLSearchParams(payload).toString())
    },
    getNavigationData(payload) {
        return apiClient.get('/navigation-data?' + new URLSearchParams(payload).toString())
    },
    getArticleData(payload) {
        return apiClient.get('/article~a' + payload.pid + '?f=json')
    },
    sendCustomerContactRequest(payload) {
        return apiClient.post(
            '/contact',
            payload
        )
    },
    sendContactMail(payload) {
        return apiClient.post(
            '/send-contact-mail',
            payload
        )
    },
    getCheckoutCsrfToken(stepName) {
        return apiClient.get('/checkout/token/' + stepName)
    },
    subscribeNewsletter(payload) {
        return apiClient.post('/user/newsletter/subscribe')
    },
    unsubscribeNewsletter(payload) {
        return apiClient.post('/user/newsletter/unsubscribe')
    },
    requestAccountBalance(payload) {
        return apiClient.post('/user/account-balance/request')
    },
    patchProfileData(payload)
    {
        return apiClient.patch('/user/profile', payload)
    },
    patchUserPassword(payload) {
        return apiClient.patch('/user/password', payload)
    },
    postRevocationForm(payload)
    {
        return apiClient.post('/user/revocation-form', payload)
    },
    setPartialDelivery(payload) {
        return apiClient.post('/cart/partial-delivery', { partialDelivery: payload })
    },
    postReturnForm(payload)
    {
        return apiClient.post('/user/return-form', payload)
    },
    cancelOrder(payload)
    {
        return apiClient.post('/user/order/cancel', payload)
    },
    getOrderTracking(payload) {
        return apiClient.get('/user/order/tracking/' + payload.orderId)
    },
    addGiftItem(payload) {
        return apiClient.post('/cart/gift-item', payload)
    },
    deleteGiftItem(payload) {
        return apiClient.delete('/cart/gift-item/' + payload)
    }
}
<template>

    <div
        class="uk-grid-small uk-width-1-1 uk-padding-small uk-padding-remove-horizontal"
        uk-grid
    >

        <div class="uk-width-1-1 uk-width-auto@m uk-inline">
            <span v-if="wishlistItem.ListImageHtml" v-html="wishlistItem.ListImageHtml"></span>
            <a :href="wishlistItem.DetailUrl" class="uk-position-cover" :title="wishlistItem.Bezeichnung"></a>
        </div>

        <div class="uk-width-1-1 uk-width-expand@m uk-inline">
            <h4 class="uk-margin-remove-bottom">{{ wishlistItem.Bezeichnung }}</h4>
            <p class="uk-margin-remove-top ws-text-subline">{{ wishlistItem.Artikelnummer }}</p>

            <div v-if="wishlistItem.BeschreibungKurz" class="ws-article-description-short" v-html="wishlistItem.BeschreibungKurz"></div>

            <div v-if="wishlistItem.deliveryState">

                <i class="fa-solid fa-circle" :class="'ws-' + wishlistItem.deliveryState"></i> <span v-if="wishlistItem.deliveryMessage" class="ws-available-info" v-html="wishlistItem.deliveryMessage"></span>

            </div>

            <a v-if="wishlistItem.DetailUrl" :href="wishlistItem.DetailUrl" class="uk-position-cover uk-link-reset"></a>

        </div>

        <div class="uk-width-1-1 uk-width-auto@m uk-flex uk-flex-column uk-flex-between">

            <div class="uk-margin-small-bottom" uk-grid>

                <div class="uk-width-expand"></div>

                <div v-if="wishlistItem.hasDiscount" class="uk-width-auto">
                    <span class="ws-discount ws-discount--percentage">%</span>
                </div>

                <div class="uk-width-auto">
                    <WishlistButton
                        :item-in-wishlist="true"
                        :wishlist-view="true"
                        :pid="wishlistItem.Id"
                    ></WishlistButton>

                </div>
            </div>

            <div class="uk-text-right">

                <div v-if="wishlistItem.hasDiscount" class="ws-price-reduced">
                    {{ wishlistItem.OriginalPriceString }}
                </div>

                <div class="ws-price-default">
                    {{ wishlistItem.PriceString }}
                </div>

                <div class="ws-wishlist-button-container uk-display-inline-block cart-button uk-margin-small-top">
                    <ButtonInput :pid="wishlistItem.Id" :is-in-wishlist="true"></ButtonInput>
                </div>
            </div>
        </div>

    </div>

</template>

<script>

import ButtonInput from "../../../cart/ButtonInput.vue";
import WishlistButton from "../../../cart/WishlistButton.vue";

export default {
    name: 'WishlistSingleItemListView',
    components: {WishlistButton, ButtonInput},

    props: {
        wishlistItem: {
            type: Object,
            required: true,
        }
    },
};

</script>

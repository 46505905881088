<template>
    <div id="cartSmallOverview" uk-dropdown="pos: bottom-right" class="uk-width-medium uk-width-large@s">
        <h3>{{ $t('text.shop.cart.name') }}</h3>
        <p v-if="hasCartItem && overviewTextExists">
            {{ $t('text.shop.cart_overview.text') }}
        </p>
        <div v-if="hasCartItem">
            <div class="cart-small-overview__item-container uk-overflow-auto">
                <div v-for="(items, state) in cartStore.cartItems" :key="state">
                    <div v-for="item in items" :key="item.Id">
                        <hr class="uk-hr">
                        <div class="uk-grid-small" uk-grid>
                            <div class="uk-width-auto" v-html="item.ListImageHtml"></div>
                            <div class="uk-width-expand">
                                <div class="uk-grid-small" uk-grid>
                                    <div class="uk-width-2-3">
                                        <h4 class="hyphens-auto">{{ item.Bezeichnung }}</h4>
                                        <div>{{ item.Artikelnummer }}</div>
                                    </div>
                                    <div class="uk-width-1-3">
                                        <div class="ws-price-default ws-price-small uk-text-right">{{
                                                item.TotalPriceString
                                            }}
                                        </div>
                                    </div>
                                </div>
                                <div class="uk-grid-collapse uk-margin-small-bottom" uk-grid>
                                    <div class="uk-width-2-3">
                                        <div>{{ $t('text.shop.itemcount') }}: {{ item.Count }}</div>
                                    </div>
                                    <div class="uk-width-1-3">
                                        <div class="uk-text-right">
                                    <span :uk-tooltip="'title: ' + $t('text.shop.cart-remove-item') + '; pos: left'" class="uk-padding-small ws-cursor-pointer" @click.stop="removeFromCart(item.Id)"><i
                                        class="fal fa-trash-alt"></i>
                                    </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="uk-margin-small-top">
                <BaseButton :href="cartStore.cartData.cartRoute" :full-width="true">{{ $t('text.shop.to_cart') }}</BaseButton>
            </div>
        </div>
        <div v-else v-html="$t('text.shop.cart_empty')"></div>
    </div>
</template>

<script>
import BaseButton from "../form/base/BaseButton.vue"
import { useCartStore } from "../../store/CartStore"

export default {
    name: "SmallOverview",
    components: {
      BaseButton
    },
    setup() {
        const cartStore = useCartStore()
        return {
            cartStore
        }
    },
    methods: {
        removeFromCart(pid) {
            this.cartStore.removeCartItem({pid: pid, type: 'cart'})
        },
    },
    computed: {
        overviewTextExists(){
            return this.$te('text.shop.cart.overview.text');
        },
        hasCartItem() {
            return this.cartStore.cartItemsCount > 0;
        }
    }
}
</script>

<template>
    <div class="uk-margin-medium-bottom">
        <div class="ws-input-floating-label uk-padding-small uk-padding-remove-vertical" :class="{'error': !!errorMessage}">
            <select
                :value="modelValue"
                class="uk-select uk-margin-small-top uk-margin-small-bottom"
                :id="uuid"
                v-bind="{
                    ...$attrs,
                    onChange: ($event) => { $emit('update:modelValue', $event.target.value), updateDeliveryServices($event.target.value) }
                }"
                :aria-describedby="errorMessage ? uuid + '-error' : null"
                :aria-invalid="errorMessage ? true : null"
            >
                <option value="" disabled selected>{{ $t('text.form.select.please-choose') }}</option>
                <option
                    v-for="(option, index) in options"
                    :selected="option.value === modelValue"
                    :key="option.value"
                    :value="option.value"
                >{{ $t(option.name) }}</option>
            </select>
            <label
                :for="uuid"
                class="uk-padding-small uk-padding-remove-vertical"
            >
                {{ $t('text.checkout.select.country') }}<span v-if="required"><sup>*</sup></span>
            </label>
            <i class="fa fa-chevron-down"></i>
        </div>
        <p
            class="ws-checkout-error-field uk-text-danger"
            v-if="errorMessage"
            :id="uuid + '-error'"
            aria-live="assertive"
        >
            {{ $t(errorMessage) }}
        </p>
    </div>
</template>

<script>
import {useCheckoutStore} from "../../../store/CheckoutStore.js"
import {useCartStore} from "../../../store/CartStore.js";
import UniqueID from "../../../includes/UniqueID.js";
import ApiService from "../../../service/ApiService.js";

export default {
    name: 'BaseSelect',
    props: {
        required: {
            type: Boolean,
            default: true
        },
        modelValue: {
            type: [String, Number],
            default: ''
        },
        options: {
            type: Array,
            required: true
        },
        errorMessage: {
            type: String,
            default: ''
        },
    },
    setup() {
        const uuid = UniqueID().getID()
        const CheckoutStore = useCheckoutStore()
        const CartStore = useCartStore()

        return {
            uuid,
            CheckoutStore,
            CartStore
        }
    },
    methods: {

        updateDeliveryServices(country) {

            ApiService.getCheckoutDeliveryServices({country: country}).then((response) => {

                this.CheckoutStore.checkoutData.steps.delivery.options.deliveryService = response.data.services

                // Aktuell ausgewählter Dienst noch verfügbar?
                for (let item in this.CheckoutStore.checkoutData.steps.delivery.options.deliveryService) {

                    if (
                        this.CheckoutStore.checkoutData.steps.delivery.options.deliveryService[item].value
                        === this.CheckoutStore.checkoutData.steps.delivery.data.deliveryService
                    ) {
                        return
                    }

                }

                this.CheckoutStore.checkoutData.steps.delivery.data.deliveryService = response.data.default
                ApiService.setDeliveryCountry(country).then((response) => {
                    if(response.data.success) {
                        this.CartStore.fetchCartData({
                            'serviceId': this.CheckoutStore.checkoutData.steps.delivery.data.deliveryService,
                            'deliveryCountry': country
                        })
                    }
                })
            })

        }
    }
};
</script>

<template>
    <div class="user-app-revocation-form">
        <div class="uk-width-1-1">
            <h1>{{ $t('text.user.profile.revocation.headline') }}</h1>
            <p class="uk-width-1-1">
                {{ $t('text.user.profile.revocation.text') }}
            </p>
            <div v-if="userStore.userData.legal.revocation.revocationPdf" class="uk-width-1-1 uk-width-3-4@m">
                <a :href="userStore.userData.legal.revocation.revocationPdf" target="_blank">
                    {{ $t('text.user.profile.revocation.download_button_text') }}
                </a>
                <p class="uk-margin-medium-top">
                    {{ $t('text.user.profile.revocation.return_address_text') }}
                </p>
                <p class="uk-margin-medium-top" v-html="userStore.userData.legal.revocation.revocationReturnAddress">
                </p>
                <p class="uk-margin-medium-top">
                    {{ $t('text.user.profile.revocation.return_address_text_after') }}
                </p>
            </div>
            <hr class="uk-margin-medium-top">
            <form v-if="!submitted">
                <fieldset class="uk-fieldset">
                    <legend class="uk-legend uk-width-1-1 uk-width-3-4@m">
                        <span class="uk-float-right">{{ $t('text.checkout.note.mandatory-fields') }}</span>
                        {{ $t('text.user.profile.revocation.form.title') }}
                    </legend>
                    <FloatingLabelInput
                        v-model="models"
                        :error-message="modelsError"
                        :label="$t('text.user.profile.revocation.form.label_models')"
                        type="input"
                        :class="['uk-width-1-1 uk-width-3-4@m uk-margin-small-top uk-margin-remove-bottom']"
                    ></FloatingLabelInput>
                    <FloatingLabelInput
                        v-model="orderDate"
                        :label="$t('text.user.profile.revocation.form.label_orderDate')"
                        type="date"
                        :required="false"
                        :class="['uk-width-1-1 uk-width-3-4@m uk-margin-small-top uk-margin-remove-bottom']"
                    ></FloatingLabelInput>
                    <FloatingLabelInput
                        v-model="orderId"
                        :error-message="orderIdError"
                        :label="$t('text.user.profile.revocation.form.label_orderId')"
                        type="input"
                        :class="['uk-width-1-1 uk-width-3-4@m uk-margin-small-top uk-margin-remove-bottom']">
                    </FloatingLabelInput>
                    <FloatingLabelInput
                        v-model="customerId"
                        :error-message="customerIdError"
                        :label="$t('text.user.profile.revocation.form.label_customerId')"
                        type="input"
                        :class="['uk-width-1-1 uk-width-3-4@m uk-margin-small-top uk-margin-remove-bottom']"
                    ></FloatingLabelInput>
                    <FloatingLabelInput
                        v-model="firstName"
                        :label="$t('text.user.profile.revocation.form.label_firstname')"
                        type="input"
                        :required="false"
                        :class="['uk-width-1-1 uk-width-3-4@m uk-margin-small-top uk-margin-remove-bottom']"
                    ></FloatingLabelInput>
                    <FloatingLabelInput
                        v-model="lastName"
                        :error-message="lastNameError"
                        :label="$t('text.user.profile.revocation.form.label_lastName')"
                        type="input"
                        :class="['uk-width-1-1 uk-width-3-4@m uk-margin-small-top uk-margin-remove-bottom']"
                    ></FloatingLabelInput>
                    <FloatingLabelInput
                        v-model="emailAddress"
                        :error-message="emailAddressError"
                        :label="$t('text.user.profile.revocation.form.label_email')"
                        type="email"
                        :class="['uk-width-1-1 uk-width-3-4@m uk-margin-small-top uk-margin-remove-bottom']"
                    ></FloatingLabelInput>
                    <FloatingLabelInput
                        v-model="address"
                        :error-message="addressError"
                        :label="$t('text.user.profile.revocation.form.label_address')"
                        type="input"
                        :class="['uk-width-1-1 uk-width-3-4@m uk-margin-small-top uk-margin-remove-bottom']"
                    ></FloatingLabelInput>
                    <FloatingLabelInput
                        v-model="zipCode"
                        :error-message="zipCodeError"
                        :label="$t('text.user.profile.revocation.form.label_zipCode')"
                        type="input"
                        :class="['uk-width-1-1 uk-width-3-4@m uk-margin-small-top uk-margin-remove-bottom']"
                    ></FloatingLabelInput>
                    <FloatingLabelInput
                        v-model="city"
                        :error-message="cityError"
                        :label="$t('text.user.profile.revocation.form.label_city')"
                        type="input"
                        :class="['uk-width-1-1 uk-width-3-4@m uk-margin-small-top uk-margin-remove-bottom']"
                    ></FloatingLabelInput>
                </fieldset>
                <BaseButton
                    :href="'#'"
                    :text="$t('text.user.profile.revocation.form.text_button_submit')"
                    :class="['uk-margin-small-top uk-padding-small uk-width-1-1 uk-width-3-4@m']"
                    @click="submit"
                ></BaseButton>
            </form>
            <div v-if="submitted" class="uk-width-1-1 uk-width-3-4@m">
                <div uk-alert class="uk-alert-success" uk-alert-type="success">
                    {{ $t('text.user.profile.revocation.form.submitted_success_message') }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import BaseButton from "../../form/base/BaseButton.vue";
import FloatingLabelInput from "../../form/base/FloatingLabelInput.vue";
import { useUserStore } from "../../../store/UserStore";
import { notification } from "uikit";
import { useField, useForm } from 'vee-validate';
import { required, email, alpha } from '@vee-validate/rules';
import ApiService from "../../../service/ApiService";

export default ({
    name: "RevocationForm",
    components: {
        FloatingLabelInput,
        BaseButton
    },
    data() {

        const { handleSubmit, meta, errors } = useForm();

        const formSubmitting = handleSubmit((values) => values)

        const { value: models, errorMessage: modelsError } = useField('models', required);
        const { value: orderDate, errorMessage: orderDateError } = useField('orderDate');
        const { value: orderId, errorMessage: orderIdError } = useField('orderId', required);
        const { value: customerId, errorMessage: customerIdError } = useField('customerId', required);
        const { value: firstName, errorMessage: firstNameError } = useField('firstName');
        const { value: lastName, errorMessage: lastNameError } = useField('lastName', required);
        const { value: emailAddress, errorMessage: emailAddressError } = useField('email', [email, required]);
        const { value: zipCode, errorMessage: zipCodeError } = useField('zipCode', required);
        const { value: address, errorMessage: addressError } = useField('address', required);
        const { value: city, errorMessage: cityError } = useField('city', alpha);

        return {
            formSubmitting,
            handleSubmit,
            meta,
            errors,
            models,
            modelsError,
            orderId,
            orderIdError,
            orderDate,
            orderDateError,
            customerId,
            customerIdError,
            lastName,
            lastNameError,
            emailAddress,
            emailAddressError,
            zipCode,
            zipCodeError,
            city,
            cityError,
            firstName,
            address,
            submitted: false,
            userStore: useUserStore()
        }
    },
    mounted() {
        if (!this.emailAddress && this.userStore.userData.Email) {
            this.emailAddress = this.userStore.userData.Email;
        }
        if (!this.firstName && this.userStore.userData.Firstname) {
            this.firstName = this.userStore.userData.Firstname
        }
        if (!this.lastName && this.userStore.userData.Lastname) {
            this.lastName = this.userStore.userData.Lastname
        }
        if (!this.address && this.userStore.userData.Street) {
            this.address = this.userStore.userData.Street + ' ' +  this.userStore.userData.StreetNumber;
        }
        if (!this.zipCode && this.userStore.userData.Zip) {
            this.zipCode = this.userStore.userData.Zip;
        }
        if (!this.city && this.userStore.userData.City) {
            this.city = this.userStore.userData.City;
        }
        if (!this.customerId && this.userStore.userData.Id) {
            this.customerId = this.userStore.userData.Id;
        }
    },
    methods: {
        showNotification(message, status = "success") {
            const timeout = 5000
            notification({
                message: this.$t(message),
                status: status,
                pos: 'top-center',
                timeout: timeout
            })
            setTimeout(() => this.privacyError = false, timeout)
        },
        resetForm() {
            ['models', 'orderId', 'orderDate'].forEach(field => this[field] = null)
            this.submitted = true
        },
        async submit() {
            try {
                await this.formSubmitting();
                if (this.meta.valid) {
                    const formValues = {
                        models: this.models || '',
                        orderId: this.orderId || '',
                        orderDate: this.orderDate || '',
                        customerId: this.customerId || '',
                        lastName: this.lastName || '',
                        email: this.emailAddress || '',
                        zipCode: this.zipCode || '',
                        city: this.city || '',
                        firstName: this.firstName || '',
                        address: this.address || ''
                    };
                    const response = await ApiService.postRevocationForm(formValues)
                    if (response.status === 200) {
                        this.showNotification('text.user.profile.revocation.submitted_successfully')
                        this.resetForm()
                    } else {
                        this.showNotification('text.user.profile.revocation.submitted_unsuccessfully', 'danger')
                    }
                }
            } catch (error) {
                this.showNotification('text.user.profile.revocation.submitted_unsuccessfully', 'danger')
            }
        }
    }
})
</script>
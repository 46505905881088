<template>
    <div class="uk-cover-container">
        <p class="uk-text-center">
            <i class="fa-light fa-bookmark fs-lg"></i>
            <span v-if="cartStore.wishlistItemsCount > 0" class="uk-badge">{{ cartStore.wishlistItemsCount }}</span>
            <br />
            <span class="uk-text-small uk-visible@m" v-html="$t('text.menu-subtext.wishlist')"></span>
        </p>
        <a class="uk-position-cover" :href="cartStore.wishlistData.cartRoute"></a>
    </div>
</template>

<script>
import SmallOverview from "./SmallOverview.vue"
import {useCartStore} from "../../store/CartStore"

export default {
    name: "CartMenuIcon",
    created() {
        this.cartStore.fetchCartData(null, 'wishlist').then((data) => {
        }).catch(error => {
            console.log(error)
        })
    },
    data() {
        return {
            cartStore: useCartStore()
        }
    },
    components: {SmallOverview}
}
</script>
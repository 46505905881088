<template>
    <div>
        <BaseSelect
            v-model="values.requestType"
            :pleaseChooseOptionLabel="'text.option.request-type.select-type'"
            :options="config.requestTypeOptions"
            :include-please-choose-option="true"
            :error-message="errors.requestType"
            :label="$t('text.option.request-type.select-type')"
        />
        <div>
            <FloatingLabelInput
                v-model="values.customerId"
                :label="$t('label.contact-form-customer-id.length-hint')"
                type="input"
                :error-message="errors.customerId"
            />
            <BaseRadio
                v-model="values.salutation"
                :options="config.salutationOptions"
                :error-message="errors.salutation"
                class="uk-margin-medium-bottom uk-margin-remove-top"
            />
            <FloatingLabelInput
                v-model="values.firstname"
                :label="$t('label.contact-form-firstname')"
                type="input"
                :error-message="errors.firstname"
            />
            <FloatingLabelInput
                v-model="values.lastname"
                :label="$t('label.contact-form-lastname')"
                type="input"
                :error-message="errors.lastname"
            />
            <FloatingLabelInput
                v-model="values.email"
                :label="$t('label.contact-form-email')"
                type="input"
                :error-message="errors.email"
            />
            <FloatingLabelInput
                v-model="values.phoneNumber"
                :label="$t('label.contact-form-phone')"
                :required="false"
                type="input"
                :error-message="errors.phoneNumber"
                :hint="$t('label.contact-form-phone-hint')"
            />
            <BaseTextarea
                v-model="values.message"
                :placeholder="$t('label.contact-form-message')"
                :error-message="errors.message"
                :required="true"
            />

            <p
                class="uk-text-small uk-padding-small uk-padding-remove-horizontal uk-padding-remove-top"
            >
                {{ $t('text.checkout.note.mandatory-fields')}}
            </p>

            <div v-if="showSubmit" class="uk-margin-small-top">
                <button class="uk-margin-medium-top uk-button uk-button-default uk-text-uppercase" @click="submit">
                    <span>{{ $t('text.send-now') }}</span>
                    <span v-if="isLoading" class="uk-preserve" uk-spinner="ratio:1"></span>
                </button>
            </div>
            <div v-else :class="['uk-margin-small-top', resultSuccess ? 'uk-alert-success' : 'uk-alert-danger']" uk-alert>
                <p v-html="resultMessage"></p>
            </div>
        </div>
    </div>
</template>
<script>
import BaseInput from "../form/base/BaseInput.vue";
import BaseRadio from "../form/base/BaseRadio.vue";
import BaseSelect from "../form/base/BaseSelect.vue";
import FloatingLabelInput from "../form/base/FloatingLabelInput.vue";
import BaseTextarea from "../form/base/BaseTextarea.vue";
import ApiService from "../../service/ApiService";
import {object, string} from "yup";

export default {
    name: 'ContactForm',
    components: {BaseTextarea, FloatingLabelInput, BaseSelect, BaseRadio, BaseInput},
    data() {
        return {
            isLoading: false,
            resultMessage: '',
            resultSuccess: false,
            showSubmit: true,
            config: {
                requestTypeOptions: [
                    {
                        value: 'product-support',
                        name: 'text.option.request-type.product-support'
                    }, {
                        value: 'order',
                        name: 'text.option.request-type.order'
                    }, {
                        value: 'delivery-time',
                        name: 'text.option.request-type.delivery-time'
                    },{
                        value: 'payment-and-credit-note',
                        name: 'text.option.request-type.payment-and-credit-note'
                    },{
                        value: 'returns-and-complaints',
                        name: 'text.option.request-type.returns-and-complaints'
                    }, {
                        value: 'other-requests',
                        name: 'text.option.request-type.other-requests'
                    }
                ],
                salutationOptions: [
                    {
                        value: 'male',
                        name: 'text.option.salutation.male'
                    }, {
                        value: 'female',
                        name: 'text.option.salutation.female'
                    }, {
                        value: 'diverse',
                        name: 'text.option.salutation.diverse'
                    }
                ]
            },
            values: {
                requestType: '',
                customerId: '',
                salutation: '',
                firstname: '',
                lastname: '',
                email: '',
                phoneNumber: '',
                message: '',
            },
            errors: {
                requestType: '',
                customerId: '',
                salutation: '',
                firstname: '',
                lastname: '',
                email: '',
                phoneNumber: '',
                message: ''
            },
            validationSchema: object({
                requestType: string().required(this.$t("text.error.input.required")),
                salutation: string().required(this.$t("text.error.input.required")),
                firstname: string().required(this.$t("text.error.input.required")),
                lastname: string().required(this.$t("text.error.input.required")),
                email: string().required(this.$t("text.error.input.required")).email(this.$t("text.error.input.invalid-email")),
                phoneNumber: string(),
                message: string().required(this.$t("text.error.input.required")),
                csrfTokenField: string(),
            })
        }
    },
    mounted() {
        this.prefillData();
    },
    methods: {
        submit(){
            this.errors = {};

            this.validationSchema
                .validate(this.values, { abortEarly: false })
                .then(() => {
                    this.errors = {};

                    this.sendRequest();
                })
                .catch(err => {
                    err.inner.forEach(error => {
                        this.errors[error.path] = error.message;
                    });
                });
        },
        sendRequest() {
            this.isLoading = true
            let sendFormPromise = ApiService.sendCustomerContactRequest(this.values)
            if (sendFormPromise) {
                sendFormPromise.then((response) => {
                    if(response.data) {
                        this.resultMessage = response.data.message
                        this.showSubmit = false
                        this.resultSuccess = response.data.success
                    }
                }).finally(() => {
                    this.isLoading = false
                })
            } else {
                this.isLoading = false
            }
        },
        prefillData() {
            this.showSubmit = true
            this.resultMmessage = ''
            return ApiService.getUserData(true).then(response => {
                if (response.data.user) {
                    let user = response.data.user
                    if (user.loggedIn) {
                        this.values.salutation = user.Gender ?? ''
                        this.values.firstname = user.Firstname ?? ''
                        this.values.lastname = user.Lastname ?? ''
                        this.values.email = user.Email ?? ''
                        this.values.phone = user.Phone ?? ''
                    }
                }
            })
        },
    },
}
</script>
import {defineStore} from "pinia"
import ApiService from "../service/ApiService"

export const useUserProfileStore = defineStore('UserProfileStore', {
    state: () => ({
        currentTab: 'dashboard',
        currentOrderId: 0,
        ordersCount: 0,
        orderList: {
            items: [],
            pageCount: 1,
        },
        wishlist: [],
        personalDataEditModeActive: false,
        personalDataEditMode: '',
        addressEditIndex: 0,
        paymentMethods: [],
    }),
    actions: {
        patchProfile(payload) {
            this.isLoading = true
            return ApiService.patchProfileData(payload)
            .then((response) => {
                this.isLoading = false
            })
            .catch((error) => {
                this.isLoading = false
                throw error
            })
        },
        patchPassword(payload) {
            this.isLoading
            return ApiService.patchUserPassword(payload)
        },
        setCurrentTab(currentTab, showTabRoot = false) {
            this.currentTab = currentTab

            if (currentTab === 'order-list' && showTabRoot) {
                this.setCurrentOrderId(0)
            }

            if (currentTab === 'personal-data' && showTabRoot) {
                this.setPersonalDataEditMode()
            }
        },
        setCurrentOrderId(id) {
            this.currentOrderId = id
        },
        setPersonalDataEditModeActive(isActive) {
            this.personalDataEditModeActive = isActive
        },
        setPersonalDataEditMode(editMode = '') {
            this.personalDataEditMode = editMode

            if (editMode !== '') {
                this.setPersonalDataEditModeActive(true)
            } else {
                this.setPersonalDataEditModeActive(false)
            }
        },
        setAddressEditIndex(index = 0) {
            this.addressEditIndex = index
        },
        loadOrderList(payload) {
            this.isLoading = true
            let page = null
            let limit = null
            if(payload) {
                if (payload.page) {
                    page = payload.page
                }
                if (payload.limit) {
                    limit = payload.limit
                }
            }
            return ApiService.loadOrderList(page, limit)
                .then((response) => {
                    this.orderList.items = response.data.items
                    this.orderList.pageCount = response.data.pageCount
                    this.ordersCount = Object.keys(this.orderList.items).length?? 0
                    return response
                }).finally(() => {
                    this.isLoading = false
                })
        },
        loadPaymentMethods() {
            this.isLoading = true
            return ApiService.fetchPaymentMethods()
                .then((response) => {
                    this.paymentMethods = response.data
                })
                .finally(() => {
                    this.isLoading = false
                })
        }
    },
    getters: {
        getOrdersCount: (state) => { return state.ordersCount }
    }
})
<template>
    <Header
        :headline="$t('text.user.profile.address-data.headline')"
        :subline="$t('text.user.profile.address-data.subline')"
    />
    <div class="user-app-address-data">
        <SubHeader :headerTest="$t('text.user.profile.personal-data.shipping-address-header')"/>
        <AddressSection />
    </div>
</template>

<script>

import AddressSection from "./address/AddressSection.vue";
import Header from "./partials/Header.vue";
import SubHeader from "./partials/SubHeader.vue";


export default {
    name: 'AddressData',
    components: {
        SubHeader,
        Header,
        AddressSection
    }
};

</script>

<template>
    <div class="uk-grid-medium" uk-grid>
        <div class="uk-width-1-1 uk-width-expand@s">
            <h2>{{ $t('text.user.profile.personal-data.headline') }}</h2>
        </div>
    </div>
    <hr class="uk-hr">
    <div>

        <span class="uk-text-bold">{{ $t('text.user.profile.personal-data.customer-data.headline') }}</span><br>
        {{ userStore.userData.Firstname }} {{ userStore.userData.Lastname }}<br>
        {{ userStore.userData.Email }}<br>
        {{ userStore.userData.Phone }}<br>
        <br>
        <!--<a href="#" @click.prevent="editData()">{{ $t('text.user.profile.edit-user-data') }}</a>--><br>

        <p v-if="userStore.getCustomerNumber">
            {{ $t('text.user.profile.your-customer-number') }}: <span class="uk-text-bold">{{ userStore.getCustomerNumber }}</span>
        </p>

    </div>

</template>

<script>

import {useUserStore} from "../../../../store/UserStore"
import {useUserProfileStore} from "../../../../store/UserProfileStore"

export default {
    name: 'PersonalData',
    setup() {

        const userStore = useUserStore()
        const userProfileStore = useUserProfileStore()

        return {
            userStore,
            userProfileStore
        }
    },
    methods: {
        editData() {
            this.userProfileStore.setCurrentTab('personal-data')
            this.userProfileStore.setPersonalDataEditMode('customer-data')
        }
    }
};
</script>

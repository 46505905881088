<template>
    <!--
    <div class="uk-margin-large-top uk-padding uk-background-muted">
        <h4 class="ws-text-sauterblau">Haben Sie Fragen?</h4>
        <p><img src="" alt="BILD">01234 - 56789 (0)</p>
        <div uk-grid>
            <div class="uk-width-1-2">Erreichbarkeit...</div>
            <div class="uk-width-1-2 uk-align-right">
                <a href="#">Zum Kontaktformular</a>
            </div>
        </div>
    </div>
    -->
</template>

<script>
export default {
    name: 'Contact'
};
</script>
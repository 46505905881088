<template>
    <div :class="{'error': !!errorMessage}">
        <label :for="uuid" v-if="label">{{ label }}<span v-if="required"><sup>*</sup></span></label>
        <input
            v-if="!isFocused && !modelValue"
            :placeholder="placeholderText"
            class="uk-input"
            @focus="handleFocus"
        />
        <textarea
            v-else
            ref="textarea"
            :placeholder="placeholderText"
            class="uk-textarea uk-padding-small"
            :class="textareaClass"
            :value="modelValue"
            @input="handleInput"
            @focus="isFocused = true"
            @blur="isFocused = false"
            autofocus
            :id="uuid"
            :aria-describedby="errorMessage ? `${uuid}-error` : null"
            :aria-invalid="errorMessage ? true : null"
        ></textarea>
        <p
            class="uk-text-danger"
            v-if="errorMessage"
            :id="`${uuid}-error`"
            aria-live="assertive"
        >
            {{ $t(errorMessage) }}
        </p>
    </div>
</template>

<script>
import UniqueID from "../../../includes/UniqueID.js";

export default {
    name: 'BaseTextarea',
    props: {
        label: {
            type: String,
            default: ''
        },
        placeholder: {
            type: String,
            default: ''
        },
        modelValue: {
            type: [String, Number],
            default: ''
        },
        required: {
            type: Boolean,
            default: true
        },
        errorMessage: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            isFocused: false,
        };
    },
    computed:{
        placeholderText(){
            let text = this.placeholder ? this.placeholder : this.label

            if(this.required) {
                text = text + '*'
            }
            return text
        },
        textareaClass() {
            return {
                'uk-border-muted': !this.isFocused && !this.modelValue,
                'uk-height-small uk-border-primary': this.isFocused || this.modelValue
            };
        }
    },
    methods: {
        handleInput(event) {
            this.$emit('update:modelValue', event.target.value);
        },
        handleFocus() {
            this.isFocused = true;
            this.$nextTick(() => {
                if (this.$refs.textarea) {
                    this.$refs.textarea.focus();
                }
            });
        }
    },
    setup() {
        const uuid = UniqueID().getID()
        return {
            uuid
        }
    }
};
</script>

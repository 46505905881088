<template>
    <div
        v-if="mobileCartVisible"
        class="uk-width-1-1 uk-position-bottom uk-position-z-index uk-position-fixed uk-background-default uk-hidden@m uk-animation-slide-bottom"
        :id="'mobileCartSection'"
        :key="fadeKey"
    >
        <div uk-grid class="uk-child-width-1-1">
            <hr>
            <div uk-grid="" class="uk-child-width-1-2 uk-grid uk-grid-margin uk-margin-small">
                <div class="">
                    <p class="uk-margin-small-left uk-text-bolder">{{ $t('text.shop.grandtotal') }}</p>
                </div>
                <div class="uk-text-right">
                    <p class="uk-text-bolder">{{ cartStore.cartData.TotalPriceString }}</p>
                </div>
            </div>
        </div>
        <BaseButton
            :full-width="true"
            :href="'/checkout'"
            class=""
        >
            {{ $t('text.shop.checkout') }}
        </BaseButton>
    </div>
</template>

<script>
import {useCartStore} from "../../store/CartStore";
import BaseCheckbox from "../form/base/BaseCheckbox.vue";
import BaseButton from "../form/base/BaseButton.vue";

export default {
    name: 'CartMobile',
    components: {BaseCheckbox, BaseButton},
    data() {
        return {
            cartStore: useCartStore(),
            mobileCartVisible: false,
            fadeKey: 'key'
        }
    },
    mounted() {
        window.addEventListener('scroll', this.checkPosition);
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.checkPosition);
    },
    methods: {
        checkPosition() {
            const buyButtonElement = document.querySelector('#toCheckoutButton');
            if (!buyButtonElement) return;

            const buyButtonPosition = buyButtonElement.getBoundingClientRect();

            this.mobileCartVisible = !(buyButtonPosition.top >= 0 && buyButtonPosition.bottom <= window.innerHeight);
        },
    }
}
</script>
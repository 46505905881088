<template>
    <div>
        <div class="uk-width-1-1 uk-text-primary uk-margin-medium-bottom">
            <a class="uk-margin-left uk-text-primary uk-link-reset" href="#" @click.prevent="addAddress()">
                <i class="fal fa-circle-plus uk-margin-right"></i>{{ $t('text.user.profile.address-data.add-address-link') }}
            </a>
        </div>
        <div
            class="uk-child-width-1-1 uk-child-width-1-2@s uk-child-width-1-4@l"
            uk-grid
            uk-height-match="target: > div > .uk-card"
        >
            <div
                v-for="(address, index) in userStore.userAddresses"
            >
                <div :id="'editModal'+address.addressUuid" uk-modal>
                    <div class="uk-modal-dialog uk-modal-body">
                        <addressEditForm
                            v-if="isInEditMode(address.addressUuid)"
                            :address="address"
                            :index="index"
                            @cancel-edit-address="(...args) => cancelEditAddress(address.addressUuid, ...args)"
                        />
                    </div>
                </div>
                <template v-if="!address.hidden">
                    <div class="uk-card uk-card-body"
                         :class="{
                            'uk-card-primary': (address.addressUuid === highlightedIndex) || address.defaultDeliveryAddress
                        }"
                         v-if="address">
                        <a
                            class="uk-float-right"
                            v-if="selectable"
                            @click.prevent="selectAddress(address.addressUuid)"
                            href="#"
                        >
                            <i
                                class="fal fa-2x uk-margin-right"
                                :class="{
                                'fa-check-circle': address.addressUuid === highlightedIndex,
                                'fa-circle': address.addressUuid !== highlightedIndex
                            }"
                            ></i>
                        </a>
                        <div class="uk-text-primary" v-else>
                            <i
                                class="fal fa-2x uk-margin-right"
                                @click="setAsDefaultAddress(address)"
                                :class="{
                                'fa-check-circle': address.defaultDeliveryAddress,
                                'fa-circle': !address.defaultDeliveryAddress,
                                'cursor-pointer': !address.defaultDeliveryAddress
                            }"
                            ></i>
                        </div>
                        <p class="uk-text-bolder">{{ $t('text.user.profile.address-data.header_address_card') }}</p>
                        <div class="uk-margin-large-bottom">
                            {{  $t('text.customer.salutation.' + address.gender)  }} {{ address.firstname }} {{ address.lastname }}<br>
                            <span v-if="address.company">{{ address.company }}<br></span>
                            <span v-if="address.streetExtra">{{ address.streetExtra }}<br></span>
                            <span v-if="isPackstationAddress(address.addressType)">
                                <i class="fa-brands fa-dhl"></i>
                                {{ address.ServicePoint.ServicePointName }}<br>
                            </span>
                            <span v-else>
                                {{ address.street }} {{ address.streetNumber }}<br>
                            </span>
                            {{ address.zip }} {{ address.city }}<br>
                            {{ getCountry(address.country) }}<br>
                            {{ address.phone }}
                        </div>
                        <div class="uk-position-bottom uk-padding-small uk-grid-collapse uk-padding-remove-right" uk-grid v-if="!selectable">
                            <div class="uk-width-1-2">
                                <a href="" @click.prevent="editAddress(address.addressUuid)">
                                    <i class="fal fa-pencil uk-margin-small-right"></i>
                                </a>
                            </div>
                            <div class="uk-width-1-2 uk-text-right">
                                <a href="" @click.prevent="deleteAddress(address.addressUuid)">
                                    <i class="fal fa-trash-alt uk-margin-small-right"></i>
                                </a>
                            </div>
                        </div>
                        <div v-else class="uk-position-bottom uk-padding-small uk-grid-collapse uk-padding-remove-right">
                            <a class="uk-position-bottom-left uk-margin-left uk-margin-small-bottom" href="" @click.prevent="editAddress(address.addressUuid)">
                                <i class="fal fa-pencil uk-margin-right"></i>
                            </a>
                            <a class="uk-position-bottom-right uk-margin-small-bottom " href="" @click.prevent="deleteAddress(address.addressUuid)">
                                <i class="fal fa-trash-alt uk-margin-right"></i>
                            </a>
                        </div>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import {defineComponent} from 'vue'
import AddressEditForm from "./AddressEditForm.vue";
import {useUserStore} from "../../../../store/UserStore";
import {useUserProfileStore} from "../../../../store/UserProfileStore";
import {modal, notification} from "uikit";
import {generateUUID} from "three/src/math/MathUtils";

export default defineComponent({
    name: "AddressSection",
    components: {
        AddressEditForm
    },
    props: {
        selectable: false,
        highlightedIndex: null
    },
    data() {
        return {
            userStore: useUserStore(),
            userProfileStore: useUserProfileStore()
        }
    },
    beforeMount() {
        this.userStore.fetchUserData()
        this.userStore.fetchUserAddresses()
    },
    methods: {
        selectAddress(uuid) {
            if (this.selectable) {
                this.$emit("selectAddress", uuid)
            }
        },
        setAsDefaultAddress(address) {
            if(address.defaultDeliveryAddress === true) {
                return;
            }

            address.defaultDeliveryAddress = true;

            this.userStore.saveUserAddress(address)
        },
        showNotification(message, status = "success") {
            const timeout = 5000
            notification({
                message: this.$t(message),
                status: status,
                pos: 'top-center',
                timeout: timeout
            })
            setTimeout(() => this.privacyError = false, timeout)
        },
        scrollToTop() {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        },
        getCountry(isoCode) {
            if (this.userStore.userData) {
                for (const countryKey in this.userStore.userData.countries) {
                    if (this.userStore.userData.countries[countryKey].value === isoCode) {
                        return this.userStore.userData.countries[countryKey].name;
                    }
                }
            }

            return 'Country not found';
        },
        isPackstationAddress(type) {
            return type === 'packstation'
        },
        isInEditMode(addressUuid) {
            return addressUuid === this.userProfileStore.addressEditIndex
        },
        editAddress(addressUuid) {
            this.userProfileStore.setAddressEditIndex(addressUuid)
            modal('#editModal' + addressUuid).show();
        },
        async cancelEditAddress(addressUuid, message, type = 'success') {
            this.scrollToTop()
            this.userProfileStore.setAddressEditIndex(0)
            await modal('#editModal' + addressUuid).hide();
            if (message) {
                this.showNotification(message, type)
            }
        },
        async deleteAddress(addressUuid) {
            try {
                await modal.confirm(this.$t('text.user.profile.address-data.delete-address-confirmation-message'), {
                    labels: {
                        ok: this.$t('text.default.yes'),
                        cancel: this.$t('text.default.no')
                    }
                });
                this.userStore.deleteUserAddress(addressUuid)
                    .then(() => {
                        this.showNotification("text.user.profile.address-data.deleted-notification");
                    }).catch((error) => {
                    this.showNotification('text.user.profile.address-data.error_occured', 'warning')
                })
            } catch (error) {
                this.showNotification('text.user.profile.address-data.deletion_cancelled', 'warning')
            }
        },
        saveAddresses() {
            this.userStore.saveUserAddresses()
        },
        async addAddress() {

            (new Promise((resolve) => {
                let newIndex = 0;
                const uuid = generateUUID()

                Object.entries(this.userStore.userAddresses).forEach(([key, value]) => {
                    if (key >= newIndex) {
                        newIndex = parseInt(key) + 1
                    }
                })

                this.userStore.userAddresses[newIndex] = {
                    addressType: 'standard',
                    gender: '',
                    firstname: '',
                    lastname: '',
                    postnummer: '',
                    company: '',
                    streetExtra: '',
                    street: '',
                    streetNumber: '',
                    zip: '',
                    city: '',
                    country: '',
                    phone: '',
                    ServicePoint: {
                        ServicePointId: null,
                        ServicePointName: null,
                        ServicePointComment: null
                    },
                    hidden: true,
                    addressUuid: uuid,
                    defaultDeliveryAddress: false
                }

                resolve(uuid)

            })).then(addressUuid => this.editAddress(addressUuid))

        }
    }
})
</script>
<template>
    <Summary :is-in-checkout="true" :cart="cartStore.cartData" />
</template>

<script>
import Summary from "../cart/Summary.vue";
import {useCartStore} from "../../store/CartStore.js"

export default {
    name: "CartSummary",
    setup() {
        const cartStore = useCartStore()
        return {
            cartStore
        }
    },
    components: {Summary },
    props: {
        cart: {
            type: Object,
            required: false
        },
        isInCheckout: {
            type: Boolean,
            default: false
        },

    }
}
</script>
<template>
    <div class="uk-background-default uk-hidden@s ws-cart-mobile-sticky">
        <div class="ws-cart-mobile-sticky-content">
            <div class="ws-price-default">{{ $t('text.shop.grandtotal') }}: {{ cart.TotalPriceString }} *</div>
            <div>
                <BaseButton :href="'/checkout'" :full-width="true">{{ $t('text.shop.checkout') }}</BaseButton>
            </div>
        </div>
    </div>
</template>

<script>
import BaseButton from "../form/base/BaseButton.vue";

export default {
    name: "StickyElementMobile",
    components: {BaseButton},
    props: {
        cart: {
            type: Object,
            required: true
        },
    },
}

</script>
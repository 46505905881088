<template>
    <div class="uk-margin-large-top">
        <h3>{{ $t('text.user.profile.personal-data.change_password_headline') }}</h3>
        <hr />
        <div class="uk-width-1-1 uk-width-1-2@m">
            <p>{{ $t('text.user.profile.personal-data.change_password_text') }}</p>
            <FloatingLabelInput
                v-model="currentPassword"
                :error-message="currentPasswordError"
                :label="$t('text.user.profile.personal-data.form.currentPassword')"
                type="password"
                :class="['uk-width-1-1 uk-margin-large-top uk-margin-remove-bottom']"
            />
            <FloatingLabelInput
                v-model="newPassword"
                :error-message="newPasswordError"
                :label="$t('text.user.profile.personal-data.form.newPassword')"
                type="password"
                :class="['uk-width-1-1 uk-margin-medium-top uk-margin-remove-bottom']"
            />
            <FloatingLabelInput
                v-model="confirmPassword"
                :error-message="confirmPasswordError"
                :label="$t('text.user.profile.personal-data.form.confirmPassword')"
                type="password"
                :class="['uk-width-1-1 uk-margin-medium-top uk-margin-remove-bottom']"
            />
            <BaseButton
                :href="'#'"
                :text="$t('text.user.profile.personal-data.form.text_button_submit')"
                :class="['uk-margin-large-top uk-padding-small']"
                @click="submitPassword"
            />
            <span class="uk-float-right uk-margin-small-top">{{ $t('text.checkout.note.mandatory-fields') }}</span>

            <div class="uk-margin-medium-top">
                <a class="" href="#" @click.prevent="closeEdit()">{{ $t('text.user.profile.personal-data.back_to_personal_data') }}</a>
            </div>
        </div>
    </div>
</template>

<script>
import {defineComponent} from 'vue'

import { useUserStore } from "../../../../store/UserStore";
import { useUserProfileStore } from "../../../../store/UserProfileStore";
import BaseInput from "../../../form/base/BaseTextarea.vue";
import FloatingLabelInput from "../../../form/base/FloatingLabelInput.vue";
import { useField, useForm } from 'vee-validate';
import { required } from '@vee-validate/rules';
import BaseButton from "../../../form/base/BaseButton.vue";
import { notification } from "uikit";

export default defineComponent({
    name: "PasswordForm",
    components: {BaseButton, FloatingLabelInput, BaseInput},
    setup() {
        const { handleSubmit, meta } = useForm();
        const formSubmitting = handleSubmit((values) => values)

        const userStore = useUserStore()
        const userProfileStore = useUserProfileStore()

        const { value: currentPassword, errorMessage: currentPasswordError } = useField('currentPassword', required);
        const { value: newPassword, errorMessage: newPasswordError } = useField('newPassword', required);
        const { value: confirmPassword, errorMessage: confirmPasswordError } = useField('confirmPassword', value => {
            if (!value) {
                return 'is not valid'
            }
            if (newPassword.value !== value) {
                return 'Passwords must match'
            }
            return true
        })

        return {
            currentPassword,
            currentPasswordError,
            newPassword,
            newPasswordError,
            confirmPassword,
            confirmPasswordError,
            userStore,
            userProfileStore,
            handleSubmit,
            formSubmitting,
            meta
        }

    },
    methods: {
        showNotification(message, status = "success") {
            const timeout = 5000
            notification({
                message: this.$t(message),
                status: status,
                pos: 'top-center',
                timeout: timeout
            })
            setTimeout(() => this.privacyError = false, timeout)
        },
        async submitPassword() {
            await this.formSubmitting();
            if (this.meta.valid) {
                this.userProfileStore.patchPassword({
                    oldPassword: this.currentPassword,
                    newPassword: this.newPassword
                })
                .then((result) => {
                    this.showNotification('text.user.profile.personal-data.form.text_button_submit_success')
                })
                .catch((error) => {
                    switch (error.response.status) {
                        case 401:
                            this.showNotification('text.user.profile.personal-data.form.password_not_match', 'warning')
                            break;
                        case 406:
                            this.showNotification('text.user.profile.personal-data.form.password_not_valid', 'warning')
                            break;
                        default:
                            this.showNotification('text.user.profile.personal-data.form.text_button_submit_not_success', 'warning')
                            break;
                    }
                })
                    .finally(() => this.userProfileStore.setPersonalDataEditMode());
            }
        },
        closeEdit() {
            this.userProfileStore.setPersonalDataEditMode()
        }
    }
})
</script>
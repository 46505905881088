<template>
    <div class="ws-configurator">
        <div class="uk-grid-collapse spinner" uk-grid v-if="spinner">
            <div uk-spinner="ratio: 3" class="uk-position-center"></div>
        </div>
        <div class="uk-grid-collapse uk-grid-match" uk-grid v-if="!spinner">
            <div class="uk-width-1-1 uk-width-3-5@m ws-configurator-image-section uk-inline uk-position-relative">
                <div v-if="modelLoader" class="uk-position-cover uk-position-z-index opacity-70 uk-background-muted">
                    <div uk-spinner="ratio: 3" class="uk-position-center"></div>
                </div>
                <div class="uk-position-top-center uk-position-z-index uk-position-absolute uk-flex uk-flex-wrap uk-flex-center" v-if="ConfiguratorStore.configuratorProductUniqueIdentifier">
                    <ConfiguratorRememberShare />
                </div>
                <template v-if="DddObject">
                    <model-viewer
                        :src="DddObject.glb"
                        :ios-src="DddObject.usdz"
                        alt="A 3D model"
                        quick-look-browsers="safari chrome"
                        class="ws-configurator-viewer uk-margin-large-top"
                        id="model-viewer"
                        :poster="product.posterImage"
                        camera-controls
                        loading="eager"
                        @load="unsetLoader"
                    >
                    </model-viewer>
                    <a
                        :id='DddObject.scanblueId'
                        v-if="DddObject.scanblueId?? false"
                        :uk-tooltip="$t('configurator.ar.tooltip')"
                        class="uk-margin-small-right uk-margin-small-bottom uk-box-shadow-medium uk-width-auto uk-button uk-button-default uk-border-circle uk-padding-small uk-position-bottom-right"
                    >
                        <i class="fa-thin fa-cube uk-margin-remove icon"></i>
                    </a>
                </template>
                <div
                    v-else-if="!ConfiguratorStore.getEntireArticleListIds.length"
                    v-html="product.image"
                    :class="['uk-text-center uk-margin-large-top']"
                >
                </div>
                <div
                    v-else
                    class="ws-preview-images uk-margin uk-padding uk-padding-remove-right uk-margin-medium-top"
                >
                    <div class="uk-flex uk-flex-wrap uk-flex-center uk-grid uk-grid-stack" uk-grid>
                        <div
                            v-for="product in standardImages.filter(i => i.ThumbnailHtml)"
                            :key="product.Id"
                            class="uk-margin-small-right uk-margin-small-bottom"
                            v-html="product.ThumbnailHtml"
                        >
                        </div>
                    </div>
                </div>
                <div class="ws-preview-images uk-margin-bottom uk-padding uk-padding-remove-right">
                    <div v-if="DddObject" class=" uk-flex uk-flex-wrap uk-flex-center" uk-grid>
                        <div class="uk-flex uk-flex-wrap uk-flex-center uk-grid-small@s uk-child-width-1-4" uk-grid="">
                            <div class="uk-text-center">
                                <i class="fa-solid fa-arrow-rotate-left uk-button uk-text-default" @click="resetZoom()"></i>
                                <br>
                                <span class="uk-text-uppercase uk-text-small">{{ $t('text.back-link') }}</span>
                            </div>
                            <div class="uk-text-center">
                                <i @click="zoomOut()" class="fa-solid fa-magnifying-glass-minus uk-button uk-text-default"></i>
                            </div>
                            <div class="uk-text-center">
                                <input
                                    class="uk-range uk-margin-top"
                                    type="range"
                                    id="zoomSlider"
                                    :min="minZoom"
                                    :max="maxZoom"
                                    :step="stepZoom"
                                    :value="initialZoom"
                                    @input="onZoomChange"
                                >
                                <br>
                                <span class="uk-text-uppercase uk-text-small">{{ $t('configurator.zoom-text') }}</span>
                            </div>
                            <div class="uk-text-center ">
                                <i @click="zoomIn()" class="fa-solid uk-button fa-magnifying-glass-plus uk-button uk-text-default"></i>
                            </div>
                        </div>
                    </div>
                    <div class="uk-flex uk-flex-wrap uk-flex-center" uk-grid>
                        <div
                            v-for="product in additionalImages.filter(i => i.ThumbnailHtml)"
                            :key="product.Id"
                            class="uk-margin-small-right uk-margin-small-bottom"
                            v-html="product.ThumbnailHtml"
                        >
                        </div>
                    </div>
                </div>
            </div>
            <div class="uk-width-1-1 uk-width-2-5@m ws-configurator-article-section">
                <div v-if="!spinner && ConfiguratorStore.getCategories.length > 0" class="uk-flex uk-flex-column">
                    <template
                        v-for="category in ConfiguratorStore.getCategories"
                        :key="category.id"
                    >
                        <div
                            :class="{
                                'ws-list-element-header--open uk-flex uk-flex-start': openCategory === category.identifier,
                                'ws-list-element-default uk-flex uk-flex-between uk-flex-middle': openCategory !== category.identifier
                            }"
                            class="ws-category-selector uk-button uk-width-1-1 uk-text-middle text-left" href="#"
                            @click="ConfiguratorStore.getIsCategoryEditable(category.identifier) ? openCategory = category.identifier : null"
                        >
                            <span>
                                {{ category.name }}
                            </span>
                            <span
                                v-if="ConfiguratorStore.addedProducts[category.identifier] && openCategory !== category.identifier"
                                class="ws-list-element-header__icon fa-solid fa-square-check uk-text-primary"
                            ></span>
                        </div>
                        <div
                            class="ws-category-content uk-flex-1 uk-flex uk-flex-column"
                            v-if="openCategory===category.identifier"
                        >
                            <ConfiguratorCategoryProducts
                                :category="category"
                                :products="category.articles"
                                @detailsModalOpen="openProductDetailsModal"
                            />
                        </div>
                    </template>
                </div>
            </div>
            <div v-if="ConfiguratorStore.getConfiguratorProductHasDiscount"
                 class="uk-text-default uk-text-normal uk-width-1-1 uk-width-3-5@m ws-list-element-success ws-list-element-height ws-list-element uk-button-small text-left">
                {{ $t('configurator.enjoy_your_discount') }}:
                <span class="uk-text-bold">{{ ConfiguratorStore.getConfiguratorProductDiscountPriceString }}</span>
            </div>
            <div v-else
                 class="uk-text-default uk-text-normal uk-width-1-1 uk-width-3-5@m ws-list-element-success ws-list-element-height ws-list-element uk-button-small text-left">
                <span v-if="ConfiguratorStore.getConfiguratorProductDiscountMessage">{{
                        $t(ConfiguratorStore.getConfiguratorProductDiscountMessage)
                    }}</span>
            </div>
            <div
                v-if="!alreadyInCart"
                @click="addToCart"
                :class="{'uk-link': ConfiguratorStore.getArticleListIds.length}"
                class="uk-text-default uk-text-bolder uk-width-1-1 uk-width-2-5@m ws-list-element-primary ws-list-element-height ws-list-element uk-button-small text-left">
                {{ $t('configurator.your_configurator_price') }}
                {{ ConfiguratorStore.getConfiguratorProductTotalPriceString }}
            </div>
            <div
                v-else
                @click="goToCart"
                :class="{'uk-link': ConfiguratorStore.getArticleListIds.length}"
                class="uk-text-default uk-text-bolder uk-width-1-1 uk-width-2-5@m ws-list-element-primary ws-list-element-height ws-list-element uk-button-small text-left">
                {{ $t('configurator.your_configurator_price') }}
                {{ ConfiguratorStore.getConfiguratorProductTotalPriceString }}
            </div>
            <div class="uk-width-1-1">
                <ConfiguratorArticles/>
                <!--
                <ConfiguratorExistingArticles />
                -->
            </div>
            <div class="uk-width-1-1 uk-margin-bottom" v-if="ConfiguratorStore.getArticleListIds.length">
                <div
                    class="ws-configurator-total uk-flex-right"
                    uk-grid>
                    <div class="uk-text-right">
                        <span class="uk-h3">
                            {{ $t('configurator.sum_subtotal_text') }}:
                        </span>
                    </div>
                    <div class="uk-text-right">
                        <span class="uk-h3">
                            {{ ConfiguratorStore.getConfiguratorProductOriginalPriceString }}
                        </span>
                    </div>
                </div>
                <div v-if="ConfiguratorStore.getConfiguratorProductHasDiscount"
                     class="ws-configurator-total uk-flex-right"
                     uk-grid>
                    <div class="uk-text-right">
                        <span class="uk-text-bold uk-h2">
                            {{ ConfiguratorStore.getConfiguratorProductDiscountName }}:
                        </span>
                    </div>
                    <div class="uk-text-right">
                        <span class="uk-text-bold uk-h2">
                            - {{ ConfiguratorStore.getConfiguratorProductDiscountPriceString }}
                        </span>
                    </div>
                </div>
                <div
                    class="ws-configurator-total uk-flex-right"
                    uk-grid>
                    <div class="uk-text-right">
                        <span class="uk-text-bold uk-h3">
                            {{ $t('configurator.sum_total_text') }}:
                        </span>
                    </div>
                    <div class="uk-text-right">
                        <span class="uk-text-bold uk-h3">
                            {{ ConfiguratorStore.getConfiguratorProductTotalPriceString }}
                        </span>
                    </div>
                </div>
                <p class="uk-text-right" v-if="ConfiguratorStore.getConfiguratorProductPriceInfoDescription"
                   v-html="$t(ConfiguratorStore.getConfiguratorProductPriceInfoDescription)"></p>
            </div>
            <div class="uk-width-1-1 uk-margin-bottom uk-text-center uk-padding-large" v-else>
                <p class="uk-text-center uk-h2">
                    {{ $t('configurator.no_articles_added_yet') }}
                </p>
            </div>
            <div class="uk-width-1-1 uk-margin-bottom" v-if="ConfiguratorStore.getArticleListIds.length">
                <button
                    v-if="!alreadyInCart"
                    @click="addToCart"
                    class="uk-float-right uk-button-default uk-button uk-button-small uk-padding-small uk-margin"
                >
                    {{ $t('configurator.add_to_cart') }}
                </button>
                <a
                    v-else
                    :href="CartStore.cartData.cartRoute"
                    class="uk-float-right uk-button-default uk-button uk-button-small uk-padding-small uk-margin"
                >
                    {{ $t('configurator.see_in_cart') }}
                </a>
            </div>
        </div>
        <div class="uk-flex-top uk-modal-container" uk-modal id="productDetailsModal">
            <div class="uk-modal-dialog uk-margin-auto-vertical uk-margin-auto-vertical" uk-overflow-auto>
                <ConfiguratorProductDetail :article="productDetails.article" :category="productDetails.category"/>
            </div>
        </div>
    </div>
</template>

<script>

import axios from "axios";
import ConfiguratorCategoryProducts from "./ConfiguratorCategoryProducts.vue";
import {useConfiguratorStore} from "../../store/ConfiguratorStore";
import ConfiguratorArticles from "./ConfiguratorArticles.vue";
import ConfiguratorExistingArticles from "./ConfiguratorExistingArticles.vue";
import {modal, notification} from "uikit"
import {useCartStore} from "../../store/CartStore";
import ConfiguratorProductDetail from "./ConfiguratorProductDetails.vue";
import ConfiguratorRememberShare from "./ConfiguratorRememberShare.vue";

export default {
    name: 'ConfiguratorApp',
    components: {
        ConfiguratorRememberShare,
        ConfiguratorProductDetail,
        ConfiguratorExistingArticles,
        ConfiguratorArticles,
        ConfiguratorCategoryProducts
    },
    data() {
        return {
            spinner: true,
            loaded: {
                configurator: false,
                product: false
            },
            categories: [],
            product: {},
            openCategory: null,
            ConfiguratorStore: useConfiguratorStore(),
            articleDetail: null,
            configuratorProduct: {},
            CartStore: useCartStore(),
            productDetails: {
                article: {},
                category: {}
            },
            minZoom: 0,
            maxZoom: 22,
            stepZoom: 2,
            initialZoom: 1,
            currentZoom: this.initialZoom,
            manualControl: false,
            lastMouseX: 0,
            lastMouseY: 0,
            orbitX: 0,
            orbitY: 75,
            DddObject: null,
            requestModels: [],
            modelLoader: false
        };
    },
    watch: {
        'ConfiguratorStore.entireArticleListModels': {
            handler(newVal, oldVal) {
                if (newVal !== oldVal) {
                    console.log('loadImage')
                    this.loadImage()
                }
            }
        },
        loaded: {
            handler: function (val) {
                if (val.configurator && val.product) {
                    this.spinner = false;
                    this.openCategory = this.ConfiguratorStore.getNextUnDoneCategory ?? this.ConfiguratorStore.getLastCategoryIdentifier;
                }
            },
            deep: true
        },
        categories: {
            handler(newCategories) {
                const newCategory = newCategories.find(cat => this.ConfiguratorStore.productsAdded[cat.identifier] === undefined);
                if (newCategory) {
                    this.openCategory = this.ConfiguratorStore.getNextUnDoneCategory;
                }
            },
            deep: true
        },
        alertSuccessMessage(newVal, oldVal) {
            if (newVal !== oldVal && newVal) {
                this.showNotification();
            }
        }
    },
    computed: {
        alreadyInCart() {
            return Object.values(this.CartStore.cartItems).some(itemsArray =>
                itemsArray.some(item => item.Artikelnummer === this.ConfiguratorStore.configuratorProduct.Artikelnummer)
            );
        },
        alertSuccessMessage() {
            return this.ConfiguratorStore.alertMessage.success;
        },
        additionalImages() {
            const images = []
            this.ConfiguratorStore.getCategories.forEach(category => {
                if (category.display_mode === "2" && this.ConfiguratorStore.getArticleList[category.identifier] !== undefined) {
                    this.ConfiguratorStore.getArticleList[category.identifier].forEach(article => {
                        if (images.find(img => img.Id === article.Id) === undefined) {
                            images.push(article)
                        }
                    })
                }
            })
            return images
        },
        standardImages() {
            const images = []
            this.ConfiguratorStore.getCategories.forEach(category => {
                if (category.display_mode === "1" && this.ConfiguratorStore.getArticleList[category.identifier] !== undefined) {
                    this.ConfiguratorStore.getArticleList[category.identifier].forEach(article => {
                        if (images.find(img => img.Id === article.Id) === undefined) {
                            images.push(article)
                        }
                    })
                }
            })
            return images
        }
    },
    methods: {
        async updateDddImage() {
            try {
                await new Promise((resolve) => {
                    const elements = document.querySelectorAll('[id^="wrapper-scanblue"]');
                    elements.forEach(function(el) {
                        el.remove();
                    });
                    resolve();
                });
                await Scanblue.clearCache();
                await Scanblue.enhanceArElements()
            } catch (e) {
                console.log("Scanblue", e)
            }
        },
        unsetLoader() {
            this.modelLoader = false
            this.updateDddImage()
        },
        enableManualControl(event) {
            this.manualControl = true
            this.lastMouseX = event.clientX
            this.lastMouseY = event.clientY
            window.addEventListener('mousemove', this.updateOrbit)
        },
        disableManualControl() {
            this.manualControl = false
            window.removeEventListener('mousemove', this.updateOrbit)
        },
        updateOrbit(event) {
            if (!this.manualControl) return
            const modelViewer = document.getElementById("model-viewer")
            const dx = event.clientX - this.lastMouseX
            const dy = event.clientY - this.lastMouseY
            this.lastMouseX = event.clientX
            this.lastMouseY = event.clientY

            this.orbitX += dx * 0.1
            this.orbitY += dy * 0.1
            modelViewer.setAttribute("camera-orbit", `${this.orbitX}deg ${this.orbitY}deg auto`)
        },
        onZoomChange(event) {
            const modelViewer = document.getElementById("model-viewer");
            const desiredZoom = parseInt(event.target.value);
            const zoomDifference = desiredZoom - this.currentZoom;
            const zoomDirection = Math.sign(zoomDifference) * this.stepZoom;
            for (let i = 0; i < Math.abs(zoomDifference); i++) {
                modelViewer.zoom(zoomDirection);
            }
            this.currentZoom = desiredZoom;
        },
        zoomIn() {
            const modelViewer = document.getElementById("model-viewer");
            this.currentZoom += this.stepZoom;
            modelViewer.zoom(this.stepZoom);
        },
        zoomOut() {
            const modelViewer = document.getElementById("model-viewer");
            this.currentZoom -= this.stepZoom;
            modelViewer.zoom(-this.stepZoom);
        },
        resetZoom() {
            const modelViewer = document.getElementById("model-viewer");
            const zoomSlider = document.getElementById("zoomSlider");
            const zoomDirection = this.currentZoom > 0 ? -parseFloat(this.stepZoom) : parseFloat(this.stepZoom);
            for (let i = 0; i < Math.abs(this.currentZoom); i++) {
                modelViewer.zoom(zoomDirection);
            }
            zoomSlider.value = this.initialZoom;
            this.currentZoom = 0;
        },
        resetModelViewer() {
            const modelViewer = document.getElementById("model-viewer")
            this.currentZoom = this.initialZoom
            this.orbitX = 0
            this.orbitY = 75
            modelViewer.setAttribute("camera-orbit", `${this.orbitX}deg ${this.orbitY}deg 1m`)
            document.getElementById("zoomSlider").value = this.initialZoom
        },
        showNotification() {
            const timeout = 5000
            notification({
                message: this.ConfiguratorStore.alertMessage.message,
                status: this.ConfiguratorStore.alertMessage.success ? 'success' : 'danger',
                pos: 'top-center',
                timeout: timeout
            })
            setTimeout(() => this.ConfiguratorStore.unsetAlertMessage(), timeout)
        },
        goToCart() {
            window.location.href = this.CartStore.cartData.cartRoute;
        },
        addToCart() {
            if (this.ConfiguratorStore.getArticleListIds.length) {
                this.CartStore.addCartItem({
                    pid: this.ConfiguratorStore.configuratorProduct.Id,
                    cartItemsCount: 1,
                    type: 'cart'
                })
            }
        },
        openProductDetailsModal(payload) {
            this.productDetails.article = payload.article;
            this.productDetails.category = payload.category;
            modal('#productDetailsModal').show();
        },
        setupArticles(articleList, setListFunc, addIdFunc) {
            this.ConfiguratorStore[setListFunc](articleList);
            Object.keys(articleList).forEach(category => {
                articleList[category].forEach(article => {
                    this.ConfiguratorStore[addIdFunc](article.Id);
                    this.ConfiguratorStore.setProductsAdded(category, article.Id);
                    this.ConfiguratorStore.addModelToArticleList(article.Artikelnummer)
                });
                this.ConfiguratorStore.setCategoriesDone(category);
            });
        },
        getConfiguratorProduct(configuratorProductIdValue = null) {
            if (configuratorProductIdValue) {
                axios.get(`/api/configurator/${this.ConfiguratorStore.getConfiguratorIdentifier}/product/${this.ConfiguratorStore.getconfiguratorProductUniqueIdentifier}`).then(({data}) => {
                    this.ConfiguratorStore.unsetProductsAdded();
                    this.ConfiguratorStore.setConfiguratorProduct(data)
                    if (data.ArticleList) {
                        this.setupArticles(data.ArticleList, 'setArticleList', 'addArticleToArticleListIds');
                    }
                    if (data.ExistingArticleList) {
                        this.setupArticles(data.ExistingArticleList, 'setExistingArticleList', 'addArticleToExistingArticleListIds');
                    }
                    this.loaded.product = true;
                }).finally(() => {
                    this.loadImage()
                });
            } else {
                this.loaded.product = true;
            }
        },
        getConfigurator() {
            axios.get(`/api/configurator/${this.ConfiguratorStore.getConfiguratorIdentifier}`)
                .then(({data}) => {
                    this.ConfiguratorStore.setCategories(data.categories)
                    this.ConfiguratorStore.setNon3D(data.non3D)
                    this.product = data.product
                    this.loaded.configurator = true;
                })
                .catch(() => {
                    this.loaded.configurator = true;
                })
                .finally(() => {
                    this.ConfiguratorStore.postConfiguratorReturnProductPrices(this.ConfiguratorStore.getAllAvailableConfiguratorProducts)
                });
        },
        async loadImage() {
            let doRequest = false
            const newRequest = this.ConfiguratorStore.getEntireArticleListModels.filter(item => !this.ConfiguratorStore.getNon3D.includes(item))
            if (JSON.stringify(newRequest.sort()) !== JSON.stringify(this.requestModels.sort())) {
                this.requestModels = this.ConfiguratorStore.getEntireArticleListModels.filter(item => !this.ConfiguratorStore.getNon3D.includes(item))
                doRequest = true
            }
            try {
                if (doRequest) {
                    this.modelLoader = true
                    const response = await axios.post(`/api/configurator/${this.ConfiguratorStore.getConfiguratorIdentifier}/image`, {
                        models: this.requestModels
                    })
                    if (response.status === 200) {
                        this.DddObject = response.data
                    } else {
                        this.DddObject = null
                        this.unsetLoader()
                    }
                }
            } catch (e) {
                this.unsetLoader()
                this.DddObject = null
            }
        },
        loadScript(src, callback) {
            const script = document.createElement("script");
            script.type = "module";
            script.src = src;
            script.onload = callback;
            document.head.appendChild(script);
        },
        unloadScript(src) {
            const scripts = [...document.querySelectorAll('script[src]')];
            const script = scripts.find(s => s.getAttribute('src') === src);
            if (script) {
                script.parentNode.removeChild(script);
            }
        }
    },
    created() {
        if (this.configuratorProductUniqueIdentifier) {
            this.ConfiguratorStore.setConfiguratorProductUniqueIdentifier(this.configuratorProductUniqueIdentifier)
        }
        if (this.configuratorIdentifier) {
            this.ConfiguratorStore.setConfiguratorIdentifier(this.configuratorIdentifier)
        }
    },
    mounted() {
        this.getConfigurator();
        this.getConfiguratorProduct(this.ConfiguratorStore.configuratorProductUniqueIdentifier)
        this.loadScript("https://ajax.googleapis.com/ajax/libs/model-viewer/3.1.1/model-viewer.min.js");
    },
    beforeDestroy() {
        this.unloadScript("https://ajax.googleapis.com/ajax/libs/model-viewer/3.1.1/model-viewer.min.js");
    }
}

</script>
<style scoped>


</style>
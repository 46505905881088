<template>
    <div class="uk-margin-large-top">
        <div class="uk-grid-medium" uk-grid>
            <div class="uk-width-1-1 uk-width-expand@s">
                <h2>{{ $t('text.user.profile.contact-ways') }}</h2>
            </div>
        </div>
        <hr />
        <div uk-grid class="uk-child-width-1-1 uk-child-width-1-2@m">
            <div>
                <div class="uk-card uk-card-body">
                    <h4>{{ $t('text.user.profile.still-questions-header') }}</h4>
                    <p>{{ $t('text.user.profile.check-faq') }}</p>
                    <p>{{ $t('text.user.profile.check-service-section') }}</p>
                </div>
            </div>
            <div>
                <div class="uk-card uk-card-body">
                    <h4>{{ $t('text.user.profile.contact-header') }}?</h4>
                    <p><i class="fa-light fa-phone-alt"></i> {{ $t('text.user.profile.phone') }}</p>
                    <p><i class="fa-light fa-envelope"></i> {{ $t('text.user.profile.email') }}</p>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "ContactSection"
}
</script>
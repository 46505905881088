<template>
    <div class="uk-padding-remove-top">
        <MobileSummary
            :is-in-checkout="true"
            :cart="CartStore.cartData"
            @orderSubmit="orderSubmit"
        />
        <div uk-grid>
            <div class="uk-width-1-1 uk-margin-remove" id="ws-checkout-top"></div>
            <div class="uk-width-1-1 uk-width-3-5@m">
                <div
                    class="ws-checkout-error-general uk-text-danger"
                    v-if="
                        CheckoutStore.checkoutData.error.general
                        && !(['address', 'delivery', 'payment', 'confirmation']).includes(CheckoutStore.checkoutData.current)"
                >
                    {{ $t(errorMessage) }}
                </div>
                <VatStatus />
                <HintBox
                    class="uk-margin-medium-bottom"
                    v-if="CheckoutStore.checkoutData.current === 'confirmation'"
                    :content="$t('text.checkout.review-order')" />

                <div v-if="formSize === 'short'" class="uk-child-width-1-2 uk-margin-medium" uk-grid>
                    <div>
                        <button
                            class="uk-width-1-1 uk-height-1-1 uk-button uk-button-muted uk-text-uppercase"
                            :class="{ 'uk-button-default': state === 'login' }"
                            @click="CheckoutStore.showCheckoutForm = false; state = 'login'">
                            <span>
                                {{ $t('text.checkout.button.login') }}
                            </span>
                        </button>
                    </div>
                    <div>
                        <button
                            class="uk-width-1-1 uk-height-1-1 uk-button uk-button-muted uk-text-uppercase"
                            :class="{ 'uk-button-default': state === 'register' }"
                            @click="CheckoutStore.showCheckoutForm = true; state = 'register'">
                            <span>
                                {{ $t('text.checkout.button.continue-as-guest') }}
                            </span>
                        </button>
                    </div>
                </div>
                <div v-if="!CheckoutStore.showCheckoutForm">
                    <UserLoginForm
                        :redirect-to="'/checkout'"
                    ></UserLoginForm>
                </div>
                <div v-if="CheckoutStore.showCheckoutForm">
                    <StepHeading
                        v-if="formSize === 'long'"
                        :current-step="CheckoutStore.checkoutData.current"
                        :expected-step="'address'"
                        :show-edit-link="CheckoutStore.checkoutData.steps.address.isFilled"
                    >
                        {{ stepNumbers.address }}. {{ $t('text.checkout.headline.address') }}
                    </StepHeading>
                    <Address
                        :step-is-active="(CheckoutStore.checkoutData.current === 'address')"
                        :step-is-filled="CheckoutStore.checkoutData.steps.address.isFilled"
                        :formSize="formSize"
                        @change-form-size="(val) => changeFormSize(val)"
                    ></Address>

                    <template v-if="formSize === 'long'">
                        <StepHeading
                            :current-step="CheckoutStore.checkoutData.current"
                            :expected-step="'delivery'"
                            :show-edit-link="CheckoutStore.checkoutData.steps.delivery.isFilled"
                        >
                            {{ stepNumbers.delivery }}. {{ $t('text.checkout.headline.delivery') }}
                        </StepHeading>
                        <Delivery
                            :step-is-active="CheckoutStore.checkoutData.current === 'delivery'"
                            :step-is-filled="CheckoutStore.checkoutData.steps.address.isFilled"
                            :next-step-translation-key="'text.checkout.button.go-to-payment'"
                        >
                        </Delivery>
                        <StepHeading
                            v-if="CheckoutStore.checkoutData.hasPayment"
                            :current-step="CheckoutStore.checkoutData.current"
                            :expected-step="'payment'"
                            :show-edit-link="CheckoutStore.checkoutData.steps.payment.isFilled"
                        >
                            {{ stepNumbers.payment }}. {{ $t('text.checkout.headline.payment') }}
                        </StepHeading>
                        <Payment
                            v-if="CheckoutStore.checkoutData.hasPayment "
                            :step-is-active="CheckoutStore.checkoutData.current === 'payment'"
                            :step-is-filled="CheckoutStore.checkoutData.steps.payment.isFilled"
                        ></Payment>

                        <MobileConfirmation
                            :step-is-active="CheckoutStore.checkoutData.current === 'confirmation'"
                            :catalogue-image="CheckoutStore.catalogueImage"
                            :submitOrder="submitOrder"
                            :class="['uk-hidden@s']"
                        />

                        <Confirmation
                            :step-is-active="CheckoutStore.checkoutData.current === 'confirmation'"
                            :catalogue-image="CheckoutStore.catalogueImage"
                            :submitOrder="submitOrder"
                            :class="['uk-visible@m']"
                        />
                    </template>
                </div>

                <p
                    class="uk-text-small uk-padding-small uk-padding-remove-horizontal uk-padding-remove-top"
                >
                    {{ $t('text.checkout.note.mandatory-fields')}}
                </p>

            </div>
            <div class="uk-width-1-1 uk-width-2-5@m uk-visible@m" style="z-index: 0">
                <CartSummary  />
            </div>
            <div class="uk-width-1-1 uk-margin-remove" id="ws-checkout-cart-summary-sticky-end"></div>
        </div>

    </div>
</template>

<script>
import CartSummary from "./CartSummary.vue";
import StepHeading from "./StepHeading.vue";
import Address from "./steps/Address.vue";
import Delivery from "./steps/Delivery.vue";
import Payment from "./steps/Payment.vue";
import Confirmation from "./steps/Confirmation.vue";
import UserLoginForm from "../user/UserLoginForm.vue";
import BaseButton from "../form/base/BaseButton.vue";
import BaseRadio from "../form/base/BaseRadio.vue";
import FloatingLabelInput from "../form/base/FloatingLabelInput.vue";
import HintBox from "../website/HintBox.vue";
import Spinner from "../website/Spinner.vue";
import {useCheckoutStore} from "../../store/CheckoutStore";
import {useCartStore} from "../../store/CartStore";
import MobileSummary from "../cart/MobileSummary.vue";
import MobileConfirmation from "./steps/MobileConfirmation.vue";
import VatStatus from "../notifications/VatStatus.vue";

export default {
    name: "CheckoutView",

    components: {
        VatStatus,
        MobileConfirmation,
        MobileSummary,
        CartSummary,
        StepHeading,
        Address,
        Delivery,
        Payment,
        Confirmation,
        UserLoginForm,
        BaseButton,
        BaseRadio,
        FloatingLabelInput,
        HintBox,
        Spinner
    },

    data() {
        return {
            isLoading: true,
            state: 'login',
            formStep: 'short',
            CheckoutStore: useCheckoutStore(),
            CartStore: useCartStore(),
            email: '',
            emailConfirm: '',
            customerType: '',
            gender: '',
            firstname: '',
            lastname: '',
            phone: '',
            submitOrder: false
        };
    },

    computed: {
        formSize: function() {
            return this.CheckoutStore.checkoutData.isLoggedIn || this.formStep === 'long' || this.CheckoutStore.checkoutData.steps.address.isFilled
                ? 'long'
                :'short';
        },
        errorMessage() {
            return ('text.checkout.payment.error.'+this.CheckoutStore.checkoutData.error.general).trim().toLowerCase()
        },
        stepNumbers() {
            let steps = ['address', 'delivery', 'payment', 'confirmation'];
            let currentNumber = 1;
            let numbers = {};

            if (this.CheckoutStore.showCheckoutForm && this.formSize === 'long') {
                steps.forEach(step => {
                    if (step === 'payment' && !this.CheckoutStore.checkoutData.hasPayment) {
                        return;
                    }
                    numbers[step] = currentNumber++;
                });
            }

            return numbers;
        }
    },

    methods: {
        orderSubmit() {
            this.CheckoutStore.checkoutData.steps.confirmation.data.customer = this.CheckoutStore.checkoutData.steps.address.data.customer
            this.CheckoutStore.checkoutData.isFinished = true;
            this.submitOrder = true;
        },
        async fetchData() {
            await this.CheckoutStore.fetchCheckoutData();
            this.isLoading = false;
            await this.CheckoutStore.retrieveCatalogueImage();
            this.CartStore.hideCartItem();
        },

        showSpinner(loadingText, useOverlay = false) {
            this.loadingSpinner.show(loadingText, useOverlay);
        },

        hideSpinner() {
            this.loadingSpinner.hide();
        },

        changeFormSize(formSize) {
            this.formStep = formSize;
        }
    },

    created() {
        this.fetchData();
    }
}
</script>
<template>
    <div class="uk-inline">
        <a href="/user/login">
            <i class="fal fa-user"></i>
        </a>
    </div>
</template>

<script>
import {useUserStore} from "../../store/UserStore"

export default {
    name: "MenuIcon",
    created() {
        this.userStore.fetchUserData()
    },
    setup() {
        const userStore = useUserStore()
        return {
            userStore
        }
    }
}
</script>
<template>
    <div>
        <h3>{{ $t('text.checkout.sub-headline.find-service-point')}}</h3>
        <div>
            <FloatingLabelInput
                :label="$t('text.checkout.input.zip')"
                v-model="zip"
                type="input"
                @input="updateLocationList"
            />
        </div>
        <div v-if="this.CheckoutStore.lockerLocationList.length > 0">
            <div
                class="ws-selector ws-delivery-service-selector uk-margin-small-top uk-margin-small-bottom"
                :class="{'selected': this.CheckoutStore.selectedLockerId === item.id}"
                v-for="item in this.CheckoutStore.lockerLocationList" :key="item.id"
                @click.prevent="selectLocation(item.id)"
            >
                <div class="uk-padding-small">
                    <div class="uk-text-bold">{{ item.name }}<span v-if="(item.containedInPlace != '')"> | {{ item.containedInPlace }}</span> </div>
                    <div :data-id="item.id">{{ item.streetReal }}, {{ item.zip }} {{ item.city }}</div>
                </div>
            </div>
        </div>
        <div v-else>
            {{ $t('text.checkout.delivery.no-service-points-found') }}
        </div>

    </div>
</template>

<script>
import {useCheckoutStore} from "../../../../store/CheckoutStore.js";
import FloatingLabelInput from "../../../form/base/FloatingLabelInput.vue";

export default {
    name: "DhlServicePointSelect",
    components: {FloatingLabelInput},
    data() {
        return {
            zip: 0
        }
    },
    setup() {

        const CheckoutStore = useCheckoutStore()

        return {
            CheckoutStore
        }

    },
    methods: {
        updateLocationList() {
            this.CheckoutStore.getDhlLocationList(
                {
                    countryCode: this.CheckoutStore.checkoutData.steps.delivery.data.country,
                    zip: this.zip
                }
            )
        },
        selectLocation(id) {

            const lockerData = this.CheckoutStore.getDhlLocationDataById(id)

            if (
                lockerData.hasOwnProperty('streetLabel') && lockerData.streetLabel !== ''
                && lockerData.hasOwnProperty('streetNumber') && lockerData.streetNumber !== ''
                && lockerData.hasOwnProperty('zip') && lockerData.zip !== ''
                && lockerData.hasOwnProperty('city') && lockerData.city !== ''
            ) {
                this.CheckoutStore.checkoutData.steps.delivery.data.street = lockerData.streetLabel
                this.CheckoutStore.checkoutData.steps.delivery.data.streetNumber = lockerData.streetNumber
                this.CheckoutStore.checkoutData.steps.delivery.data.zip = lockerData.zip
                this.CheckoutStore.checkoutData.steps.delivery.data.city = lockerData.city
                // Nur setzen, wenn Daten vorhanden
                this.CheckoutStore.selectedLockerId = id;
                this.CheckoutStore.checkoutData.steps.delivery.data.addressDiffers = "yes"

            }

        },
    },
    mounted() {
        this.zip = this.CheckoutStore.checkoutData.steps.delivery.data.zip
        this.updateLocationList()
    }
}
</script>
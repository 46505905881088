import {defineStore} from "pinia"
import ApiService from "../service/ApiService"
import TrackingService from '../service/TrackingService';

export const useCartStore = defineStore('CartStore', {
    state: () => ({
        cartData: {},
        wishlistData: {},
        lastAddedItemId: null,
        lastAddedItemTimestamp: null,
        showCartItem: true
    }),
    getters: {
        cartItemsCount(state) {
            let totalCount = 0;
            if(state.cartData.Items) {
                for (let key in state.cartData.Items) {
                    totalCount += state.cartData.Items[key].length;
                }
            }
            return totalCount
        },
        wishlistItemsCount(state) {
            if(state.wishlistData.Items) {
                return state.wishlistData.Items.length
            }
            return 0
        },
        cartItems(state) {
            return state.cartData.Items ?? [];
        },
        giftItems(state) {
            return state.cartData.GiftItems ?? [];
        },
        shippingMethods(state) {
            return state.cartData.ShippingMethods ?? []
        },
        totalPrice(state) {
            return state.cartData.TotalPrice ?? 0
        },
        currency(state) {
            return state.cartData.Currency ?? ""
        },
        getLastAddedItem(state) {
            if (state.cartData.Items) {
                for (let status of Object.keys(state.cartData.Items)) {
                    for (let item of state.cartData.Items[status]) {
                        if (item.Id === parseInt(state.lastAddedItemId)) {
                            return item;
                        }
                    }
                }
            }
        },
        getCountryList(state) {
            return state.cartData.countryList?? []
        },
        getSiteLocale(state) {
            return state.cartData.siteLocale?? ""
        },
        getDeliveryCountryCode(state) {
            return state.cartData.deliveryCountryCode?? ""
        },
        getavailableGiftItems(state) {
            return state.cartData.availableGiftItems?? []
        }
    },
    actions: {
        setPartialDelivery(partialDeliverySelected)
        {
            return ApiService.setPartialDelivery(partialDeliverySelected).then((response) => {
                this.fetchCartData();
            })
        },
        setLastAddedItemTimestamp() {
            this.lastAddedItemTimestamp = Date.now()
        },
        fetchCartData(payload, type = null) {

            return ApiService.getCartData(payload, type).then(
                response => {
                    if (type === ApiService.cartTypeWishlist) {
                        this.wishlistData = response.data
                    } else {
                        this.cartData = response.data
                    }
                    return response

                }
            )

        },
        async removeGiftItemFromCart(payload) {
            try {
                const giftItemResponse = await ApiService.deleteGiftItem(payload)
                if (giftItemResponse.status !== 200) {
                    return false
                }

                await this.fetchCartData()
                return true
            } catch (e) {
                return false;
            }
        },
        async addGiftItemToCart(payload) {
            try {
                const giftItemResponse = await ApiService.addGiftItem({
                    pid: payload
                })

                if (giftItemResponse.status !== 200) {
                    return false
                }

                await this.fetchCartData()
                return true
            } catch (e) {
                return false
            }
        },
        addCartItem(payload) {
            return ApiService.addCartItem(payload.pid, payload.cartItemsCount, payload.type, payload.isConfiguratorProduct).then(
                (response) => {

                    TrackingService.track(response.data, 'addCart');

                    this.fetchCartData(null, payload.type).then(() => {

                        if (payload.type !== ApiService.cartTypeDefault) {
                            return
                        }

                        this.lastAddedItemId = payload.pid
                        this.lastAddedItemTimestamp = Date.now()
                        return response

                    }).catch(function (error) {
                        throw error
                    })
                }
            );
        },

        updateCartItem(payload) {
            return ApiService.updateCartItem(payload.pid, payload.cartItemsCount, payload.type).then((response) => {
                this.fetchCartData(null, payload.type).then(() => {
                    return response
                }).catch(function (error) {
                    throw error
                })
            });
        },

        removeCartItem(payload) {
            const returnPromise = ApiService.removeCartItem(payload.pid, payload.type)

            returnPromise.then((response) => {

                TrackingService.track(response.data, 'removeCart');

                this.fetchCartData(null, payload.type).catch(function (error) {
                    throw error
                })
            });

            return returnPromise
        },

        hideCartItem() {
            this.showCartItem = false
        },

        updateShipment(payload) {
            return ApiService.updateCartShipment(payload.pid);
        }

    }
})
<template>
    <a @click.prevent="openCancel()" class="uk-link uk-text-secondary" v-if="order.Cancelable">
        <i class="fa-regular fa-ban uk-margin-small-right"></i>{{ $t('text.user.profile.cancel.cancel_order') }}
    </a>
    <div class="uk-flex-top" uk-modal :id="'cancelOrder'+this.order.OrderId">
        <div class="uk-modal-dialog uk-margin-auto-vertical uk-modal-body">
            <div class="uk-modal-title uk-modal-body">
                <h2>
                    {{ $t('text.user.profile.cancel.cancel_order_header') }}
                </h2>
            </div>
            <div class="uk-modal-body">
                {{ $t('text.user.profile.cancel-order-confirmation-text') }}
            </div>
            <div class="uk-modal-footer uk-child-width-1-2" uk-grid>
                <div class="uk-margin-small-top">
                    <button class="uk-modal-close uk-button uk-button-default uk-button-small" @click.prevent="closeCancel()"
                            type="button">
                        {{ $t('text.user.profile.cancel.cancel_close_modal') }}
                    </button>
                </div>
                <div class="uk-margin-small-top uk-text-right">
                    <button class="uk-modal-close uk-button uk-button-default uk-button-small" @click.prevent="cancelOrder()"
                            type="button">
                        {{ $t('text.user.profile.cancel.cancel_order') }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ApiService from "../../../../service/ApiService";
import { modal, notification } from 'uikit';

export default {
    name: 'Cancel',
    props: ['order'],
    methods: {
        showNotification(message, status = "success") {
            const timeout = 5000
            notification({
                message: this.$t(message),
                status: status,
                pos: 'top-center',
                timeout: timeout
            })
            setTimeout(() => this.privacyError = false, timeout)
        },
        openCancel() {
            modal('#cancelOrder'+this.order.OrderId).show();
        },
        closeCancel() {
            modal('#cancelOrder'+this.order.OrderId).hide();
        },
        async cancelOrder() {
            try {
                await ApiService.cancelOrder({ 'orderId': this.order.OrderId })
                await this.closeCancel()
                this.showNotification('text.user.profile.cancel.cancel_order_successful')
            } catch (error) {
                await this.closeCancel()
                this.showNotification('text.user.profile.cancel.cancel_order_error', 'danger')
            }
        }
    }
}
</script>
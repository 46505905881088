<template>
    <div class="uk-margin-medium-bottom" :class="$attrs.class">
        <div class="ws-input-floating-label uk-padding-small uk-padding-remove-vertical" :class="{'error': !!errorMessage}">
            <input
                :type="this.inputType"
                :placeholder="placeholder ? placeholder : label"
                class="uk-input uk-margin-small-top uk-margin-small-bottom"
                :class="inputClass"
                :value="modelValue"
                @input="$emit('update:modelValue', $event.target.value)"
                :id="uuid"
                :aria-describedby="errorMessage ? uuid + '-error' : null"
                :aria-invalid="errorMessage ? true : null"
                :readonly="readOnly"
            >
            <label
                :for="uuid"
                class="uk-padding-small uk-padding-remove-vertical"
                v-if="label"
            >
                {{label}}<span v-if="required"><sup>*</sup></span>
            </label>
            <a class="ws-input-toggle-icon" v-html="getToggleIcon()" @click.prevent="this.togglePasswordShow()" href="#" :title="$t('text.password-show-toggle')"></a>
        </div>
        <p
            class="ws-checkout-error-field uk-text-danger"
            v-if="errorMessage"
            :id="uuid + '-error'"
            aria-live="assertive"
        >
            {{ $t(errorMessage) }}
        </p>
        <p
            class="uk-text-small uk-padding-small uk-padding-remove-horizontal uk-padding-remove-top"
            v-if="hint"
            v-html="hint"
        >
        </p>
    </div>
</template>

<script>
import UniqueID from "../../../includes/UniqueID.js";

export default {
    name: 'FloatingLabelPassword',
    props: {
        label: {
            type: String,
            default: ''
        },
        placeholder: {
            type: String,
            default: ''
        },
        modelValue: {
            type: [String, Number],
            default: ''
        },
        required: {
            type: Boolean,
            default: true
        },
        hint: {
            type: String,
            default: ''
        },
        errorMessage: {
            type: String,
            default: ''
        },
        inputClass: {
            type: String,
            default: ''
        },
        readOnly: {
            type: Boolean,
            default: false
        },
        toggleIcon: {
            type: Object,
            default(raw) {

                if (raw.hasOwnProperty('on') && raw.hasOwnProperty('off')) {
                    return {
                        on: raw.on,
                        off: raw.off,
                    }
                }

                return {
                    on: '<i class="fa-light fa-eye-slash"></i>',
                    off: '<i class="fa-light fa-eye"></i>',
                }

            }
        },
        inputTypeDefault: {
            type: String,
            default: 'password'
        },
        inputTypeToggled: {
            type: String,
            default: 'text'
        }
    },
    data() {
        return {
            toggleShowState: false,
            inputType: this.inputTypeDefault
        }
    },
    setup(props) {
        const uuid = UniqueID().getID()

        return {
            uuid
        }
    },
    methods: {
        togglePasswordShow() {
            if (this.toggleShowState) {
                this.toggleShowState = false
                this.inputType = this.inputTypeDefault
            } else {
                this.toggleShowState = true
                this.inputType = this.inputTypeToggled

            }
        },
        getToggleIcon() {
            return this.toggleShowState ? this.toggleIcon.on : this.toggleIcon.off
        }
    }
};
</script>

<template>

    <div v-if="stepIsActive || stepIsFilled">
        <PaymentOption
            v-for="payment in PaymentStore.paymentTypes" :key="payment.id"
            :step-is-active="stepIsActive"
            :step-is-filled="stepIsFilled"
            :payment-method-name="payment.name"
            :payment-method-id="payment.paymentId"
            :icon-html="payment.iconHtml"
        >

        </PaymentOption>

        <div class="uk-background-muted uk-padding-small" v-if="stepIsActive || (stepIsFilled && CheckoutStore.checkoutData.steps.payment.data.voucher)">
            <h3>{{ $t('text.checkout.sub-headline.voucher') }}</h3>
            <FloatingLabelInput
                v-model="CheckoutStore.checkoutData.steps.payment.data.voucher"
                :error-message="retrieveError('voucher')"
                type="input"
                v-if="stepIsActive"
                :input-class="'uk-background-muted'"
                :placeholder="$t('text.checkout.sub-headline.voucher')"
                class="uk-margin-remove-bottom"
            />
            <div v-else-if="CheckoutStore.checkoutData.steps.payment.data.voucher">
                {{ CheckoutStore.checkoutData.steps.payment.data.voucher }}
            </div>
        </div>

        <div
            class="ws-checkout-error-general uk-text-danger"
            v-if="CheckoutStore.checkoutData.error.general && (CheckoutStore.checkoutData.current === this.stepName)"
        >
            {{ CheckoutStore.checkoutData.error.general }}
        </div>

        <BaseButton
            :full-width="true"
            :href="'#ws-checkout-top'"
            v-if="stepIsActive"
            @click="sendForm()"
            class="uk-margin-medium-top"
            id="commitPayment"
            uk-scroll
        >
            {{ $t('text.checkout.button.go-to-confirmation') }}
        </BaseButton>

    </div>

    <hr class="uk-margin-medium-top uk-margin-medium-bottom">

</template>

<script>

import BaseButton from "../../form/base/BaseButton.vue";
import FloatingLabelInput from "../../form/base/FloatingLabelInput.vue";
import BaseSelect from "../../form/base/BaseSelect.vue";
import PaymentOption from "./payment/PaymentOption.vue";
import {useCheckoutStore} from "../../../store/CheckoutStore.js"
import {usePaymentStore} from "../../../store/PaymentStore";
import TrackingService, {TrackingCheckoutStepCode} from "../../../service/TrackingService";

export default {
    name: "Payment",
    components: {
        PaymentOption,
        BaseButton,
        BaseSelect,
        FloatingLabelInput
    },
    props: {
        stepIsActive: {
            type: Boolean,
            required: true,
        },
        stepIsFilled: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        sendForm() {
            this.$parent.showSpinner(this.$t('text.loading-spinner.save-checkout-data.payment'), true)

            TrackingService.trackCheckoutByStep(TrackingCheckoutStepCode.checkOrder)

            this.CheckoutStore.getToken(this.stepName).then(

                (result) => {

                    if (result.hasOwnProperty('data') && result.data.hasOwnProperty('token')) {
                        this.CheckoutStore.sendCheckoutData({ step: this.stepName, token: result.data.token }).then(() => {
                            this.$parent.hideSpinner()
                        })
                    }

                }

            ).then(() => {
                this.$parent.hideSpinner()
            })

        },
        retrieveError(field) {

            if (this.CheckoutStore.checkoutData.error.payment && this.CheckoutStore.checkoutData.error.payment[field]) {
                return this.CheckoutStore.checkoutData.error.payment[field]
            }

            return '';
        },
    },
    setup() {

        const CheckoutStore = useCheckoutStore()
        const PaymentStore = usePaymentStore()
        const stepName = 'payment'

        return {
            CheckoutStore,
            PaymentStore,
            stepName
        }

    },
    mounted() {
        this.PaymentStore.fetchPaymentTypes()
    }
}
</script>

<template>
    <div>
        <BreadCrumb
            :tabs="[{id: 'component-grid', header: 'Kundenkonto'}, componentItems.find(item => item.id === currentTab)]"
            @selectTab="(id) => userProfileStore.setCurrentTab(id)"
        />
        <VatStatus />
        <ComponentGrid v-if="isActive('component-grid')" :components="componentItems" :user-store="userStore" :user-profile-store="userProfileStore" />
        <PersonalData v-if="isActive('personal-data')" />
        <AddressData v-if="isActive('address-data')" />
        <!--Payment v-if="isActive('payment')" /-->
        <OrderList v-if="isActive('order-list')" />
        <AccountBalance v-if="isActive('account-balance')"/>
        <RevocationForm v-if="isActive('revocation-form')" />
        <Wishlist v-if="isActive('wishlist')" />
        <Newsletter v-if="isActive('newsletter')" />
    </div>
</template>

<script>
import {useUserStore} from "../../store/UserStore"
import {useUserProfileStore} from "../../store/UserProfileStore"
import Dashboard from "./UserProfile/Dashboard.vue";
import PersonalData from "./UserProfile/PersonalData.vue";
import Payment from "./UserProfile/Payment.vue";
import OrderList from "./UserProfile/OrderList.vue";
import Wishlist from "./UserProfile/Wishlist.vue";
import AddressData from "./UserProfile/AddressData.vue";
import RevocationForm from "./UserProfile/RevocationForm.vue";
import Newsletter from "./UserProfile/Newsletter.vue";
import AccountBalance from "./UserProfile/AccountBalance.vue";
import ComponentGrid from "./UserProfile/ComponentGrid.vue";
import BreadCrumb from "./UserProfile/BreadCrumb.vue";
import VatStatus from "../notifications/VatStatus.vue";

export default {
    name: 'UserprofileApp',
    components: {
        BreadCrumb,
        ComponentGrid,
        VatStatus,
        RevocationForm,
        Newsletter,
        AddressData,
        OrderList,
        Payment,
        PersonalData,
        Dashboard,
        Wishlist,
        AccountBalance
    },
    data() {
        return {
            componentItems: [
                {
                    component: 'OrderList',
                    id: 'order-list',
                    icon: 'fa-clock-rotate-left',
                    header: this.$t('text.user.profile.order-list.headline')
                },
                {
                    component: 'PersonalData',
                    id: 'personal-data',
                    icon: 'fa-user-gear',
                    header: this.$t('text.user.profile.personal-data.headline')
                },
                {
                    component: 'AddressData',
                    id: 'address-data',
                    icon: 'fa-address-book',
                    header: this.$t('text.user.profile.order-list.headline')
                },
                {
                    component: 'Wishlist',
                    id: 'wishlist',
                    icon: 'fa-bookmark',
                    header: this.$t('text.user.profile.wishlist.headline')
                },
                {
                    component: 'Newsletter',
                    id: 'newsletter',
                    icon: 'fa-envelope',
                    header: this.$t('text.user.profile.newsletter.headline')
                },
                {
                    component: 'RevocationForm',
                    id:'revocation-form',
                    icon: 'fa-file-contract',
                    header: this.$t('text.user.profile.revocation.headline')
                },
                {
                    component: 'AccountBalance',
                    id: 'account-balance',
                    icon: 'fa-chart-mixed-up-circle-dollar',
                    header: this.$t('text.user.profile.account-balance.headline')
                },
                //{ component: 'Dashboard', id: 'dashboard' },
                /*{ component: 'Payment', id: 'payment' },*/
            ],
            userStore: useUserStore(),
            userProfileStore: useUserProfileStore()
        }
    },
    watch: {
        '$route'(to, from) {
            switch (to.path) {
                case '/user/profile/wishlist':
                    this.userProfileStore.currentTab = 'wishlist'
                    break;
                default:

            }
        },
        currentTab(to, from) {
            if (to !== from) {
                this.scrollToTop()
            }
        }
    },
    computed: {
        currentTab() {
            return this.userProfileStore.currentTab
        }
    },
    beforeMount() {
        this.scrollToTop()
    },
    created() {
        const section = this.getLastUrlSection()?? 'component-grid'
        if (section) {
            this.userProfileStore.setCurrentTab(section)
        }
        this.userStore.fetchUserData()
    },
    methods: {
        scrollToTop() {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        },
        isActive(componentId) {
            return this.userProfileStore.currentTab === componentId
        },
        getLastUrlSection() {
            const currentPath = window.location.pathname;
            const basePath = '/user/profile';

            if (currentPath.startsWith(basePath) && currentPath.length > basePath.length) {
                const pathSections = currentPath.split('/');
                return pathSections[pathSections.length - 1];
            }

            return null;
        }
    },
};
</script>

<template>
    <div class="last-order uk-margin-top">
        <div class="uk-grid-medium" uk-grid>
            <div class="uk-width-1-1 uk-width-expand@s">
                <h2>{{ $t('text.user.profile.your-last-order') }}</h2>
            </div>
            <div class="uk-width-1-1 uk-width-auto@s">
                <a href="#" @click.prevent="switchToOrderList()">{{ $t('text.user.profile.show-all-orders') }}</a>
            </div>
        </div>
        <hr />
        <div v-if="userProfileStore.getOrdersCount" uk-grid class="uk-child-width-1-1 uk-child-width-1-4@s">
            <div
                v-for="order in getOrderList()"
                :key="order.id"
            >
                <div class="uk-card uk-card-body uk-text-center">
                    <p>{{ order.OrderDate }}</p>
                    <p>{{ $t(order.OrderStateText) }}</p>
                    <div v-if="order.Items[0].Thumbnail" class="uk-margin-small-bottom uk-position-relative">
                        <span class="uk-badge uk-position-top-right uk-margin-large-right" v-if="order.Items.length > 1">+{{ order.Items.length-1 }}</span>
                        <div class="last-order__product-image" v-html="order.Items[0].Thumbnail"></div>
                    </div>
                    <BaseButton
                        :href="'#'"
                        :fullWidth="true"
                        @click.prevent="this.userProfileStore.setCurrentTab('order-list'); this.userProfileStore.setCurrentOrderId(order.OrderId)" >
                        {{ $t('text.user.profile.view-order') }}
                    </BaseButton>
                </div>

            </div>
        </div>
        <div v-else>
            <hr class="uk-hr">
            <p class="uk-margin-large-top uk-margin-large-bottom">{{ $t('text.user.profile.no-orders-conducted-yet') }}</p>
        </div>
    </div>
</template>

<script>

import {useUserStore} from "../../../../store/UserStore";
import {useUserProfileStore} from "../../../../store/UserProfileStore";
import Order from "../order/Order.vue";
import {notification} from "uikit";
import BaseButton from "../../../form/base/BaseButton.vue";

export default {
    name: 'LastOrder',
    components: {BaseButton, Order},
    setup() {

        const userStore = useUserStore()
        const userProfileStore = useUserProfileStore()

        return {
            userStore,
            userProfileStore
        }
    },
    beforeMount() {
        this.userProfileStore.loadOrderList({limit: 4})
    },
    methods: {
        showNotification(message, status = "success") {
            const timeout = 5000
            notification({
                message: this.$t(message),
                status: status,
                pos: 'top-center',
                timeout: timeout
            })
            setTimeout(() => this.privacyError = false, timeout)
        },
        switchToOrderList() {
            if (this.userProfileStore.getOrdersCount) {
                this.userProfileStore.setCurrentTab('order-list', true)
            } else {
                this.showNotification('text.user.profile.no-orders-conducted-yet', 'warning')
            }
        },
        getOrderList() {
            if(this.userProfileStore.orderList.items) {
                return Object.values(this.userProfileStore.orderList.items).reverse()
            }

            return []
        }
    }
};
</script>

<template>
    <div>
        <form @submit="sendForm">
            <BaseRadio
                v-model="userStore.userRegisterData.customerType"
                :options="userStore.userRegisterOptions.customerType"
                :error-message="errors.customerType"
                class="uk-margin-medium-bottom uk-margin-remove-top"
                @change="customerTypeChange"
            />
            <BaseRadio
                v-model="userStore.userRegisterData.gender"
                :options="userStore.userRegisterOptions.gender"
                :error-message="errors.gender"
                class="uk-margin-medium-bottom uk-margin-remove-top"
                @change="genderChange"
            />
            <FloatingLabelInput
                :label="$t('text.firstname')"
                :error-message="errors.firstname"
                v-model="userStore.userRegisterData.firstname"
                @change="firstnameChange"
                type="input"
                :class="['uk-margin-medium-top']"
            />
            <FloatingLabelInput
                :label="$t('text.lastname')"
                :error-message="errors.lastname"
                v-model="userStore.userRegisterData.lastname"
                @change="lastnameChange"
                type="input"
                :class="['uk-margin-medium-top']"
            />
            <FloatingLabelInput
                :label="$t('text.e-mail')"
                :error-message="errors.email"
                v-model="userStore.userRegisterData.email"
                @change="emailChange"
                type="email"
                :class="['uk-margin-medium-top']"
            />
            <FloatingLabelInput
                :label="$t('text.e-mail-confirm')"
                :error-message="errors.emailConfirm"
                v-model="userStore.userRegisterData.emailConfirm"
                @change="emailConfirmChange"
                type="email"
                :class="['uk-margin-medium-top']"
            />
            <FloatingLabelPassword
                :label="$t('text.password')"
                :error-message="errors.password"
                v-model="userStore.userRegisterData.password"
                @change="passwordChange"
                :class="['uk-margin-medium-top']"
            />
            <FloatingLabelPassword
                :label="$t('text.password-confirm')"
                :error-message="errors.passwordConfirm"
                v-model="userStore.userRegisterData.passwordConfirm"
                @change="passwordConfirmChange"
                :class="['uk-margin-medium-top']"
            />
            <FloatingLabelInput
                :label="$t('text.phone')"
                :error-message="errors.phone"
                v-model="userStore.userRegisterData.phone"
                @change="phoneChange"
                type="phone"
                :class="['uk-margin-medium-top']"
            />

            <div v-if="userStore.userRegisterData.customerType === 'Geschäftskunde'">

                <h3 class="uk-margin-small-bottom">{{ $t('text.checkout.sub-headline.company') }}</h3>

                <FloatingLabelInput
                    :label="$t('text.checkout.input.company')"
                    :error-message="errors.company"
                    v-model="userStore.userRegisterData.company"
                    @change="companyChange"
                    type="input"
                />

                <FloatingLabelInput
                    :label="$t('text.checkout.input.department')"
                    :error-message="errors.department"
                    v-model="userStore.userRegisterData.department"
                    @change="departmentChange"
                    type="input"
                    :required="false"
                />

                <FloatingLabelInput
                    :label="$t('text.checkout.input.vat-id')"
                    :error-message="errors.vatId"
                    v-model="userStore.userRegisterData.vatId"
                    @change="vatIdChange"
                    type="input"
                    :required="false"
                />

            </div>

            <BaseSelect
                :label="$t('text.checkout.select.country')"
                :error-message="errors.country"
                v-model="userStore.userRegisterData.country"
                :options="userStore.userRegisterOptions.country"
                @change="countryChange"
            />

            <div uk-grid class="uk-margin-remove-top">

                <FloatingLabelInput
                    :label="$t('text.street')"
                    :error-message="errors.street"
                    :custom-element-id="this.googleAutocompleteInput"
                    @vue:mounted="initGoogleAutocompleteService"
                    v-model="userStore.userRegisterData.street"
                    @change="streetChange"
                    type="input"
                    :class="['uk-width-1-1 uk-width-4-5@m']"
                />

                <FloatingLabelInput
                    :label="$t('text.streetNumber')"
                    :error-message="errors.streetNumber"
                    v-model="userStore.userRegisterData.streetNumber"
                    @change="streetNumberChange"
                    type="input"
                    :class="['uk-width-1-1 uk-width-1-5@m uk-margin-remove-top']"
                />

            </div>

            <div uk-grid class="uk-margin-remove-top">

                <FloatingLabelInput
                    :label="$t('text.zip')"
                    :error-message="errors.zip"
                    v-model="userStore.userRegisterData.zip"
                    @change="zipChange"
                    type="input"
                    :class="['uk-width-1-1 uk-width-1-4@m']"
                />

                <FloatingLabelInput
                    :label="$t('text.city')"
                    :error-message="errors.city"
                    v-model="userStore.userRegisterData.city"
                    @change="cityChange"
                    type="input"
                    :class="['uk-width-1-1 uk-width-3-4@m uk-margin-remove-top']"
                />

            </div>

            <button
                class="uk-button uk-button-default uk-width-1-1 uk-margin-medium-top"
                type="submit"
                :disabled="isLoading"
            >
                <span v-if="isLoading" uk-spinner="ratio:1"></span>
                <span v-else>{{ $t("text.user_save_registration_data") }}</span>
            </button>
            <p class="uk-text-warning" v-if="showErrorMessage" v-html="userStore.loginErrorMessage"></p>
        </form>
    </div>
</template>
<script>
import BaseRadio from "../form/base/BaseRadio.vue"
import BaseSelect from "../form/base/BaseSelect.vue"
import FloatingLabelInput from "../form/base/FloatingLabelInput.vue";
import FloatingLabelPassword from "../form/base/FloatingLabelPassword.vue";
import {useField, useForm} from "vee-validate"
import {useI18n} from "vue-i18n"
import {object, string, email, required} from "yup"
import {useUserStore} from "../../store/UserStore.js"
import {ref} from "vue";
import googleAutocompleteMixin from "../../mixins/googleAutocompleteMixin";

export default {
    name: "UserRegistrationForm",
    mixins: [googleAutocompleteMixin],
    components: {
        BaseRadio,
        BaseSelect,
        FloatingLabelInput,
        FloatingLabelPassword
    },
    setup() {

        const {t} = useI18n({})
        const userStore = useUserStore()
        const isLoading = ref(false)

        const validationSchema = object({
            customerType: string(),
            gender: string(),
            csrfTokenField: string(),
            firstname: string().required(t("text.customer.registration.error.mandatory-field-empty")),
            lastname: string().required(t("text.customer.registration.error.mandatory-field-empty")),
            phone: string().required(t("text.customer.registration.error.mandatory-field-empty")),
            zip: string().required(t("text.customer.registration.error.mandatory-field-empty")),
            city: string().required(t("text.customer.registration.error.mandatory-field-empty")),
            street: string().required(t("text.customer.registration.error.mandatory-field-empty")),
            streetNumber: string().required(t("text.customer.registration.error.mandatory-field-empty")),
            country: string().required(t("text.customer.registration.error.mandatory-field-empty")),
            company: string(),
            department: string(),
            vatId: string(),
            email: string()
                .required(t("text.customer.registration.error.mandatory-field-empty"))
                .email(t("text.customer.registration.error.invalid-email-address")),
            emailConfirm: string()
                .required(t("text.customer.registration.error.mandatory-field-empty"))
                .email(t("text.customer.registration.error.invalid-email-address")),
            password: string()
                .required(t("text.customer.registration.error.mandatory-field-empty"))
                .min(8, t("text.customer.registration.error.password-invalid")),
            passwordConfirm: string()
                .required(t("text.customer.registration.error.mandatory-field-empty"))
                .min(8, t("text.customer.registration.error.password-invalid"))
        })
        const {handleSubmit, errors} = useForm({
            validationSchema
        })
        const {value: customerType, handleChange: customerTypeChange} = useField('customerType')
        const {value: gender, handleChange: genderChange} = useField('gender')
        const {value: firstname, handleChange: firstnameChange} = useField('firstname')
        const {value: lastname, handleChange: lastnameChange} = useField('lastname')
        const {value: email, handleChange: emailChange} = useField('email')
        const {value: emailConfirm, handleChange: emailConfirmChange} = useField('emailConfirm')
        const {value: password, handleChange: passwordChange} = useField('password')
        const {value: passwordConfirm, handleChange: passwordConfirmChange} = useField('passwordConfirm')
        const {value: phone, handleChange: phoneChange} = useField('phone')
        const {value: zip, setValue: setZip, handleChange: zipChange} = useField('zip')
        const {value: city, setValue: setCity, handleChange: cityChange} = useField('city')
        const {value: street, setValue: setStreet, handleChange: streetChange} = useField('street')
        const {
            value: streetNumber,
            setValue: setStreetNumber,
            handleChange: streetNumberChange
        } = useField('streetNumber')
        const {value: country, handleChange: countryChange} = useField('country')
        const {value: company, handleChange: companyChange} = useField('company')
        const {value: department, handleChange: departmentChange} = useField('department')
        const {value: vatId, handleChange: vatIdChange} = useField('vatId')
        const {value: csrfTokenField} = useField('csrfToken')

        const sendForm = handleSubmit(values => {
            isLoading.value = true
            userStore.registerUser(values)
                .then((response) => {
                        if (response.data && !response.data.success) {
                            userStore.loginErrorMessage = response.data.errorString
                            isLoading.value = false
                            return
                        }
                        if (response.data.redirect_route) {
                            window.location.href = response.data.redirect_route
                        }
                    }
                ).catch((error) => {
                isLoading.value = false
            });
        })

        userStore.fetchRegisterData()

        return {
            lastname,
            email,
            password,
            sendForm,
            errors,
            setZip,
            setCity,
            setStreet,
            setStreetNumber,
            customerTypeChange,
            genderChange,
            firstnameChange,
            lastnameChange,
            emailChange,
            emailConfirmChange,
            passwordChange,
            passwordConfirmChange,
            phoneChange,
            zipChange,
            cityChange,
            streetChange,
            streetNumberChange,
            countryChange,
            companyChange,
            departmentChange,
            vatIdChange,
            customerType,
            gender,
            userStore,
            csrfTokenField,
            isLoading
        }
    },
    data() {
        return {
            errorMessageEmail: '',
            errorMessagePassword: '',
            errorMessage: '',
            googleAutocompleteInput: 'autocomplete-input-register',
        }
    },
    mounted() {
        this.csrfTokenField = this.csrfToken;
    },
    computed: {
        showErrorMessage() {
            return (!this.isLoading && this.userStore.loginErrorMessage !== '');
        },
        googleAutocompleteSelectedCountryCode() {
            return this.userStore.userRegisterData.country;
        },
    },
    watch: {
        googleAutocompleteSelectedCountryCode(countryCode) {
            if (this.googelAutocompleteAvailable && countryCode) {
                this.googleAutocompleteChangeCountryCode(countryCode);
            }
        }
    },
    methods: {
        googleAutocompleteClearAddressForm() {
            this.userStore.userRegisterData.street = '';
            this.userStore.userRegisterData.streetNumber = '';
            this.userStore.userRegisterData.city = '';
            this.userStore.userRegisterData.zip = '';

            this.setStreet('');
            this.setStreetNumber('');
            this.setCity('');
            this.setZip('')
        },
        googleAutocompleteUpdateAddress() {
            this.userStore.userRegisterData.street = this.googleAutocompleteAddress.street;
            this.userStore.userRegisterData.streetNumber = this.googleAutocompleteAddress.streetNumber;
            this.userStore.userRegisterData.city = this.googleAutocompleteAddress.city;
            this.userStore.userRegisterData.zip = this.googleAutocompleteAddress.zip;

            this.setStreet(this.googleAutocompleteAddress.street ?? '');
            this.setStreetNumber(this.googleAutocompleteAddress.streetNumber ?? '');
            this.setCity(this.googleAutocompleteAddress.city ?? '');
            this.setZip(this.googleAutocompleteAddress.zip ?? '')
        },
    }
}
</script>
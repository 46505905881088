<template>
    <div>
        <h4 class="uk-text-small@s">{{ giftHeadline }}</h4>
        <p>{{ giftDescription }}</p>
        <div
            class="uk-grid-small uk-grid-match uk-child-width-1-2"
            uk-grid
        >
            <template
                v-for="item in items"
            >
                <div>
                    <div class="uk-card uk-card uk-card-muted uk-border-solid uk-border-muted">
                        <div class="uk-card-header uk-padding-small uk-padding@s uk-padding-remove-bottom">
                            <h3 class="uk-text-meta uk-text-primary uk-position-relative uk-padding-small uk-padding-remove-right" v-if="!item.giftLeft">
                                <i class="fa-light fa-badge-check uk-float-right fa-xl uk-position-center-right uk-margin-small-right"></i>
                                {{ $t('text.availablegiftitems.available') }}
                            </h3>
                            <h3 class="uk-text-meta uk-padding-small uk-padding-remove-right" v-else>{{ $t('text.availablegiftitems.header', { val: item.giftCondition }) }}</h3>
                        </div>
                        <div class="uk-card-media-top uk-padding-small uk-padding@s uk-padding-remove-bottom" v-html="item.MainImageHtml">

                        </div>
                        <div class="uk-card-body uk-padding-small uk-padding@s uk-padding-remove-bottom">
                            <h3 class="uk-card-title uk-margin-small-top">
                                {{ item.Bezeichnung }}
                            </h3>
                            <p>{{ item.Artikelnummer }}</p>
                            <p class="uk-margin-large-bottom">&nbsp;</p>
                        </div>
                        <div class="uk-card-footer uk-padding-small uk-padding@s uk-position-bottom uk-text-small">
                            <BaseButton
                                v-if="!item.giftLeft"
                                :class="'uk-width-1-1'"
                                :href="'#'"
                                :disabled="loading"
                                :loading="loading"
                                @click.prevent="addGiftItem(item)"
                            >{{ $t('text.availablegiftitems.select_gift') }}</BaseButton>
                            <a v-else class="uk-button uk-button-muted uk-width-1-1" disabled>
                                <span class="uk-padding-remove-horizontal">{{ $t('text.availablegiftitems.still') }} {{ item.giftLeft }}</span>
                            </a>
                        </div>
                    </div>
                </div>
            </template>
        </div>

    </div>
</template>

<script>
import BaseButton from "../form/base/BaseButton.vue";
import {useCartStore} from "../../store/CartStore";

export default {
    name: "SelectableGiftItems",
    components: {
        BaseButton
    },
    data() {
        return {
            cartStore: useCartStore(),
            loading: false
        }
    },
    props: {
        items: {
            type: Array,
            required: true
        }
    },
    methods: {
        async addGiftItem(item) {
            if (!item.giftLeft) {
                this.loading = true;
                const response = await this.cartStore.addGiftItemToCart(item.Id);
                this.loading = false
            }
        }
    },
    computed: {
        giftHeadline() {
            return this.items.find(item => item.giftHeadline.length > 0)?.giftHeadline
        },
        giftDescription() {
            return this.items.find(item => item.giftDescription.length > 0)?.giftDescription
        }
    }
}
</script>
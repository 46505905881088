<template>
    <div class="user-app-newsletter">
        <div class="uk-width-1-1">
            <h1>{{ $t('text.user.profile.newsletter.headline') }}</h1>
        </div>
        <div v-if="!userStore.newsletter.newsletterSubscribed && !justUnsubscribed"  class="uk-width-1-1">
            <p class="uk-width-1-1">
                {{ $t('text.user.profile.newsletter.text') }}
            </p>
            <div class="uk-margin uk-margin-large-top" uk-margin uk-grid="">
                <div class="uk-width-1-1 uk-width-1-4@m">
                    <BaseButton
                        :href="'#'"
                        @click="subscribeNewsletter"
                        :full-width="true"
                        :loading="loading"
                    >
                        {{ $t('text.user.profile.newsletter.signup') }}
                    </BaseButton>
                </div>
                <div class="uk-width-1-1">
                    <label>
                        <input class="uk-checkbox" type="checkbox" v-model="privacy">
                        <span
                            class="uk-margin-small-left"
                            v-html="$t('text.user.profile.newsletter.privacy', { link: userStore.userData.legal.privacy })"
                        ></span>
                    </label>
                </div>
            </div>
        </div>
        <div v-else-if="justUnsubscribed" class="uk-width-1-1 uk-margin-medium-top">
            <div class="uk-margin" uk-margin>
                <div class="uk-button uk-button-default uk-padding-small">
                    {{ $t('text.user.profile.newsletter.unsubscribe_mistake') }}
                </div>
            </div>
            <h2 class="uk-h2 uk-text-bolder uk-margin-large-top">{{ $t('text.user.profile.newsletter.unsubscriped_newsletter_mistake_header') }}?</h2>
            <a
                class="uk-margin-large-top uk-width-1-1"
                href="#"
                @click="justUnsubscribed = false"
            >
                {{ $t('text.user.profile.newsletter.subscription') }}
            </a>
        </div>
        <div v-else class="uk-width-1-1 uk-margin-large-top">
            <div class="uk-margin" uk-margin>
                <h2 class="uk-h2 uk-text-bolder">{{ $t('text.user.profile.newsletter.subscribed_header') }}</h2>
                <p>{{ userStore.newsletter.contact.Email }}</p>
                <hr class="uk-margin-medium-top">
                <a
                    class="uk-margin-large-top uk-width-1-1"
                    href="#"
                    @click="unsubscribeNewsletter"
                >
                    {{ $t('text.user.profile.newsletter.unsubscribe') }}
                </a>
            </div>
        </div>
    </div>
</template>

<script>
    import BaseButton from "../../form/base/BaseButton.vue";
    import FloatingLabelInput from "../../form/base/FloatingLabelInput.vue";
    import {useUserStore} from "../../../store/UserStore";
    import { notification } from "uikit"
    export default {
        name: "Newsletter",
        components: {FloatingLabelInput, BaseButton},
        data() {
            return {
                privacy: false,
                email: '',
                userStore: useUserStore(),
                privacyError: false,
                justUnsubscribed: false,
                newsletter: null,
                loading: false
            }
        },
        methods: {
            subscribeNewsletter() {
                if (this.privacy) {
                    this.loading = true
                    this.userStore.subscribeNewsletter()
                      .then(() => {
                            this.userStore.fetchNewsletterStatus();
                            this.privacyError = false;
                            this.showNotification('text.user.profile.newsletter.newsletter_subscribed')
                        })
                      .catch(() => {
                            this.privacyError = true;
                            this.showNotification('text.user.profile.newsletter.privacy_not_accepted', "danger")
                        })
                        .finally(() => {
                          this.loading = false
                      })
                } else {
                    this.privacyError = true;
                    this.showNotification('text.user.profile.newsletter.privacy_not_accepted', "danger")
                }
                this.privacy = false
            },
            unsubscribeNewsletter() {
                this.userStore.unsubscribeNewsletter().then((response) => {
                    this.userStore.fetchNewsletterStatus();
                    this.showNotification('text.user.profile.newsletter.newsletter_unsubscribed')
                    this.justUnsubscribed = true
                }).catch(() => {
                    this.showNotification('text.user.profile.newsletter.newsletter_unsubscribed_error', "danger")
                });
            },
            showNotification(message, status = "success") {
                const timeout = 5000
                notification({
                    message: this.$t(message),
                    status: status,
                    pos: 'top-center',
                    timeout: timeout
                })
                setTimeout(() => this.privacyError = false, timeout)
            }
        },
        mounted() {
            if (!this.email) {
                this.email = this.userStore.userData.NewsletterEmail ? this.userStore.userData.NewsletterEmail : this.userStore.userData.Email;
            }
        }
    }
</script>
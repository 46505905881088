<template>
    <div>
        <button class="uk-button uk-button-small uk-button-light uk-text-muted cd-btn js-share-panel-trigger"
                @click="openPanel"
                data-panel="main">
            <i class="fa-light fa-share-nodes uk-margin-small-right"></i>
            <span>
                {{ $t('text.share') }}
            </span>
        </button>
    </div>
    <div class="share-panel share-panel--from-right js-share-panel-main"
         :class="{
            'share-panel--is-visible': panelOpen
        }">
        <header class="share-panel__header">
            <a href="#" class="share-panel__close js-cd-close" @click.prevent="closePanel">Close</a>
        </header>
        <div class="share-panel__container">
            <div class="share-panel__content">
                <div class="share-panel__content-container">
                    <h2>{{ $t('text.product-detail.share-panel.title') }}</h2>
                    <div uk-grid>
                        <div>
                            <a class="uk-button uk-button-primary"
                               :href="mailToLink">
                            <span>
                                <i class="fa-light fa-envelope"></i>
                                {{ $t('text.email') }}
                            </span>
                            </a>
                        </div>
                        <div>
                            <a class="uk-button uk-button-primary whatsapp"
                               :href="whatsAppLink"
                               target="_blank">
                            <span>
                                <i class="fa-brands fa-whatsapp"></i>
                                WhatsApp
                            </span>
                            </a>
                        </div>
                    </div>
                    <br>
                    <div>
                        <p>{{ $t('text.product-detail.share-panel.copy-link-label') }}</p>
                        <div uk-grid>
                            <div class="uk-width-3-4@s">
                                <input type="text" class="uk-input uk-height-1-1" id="input-link-copy-clipboard"
                                       :value="currentUrl">
                            </div>
                            <div class="uk-width-1-4@s">
                                <button class="uk-button uk-button-default" @click="copyToClipBoard">
                                    <span>
                                        {{ $t('text.copy') }}
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            currentUrl: window.location.href,
            panelOpen: false,
        }
    },
    computed: {
        mailToLink() {
            const body = encodeURIComponent(`${this.$t('text.product-detail.share-panel.mail-text')}\n\n${this.currentUrl}`);
            const subject = encodeURIComponent(this.productName);
            return `mailto:?subject=${subject}&body=${body}`;
        },
        whatsAppLink() {
            const text = encodeURIComponent(this.currentUrl);
            return `https://wa.me/?text=${text}`;
        }
    },
    methods: {
        async copyToClipBoard() {
            const copyText = this.currentUrl;

            try {
                await this.executeCopyToClipboard(copyText);
            } catch (error) {
                console.error(error);
            }
        },
        async executeCopyToClipboard(textToCopy) {
            if (navigator.clipboard && window.isSecureContext) {
                await navigator.clipboard.writeText(textToCopy);
            } else {
                const textArea = document.createElement("textarea");
                textArea.value = textToCopy;

                textArea.style.position = "absolute";
                textArea.style.left = "-999999px";

                document.body.prepend(textArea);
                textArea.select();

                try {
                    document.execCommand('copy');
                } catch (error) {
                    console.error(error);
                } finally {
                    textArea.remove();
                }
            }
        },
        closePanel() {
            this.panelOpen = false;
        },
        openPanel() {
            this.panelOpen = true;
        },
    },
}
</script>
<template>
    <div class="uk-grid-medium" uk-grid>
        <div class="uk-width-1-1 uk-width-expand@s">
            <h1 v-if="headline" class="uk-text-primary">{{ headline }}</h1>
            <p v-if="subline" class="uk-margin-top uk-margin-large-bottom">{{ subline }}</p>
        </div>
    </div>
</template>
<script>
export default {
    name: "Header",
    props: {
        headline: String,
        subline: String
    }
}
</script>
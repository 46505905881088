import { createRouter, createWebHistory } from 'vue-router';
import ConfiguratorApp from "../components/configurator/ConfiguratorApp.vue";
import UserprofileApp from "../components/user/UserprofileApp.vue";

const routes = [
    {
        path: '/:path/:configurator/:id',
        component: ConfiguratorApp
    },
    {
        path: '/:path',
        component: UserprofileApp
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;

<template>
    <div class="uk-margin-medium-top">
        <BaseSelect
            :options="cartStore.getCountryList"
            :required="false"
            :modelValue="deliveryCountry"
            @update:modelValue="changeDeliveryCountry"
            :class="['uk-background-muted']"
        />
    </div>
</template>
<script>
import {useCheckoutStore} from "../../store/CheckoutStore";
import {useCartStore} from "../../store/CartStore";
import BaseSelect from "../form/base/BaseSelect.vue";
import ApiService from "../../service/ApiService";

export default {
    name: "ShippingCountryDropdown",
    components: {BaseSelect},
    data() {
        return {
            checkoutStore: useCheckoutStore(),
            cartStore: useCartStore()
        }
    },
    computed: {
          deliveryCountry() {
              return this.cartStore.getDeliveryCountryCode.toUpperCase()
          }
    },
    methods: {
        changeDeliveryCountry(country) {
            ApiService.setDeliveryCountry(country).then((response) => {
                console.log("setDeliveryCountry", response.data)
                if(response.data.success) {
                    this.cartStore.fetchCartData({
                        'deliveryCountry': country
                    })
                }
            })
        }
    }
}
</script>
<template>
    <div class="user-app-dashboard">
        <h1>{{ $t('text.hallo') }} {{ userStore.getFirstName }} {{ userStore.getLastName }}</h1>
        {{ $t('text.user.profile.welcome-text') }}
        <p>{{ $t('text.user.profile.your-customer-number') }}: <span class="uk-text-bold">{{ userStore.getCustomerNumber }}</span>
        </p>
        <div class="uk-margin-large-top"></div>
        <div class="uk-child-width-1-4" uk-grid>
            <div class="uk-card uk-card-body uk-text-center">
                <i class="fa-light fa-2xl fa-clock-rotate-left"></i>
                <p>
                    {{ $t("text.user.profile.navigation.order-list") }}
                </p>
            </div>
            <div class="uk-card uk-card-body uk-text-center">
                <i class="fa-light fa-2xl fa-clock-rotate-left"></i>
                <p>
                    {{ $t("text.user.profile.navigation.order-list") }}
                </p>
            </div>
        </div>
        <LastOrder />
        <PersonalData />
    </div>
</template>

<script>
import {useUserStore} from "../../../store/UserStore"
import {useUserProfileStore} from "../../../store/UserProfileStore"
import LastOrder from "./dashboard/LastOrder.vue"
import PersonalData from "./dashboard/PersonalData.vue"

export default {
    name: 'Dashboard',
    components: {LastOrder, PersonalData},
    setup() {
        const userStore = useUserStore()
        const userProfileStore = useUserProfileStore()

        return {
            userStore,
            userProfileStore
        }
    },
    async beforeMount() {
        await this.userProfileStore.loadOrderList()
    }
};
</script>

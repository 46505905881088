<template>
    <button v-if="isLoading"  class="uk-button uk-button-small uk-button-light uk-text-muted">
        <span>{{ $t('text.question-about-article') }}</span><span class="uk-margin-small-left" uk-spinner="ratio:0.5"></span>
    </button>
    <button v-else @click="showQuestionForm" class="uk-button uk-button-small uk-button-light">
        <span>{{ $t('text.question-about-article') }}</span>
    </button>
    <div :id="'md-article-question-' + this.pid" uk-modal>
        <div class="uk-modal-dialog uk-modal-body">
            <button class="uk-modal-close-default" type="button" uk-close></button>
            <h2>{{ $t('text.question-about-article-header') }}</h2>
            <ContactForm :subject-readonly="true" :show-type-select="false" ref="contactForm"/>
        </div>
    </div>
</template>

<script>
import {modal} from "uikit";
import ContactForm from "../form/ContactForm.vue";
import ApiService from "../../service/ApiService";

export default {
    name: 'QuestionAboutArticle',
    components: {
        ContactForm
    },
    data() {
        return {
            isLoading: false
        }
    },
    methods: {
        showQuestionForm() {
            this.isLoading = true
            ApiService.getArticleData({pid: this.pid}).then((response) => {
                let data = response.data
                if (data) {
                    this.$refs.contactForm.prefillData(this.csrfToken, 'request-type-product-support', this.$t('text.request-about-article-for') + ' ' + data.Bezeichnung + ' | ' + data.Artikelnummer, { pid: data.Id}).then(() => {
                        modal('#md-article-question-' + this.pid).show()
                    })
                }
            }).finally(() => {
                this.isLoading = false
            })

        }
    }

};
</script>

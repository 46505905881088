<template>
    <div class="uk-grid-medium OrderItem" uk-grid="">
        <div class="uk-width-1-5" v-html="orderItem.Thumbnail"></div>
        <div class="uk-width-1-1 uk-width-expand@s">
            <div class="uk-grid-small" uk-grid>
                <div class="uk-width-1-1 uk-width-expand@m">
                    <p class="uk-text-bold">{{ orderItem.ProductName }}</p>
                    <p>{{ orderItem.ProductNumber }}</p>
                    <p>{{ $t('text.user.profile.order-list.item-amount')}}: {{ orderItem.Amount }}</p>
                </div>
                <div class="uk-width-1-1 uk-width-auto@m">
                    <span class="uk-text-bold">{{ orderItem.ProductPriceGrossString }}</span>
                </div>
                <div class="uk-width-1-1 ws-order-item-list--link-list" uk-grid>
                    <div class="uk-width-1-1 uk-width-1-2@m">
                        <a href="#" @click="cartStore.addCartItem({ pid: orderItem.ProductID, cartItemsCount: 1, type: 'cart' })">
                            <i class="fa-light fa-cart-arrow-down"></i>
                            {{ $t('text.shop.add-to-cart') }}
                        </a>
                    </div>
                    <div
                        class="uk-width-1-1 uk-width-1-2@m"
                        v-if="orderItem.UserManuals.length"
                    >
                        <template
                            v-for="userManual in orderItem.UserManuals"
                            :key="index"
                        >
                            <a
                                class="uk-margin-medium-left@m uk-margin-remove-left"
                                :href="userManual.publicPath"
                                target="_blank"
                            >
                                <i class="fa-light fa-file-arrow-down"></i>
                                <span v-if="userManual.title">{{ $t(userManual.title) }} </span>
                                <span v-else>{{ $t('text.user.profile.order.users-manual_download') }}</span>
                                <span v-if="userManual.language">&nbsp;({{ userManual.language }})</span>
                            </a>
                            <br>
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <hr class="uk-hr">
</template>

<script>

import {useCartStore} from "../../../../store/CartStore";

export default {
    name: 'OrderItem',
    props: {
        orderItem: {
            type: Object,
            required: true
        },
        orderCurrency: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            cartStore: useCartStore()
        }
    }
};
</script>

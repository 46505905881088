<template>
    <div>
        <section
            class="uk-section ws-shop-section uk-container-expand ws-sticky-inactive uk-margin-large-top"
            style=""
        >
            <button
                @click.prevent="modal('#productDetailsModal').hide()"
                class="uk-modal-close-default uk-float-right"
                type="button"
                uk-close
            />
            <div class="uk-container ws-shop-section-content-container ">
                <div
                    class="uk-grid-collapse ws-product-detailinformation uk-padding-remove-bottom uk-grid uk-grid-stack"
                    uk-grid="">
                    <div class="uk-width-1-1 ws-shop-content">
                        <div class="uk-grid-large uk-grid" uk-grid="">
                            <div class="uk-width-1-1 uk-width-1-2@m">
                                <div class="uk-position-relative" v-html="article.MainImageHtml">

                                </div>
                            </div>
                            <div class="uk-width-1-1 uk-width-1-2@m ws-product-detail-data">
                                <div class="uk-margin-medium-top">
                                    <h1>{{ article.Bezeichnung }}</h1>
                                    <div v-if="article.BeschreibungKurz" v-html="article.BeschreibungKurz">
                                    </div>
                                </div>
                                <div>
                                    <div class="ws-detail-attribute-list">
                                        <ul class="uk-list">
                                            <li>
                                                <span class="uk-text-bold">{{ $t('text.shop.label.brand') }}: </span>
                                                <span>{{ article.Brand }}</span>
                                            </li>
                                            <li>
                                                <span class="uk-text-bold">{{
                                                        $t('text.shop.label.article-number')
                                                    }}</span>
                                                <span>{{ article.Artikelnummer }}</span>
                                            </li>
                                            <li>
                                                <span class="uk-text-bold">{{ $t('text.shop.label.weight:') }}</span>
                                                <span>{{ article.GewichtBrutto }}</span>
                                            </li>
                                            <li>
                                                <i class="fa-solid fa-circle " :class="'ws-' + article.DeliveryState"></i> {{article.DeliveryMessage}}
                                            </li>
                                        </ul>
                                    </div>

                                    <div class="uk-grid-collapse uk-margin-medium-top uk-grid uk-grid-stack" uk-grid="">
                                        <div class="uk-width-1-1">
                                            <div class="ws-price-heading">{{ article.SinglePriceString }} *</div>
                                        </div>

                                        <div class="uk-width-1-1 ws-tax-information uk-grid-margin ">
                                            {{ article.PriceInfoDescription }}
                                        </div>
                                        <div
                                            class="uk-width-1-1 uk-margin-medium-top uk-grid-margin uk-child-width-1-2 uk-grid-medium"
                                            uk-grid
                                        >
                                            <div v-if="!ConfiguratorStore.getEntireArticleListIds.includes(article.Id)">
                                                <button
                                                    :class="{
                                                        'uk-disabled': !isProductCompatible(article) || !productCanBeAdded || Object.keys(spinnerButton).length,
                                                        'uk-button-default': !(!isProductCompatible(article) || !productCanBeAdded)
                                                    }"
                                                    :uk-spinner="spinnerButton[article.Id]"
                                                    class="uk-button uk-button-small uk-width-1-1"

                                                    @click="ConfiguratorStore.postConfiguratorExistingArticleList(category.identifier, article.Id, 1)"
                                                >{{ $t('configurator.item_already_exists') }}
                                                </button>
                                            </div>

                                            <div
                                                v-if="ConfiguratorStore.getExistingArticleListIds.includes(article.Id)">
                                                <button
                                                    :class="{
                                                        'uk-disabled': Object.keys(spinnerButton).length
                                                    }"
                                                    :uk-spinner="spinnerButton[article.Id]"
                                                    class="uk-button uk-button-default uk-button-small uk-width-1-1"
                                                    @click="ConfiguratorStore.postConfiguratorExistingArticleList(category.identifier, article.Id, 0)"
                                                >
                                                    {{ $t('configurator.remove_article_from_existing_configurator') }}
                                                </button>
                                            </div>

                                            <div v-if="!ConfiguratorStore.getEntireArticleListIds.includes(article.Id)">
                                                <button
                                                    :class="{
                                                        'uk-disabled': !isProductCompatible(article) || !productCanBeAdded || Object.keys(spinnerButton).length,
                                                        'uk-button-default': !(!isProductCompatible(article) || !productCanBeAdded)
                                                    }"
                                                    :uk-spinner="spinnerButton[article.Id]"
                                                    class="uk-button uk-button-small uk-width-1-1"
                                                    @click="ConfiguratorStore.postConfiguratorArticleList(category.identifier, article.Id, 1)"
                                                >{{ $t('configurator.add_item') }}
                                                </button>
                                            </div>

                                            <div v-if="ConfiguratorStore.getArticleListIds.includes(article.Id)">
                                                <button
                                                    :class="{
                                                        'uk-disabled': Object.keys(spinnerButton).length
                                                    }"
                                                    :uk-spinner="spinnerButton[article.Id]"
                                                    class="uk-button uk-button-default uk-button-small uk-width-1-1"
                                                    @click="ConfiguratorStore.postConfiguratorArticleList(category.identifier, article.Id, 0)"
                                                >
                                                    {{ $t('configurator.remove_article_from_configurator') }}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="uk-section ws-shop-section uk-container-expand uk-background-default ">
            <div class="uk-container ws-shop-section-content-container ">
                <div class="ws-shop-content uk-grid uk-grid-stack" uk-grid="">
                    <div class="uk-width-1-1 ws-article-detail-navigation-container">
                        <div class="uk-width-1-1">
                            <div class="uk-width-1-1 uk-margin-top">
                                <div class="ws-article-detail-information-tab" v-if="article.BeschreibungLang" id="description-long">
                                    <h2>{{ $t('text.product-detail.description-long') }}</h2>
                                    <div v-html="article.BeschreibungLang"></div>
                                </div>
                                <div class="ws-article-detail-information-tab" v-if="article.TechnischeDaten" id="technical-data">
                                    <h2>{{ $t('text.product-detail.technical-data') }}</h2>
                                    <div v-html="article.TechnischeDaten"></div>
                                </div>
                                <div class="ws-article-detail-information-tab" v-if="article.EigenschaftenVorteile" id="properties-advantages">
                                    <h2>{{ $t('text.product-detail.properties-advantages') }}</h2>
                                    <div v-html="article.EigenschaftenVorteile"></div>
                                </div>
                                <div class="ws-article-detail-information-tab" v-if="article.Lieferumfang" id="scope-of-delivery">
                                    <h2>{{ $t('text.product-detail.scope-of-delivery') }}</h2>
                                    <div v-html="article.Lieferumfang"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import {defineComponent} from 'vue'
import { useConfiguratorStore } from "../../store/ConfiguratorStore";
import {modal} from 'uikit';

export default defineComponent({
    name: "ConfiguratorProductDetails.vue",
    props: {
        category: {},
        article: {},
    },
    computed: {
        spinnerButton() {
            return this.ConfiguratorStore.getSpinnerButton;
        },
        isProductCompatible() {

            return product => {

                if (product.compatibility === undefined) {

                    return true;
                }

                let articleList = this.ConfiguratorStore.getEntireArticleListIds
                const excludingIdsList = this.ConfiguratorStore.getCategories.find(category => category.identifier === product.category).articles.map(a => a.Id);

                const availabilityIdsSet = new Set(this.ConfiguratorStore.getAllAvailableConfiguratorProducts)
                articleList = articleList.filter(id => availabilityIdsSet.has(id) && id !== product.Id && !excludingIdsList.includes(id))

                return articleList.every((addedId) => {
                    return product.compatibility.some((compatibilityObj) => {
                        return compatibilityObj.source.Id === addedId;
                    })
                })
            }
        },
        productCanBeAdded() {

            const countProductsInCategory = this.ConfiguratorStore.getProductCountFromCategory[this.category.identifier];

            if (countProductsInCategory === 0 || countProductsInCategory === undefined) {

                return true
            }

            return this.category.multiselect &&
                this.category.multiselect_count > countProductsInCategory;
        },
        getCompatibility() {

            return article => {
                if (article.compatibility === undefined) {
                    return null
                }

                const source = article.compatibility.find(item => this.ConfiguratorStore.getEntireArticleListIds.includes(item.source.Id))

                if (source === undefined) {
                    return null
                }

                if (source.required.length === 0) {
                    return null
                }

                if (source.required) {
                    if (source.required.filter(item => this.ConfiguratorStore.getEntireArticleListIds.includes(item.Id)).length) {
                        return null
                    }
                }

                return source.required
            }

        }
    },
    data() {
        return {
            ConfiguratorStore: useConfiguratorStore(),
            spinnerButton: {}
        }
    }
})
</script>
<template>

    <div
        class="uk-flex-top uk-flex uk-open uk-modal"
        v-show="showMe"
    >
        <div class="uk-width-auto uk-modal-dialog uk-modal-body uk-margin-auto-vertical uk-text-center ws-spinner">
            <div uk-spinner="ratio: 3"></div><br>
            <div id="ws-spinner-caption" class="uk-margin-small-top uk-text-center uk-text-primary uk-text-bold" v-html="loadingText"></div>
        </div>
    </div>

</template>

<script>
    export default {
        name: 'Spinner',
        data() {
            return {
                loadingText: "",
                useOverlay: false,
                showMe: false,
            }
        },
        methods: {
            show(loadingText, useOverlay = false) {
                this.loadingText = loadingText ?? ''
                this.showMe = true
                this.useOverlay = !(!useOverlay)
            },
            hide() {
                this.showMe = false
                this.loadingText = ''
                this.useOverlay = false
            }
        }
    };
</script>

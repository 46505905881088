<template>
    <div class="user-app-payment uk-width-1-1 uk-width-2-3@s">
        <h1>{{ $t('text.user.profile.payment.headline') }}</h1>
        <p>{{ $t('text.user.profile.payment.description') }}</p>
        <div
            v-for="payment in userProfileStore.paymentMethods"
            @click.prevent="makePreferred(payment.paymentId)"
            class="ws-payment-option uk-grid-medium"
            :class="{'selected': isPreferred(payment.paymentId)}"
            uk-grid
        >
            <div class="uk-width-expand">{{ payment.name }}</div>
            <div class="uk-width-auto" v-html="payment.iconHtml"></div>
        </div>
    </div>
</template>

<script>

import {useUserStore} from "../../../store/UserStore";
import {useUserProfileStore} from "../../../store/UserProfileStore";

export default {
    name: 'Payment',
    setup() {
        const userStore = useUserStore();
        const userProfileStore = useUserProfileStore()

        return {
            userStore,
            userProfileStore
        }

    },
    beforeMount() {
        this.userProfileStore.loadPaymentMethods()
    },
    methods: {
        isPreferred(method) {
            return method === this.userStore.userData.PreferredPayment
        },
        makePreferred(method) {
            this.userStore.setPaymentPreference(method)
        }
    }
};

</script>

<template>
    <div>
        <div>
            <form class="uk-search uk-search-default uk-width-1-1" action="/search">
                <span class="uk-search-icon-flip" uk-search-icon></span>
                <input :id="idWithNamespace('searchToggle')" v-model="searchTerm" name="term" class="uk-search-input" @keyup="fetchSearchResults" type="search"
                    :placeholder="$t('text.product-search')"/>
            </form>
        </div>
        <div v-show="searchResultList.length > 0">
            <div :id="idWithNamespace('ws-search-ac')" class="uk-dropdown uk-text-left" :uk-dropdown="'boundary: !.ws-shop-header-content-container;target: !.ws-shop-header-content-container; stretch: true; pos: bottom-right; flip:false; toggle: #' + idWithNamespace('searchToggle') + '; mode: click,hover'">
                <div v-for="(item, key) in searchResultList" :key="item.Id" class="ws-ac-item-hover">
                    <div class="ws-search-ac-item-container uk-grid-medium uk-position-relative" uk-grid>
                        <div class="uk-width-auto" v-html="item.imageHtml"></div>
                        <div class="uk-width-expand">
                            <h4 class="uk-margin-remove-bottom">{{ item.name }}<span v-if="item.articleNumber">&nbsp;|&nbsp;{{ item.articleNumber }}</span>
                            </h4>
                            <div v-html="item.descShort"></div>
                        </div>
                        <a v-if="item.link" :href="item.link" class="uk-position-cover" :title="item.name"></a>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import {dropdown} from 'uikit';
import ApiService from "../../service/ApiService";

export default {
    name: "SearchAutocomplete",
    data() {
        return {
            searchTerm: '',
            searchResultList: []
        }
    },
    methods: {
        fetchSearchResults() {
            if (this.searchTerm.length > 1) {
                ApiService.getProductSearchResult({term: this.searchTerm}).then(response => {
                    this.searchResultList = response.data
                    this.showDropdown()
                })
            }
        },
        showDropdown() {
            if(this.searchResultList.length > 0) {
                dropdown('#' + this.idWithNamespace('ws-search-ac')).show();
            } else {
                dropdown('#' + this.idWithNamespace('ws-search-ac')).hide();
            }
        },
        idWithNamespace(idString) {
            return idString + '-' + this.namespace
        }
    }
}
</script>

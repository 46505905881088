<template>
    <i
        @click="this.buttonClick()"
        class="ws-cursor-pointer"
        :class="[
            isItemInWishlist && !wishlistView ? 'fa-solid ws-text-sauterblau' : 'fa-light',
            wishlistView ? 'fa-trash-alt' : 'fa-bookmark',
        ]"
    >
    </i>
</template>

<script>
import { useCartStore } from "../../store/CartStore"

export default {
    name: "WishlistButton",
    props: {
        wishlistView: {
            type: Boolean,
            default: false,
        },
        itemInWishlist: {
            type: Boolean,
            default: false,
        },
        pid: {
            type: Number,
            default: 0,
            required: true,
        }
    },
    data() {
        return {
            isItemInWishlist: this.itemInWishlist,
            cartStore: useCartStore(),
        }
    },
    methods: {
        buttonClick() {

            if (this.isItemInWishlist) {

                this.isItemInWishlist = false;
                this.cartStore.removeCartItem({pid: this.pid, type: 'wishlist'})
                return

            }

            this.isItemInWishlist = true;
            this.cartStore.addCartItem({pid: this.pid, cartItemsCount: 1, type: 'wishlist'})

        }
    },
}
</script>
<template>
    <div>
        <h1>{{ $t('configurator.existing_articles_list_header') }}</h1>
        <ul>
            <li v-for="(products, category) in ConfiguratorStore.existingArticleList" :key="category">
                <h4>{{ category }}</h4>
                <ul>
                    <li v-for="(article) in products" :key="article.Id">
                        {{ article.Bezeichnung}}
                        <button @click="postConfiguratorExistingArticleList(category, article.Id, 0)">{{ $t('configurator.remove_article') }}</button>
                    </li>
                </ul>
            </li>
        </ul>
    </div>
</template>

<script>

import {useConfiguratorStore} from "../../store/ConfiguratorStore";
import axios from "axios";

export default {
    name: "ConfiguratorExistingArticles",
    data() {
        return {
            ConfiguratorStore: useConfiguratorStore()
        }
    },
    methods: {
        createFormData(category, productId, articleCount) {
            const formData = new FormData();
            formData.append('category', category);
            formData.append('productId', productId);
            formData.append('articleCount', articleCount);
            return formData;
        },

        handleArticleListResponse(product) {
            this.ConfiguratorStore.unsetAll();

            if (product.articlelist) {
                this.setupArticleList(product.articlelist, 'setArticleList', 'addArticleToArticleListIds');
            }
            if (product.existingarticlelist) {
                this.setupArticleList(product.existingarticlelist, 'setExistingArticleList', 'addArticleToExistingArticleListIds');
            }

            this.ConfiguratorStore.setAlertMessage(true, product.Artikelnummer);
        },

        setupArticleList(articleList, setterFunction, adderFunction) {
            this.ConfiguratorStore[setterFunction](articleList);
            Object.keys(articleList).forEach(category => {
                articleList[category].forEach(article => {
                    this.ConfiguratorStore[adderFunction](article.Id);
                    this.ConfiguratorStore.setProductsAdded(category, article.Id);
                });
                this.ConfiguratorStore.setCategoriesDone(category);
            });
        },

        postConfiguratorData(type, category, productId, articleCount = 0) {
            axios.post(`/api/configurator/${this.identifier}/${type}/${this.ConfiguratorStore.getConfiguratorProductId}`, this.createFormData(category, productId, articleCount), {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }).then(response => {
                if (response.status === 200) {
                    if (!this.articleId) {
                        this.router.push(`/configurator/${this.identifier}/${response.data.Id}`);
                    }
                    this.handleArticleListResponse(response.data);
                } else {
                    this.ConfiguratorStore.setAlertMessage(false, response.data);
                }
            }).catch(err => {
                this.ConfiguratorStore.setAlertMessage(false, err.message || "Fehler");
            });
        },

        postConfiguratorExistingArticleList(category, productId, articleCount = 0) {
            this.postConfiguratorData('existing-article-list', category, productId, articleCount);
        }
    }
}

</script>
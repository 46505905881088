<template>
    <div>
        <form @submit="sendForm">
            <FloatingLabelInput
                :label="$t('text.e-mail')"
                :error-message="errors.email"
                v-model="email"
                @change="emailChange"
                type="email"
                :class="['uk-margin-medium-top']"
            />
            <FloatingLabelPassword
                :label="$t('text.password')"
                v-model="password"
                :error-message="errors.password"
                :class="['uk-margin-remove-bottom']"
                @change="passwordChange"
            />
            <div class="uk-grid-small" uk-grid>
                <div class="uk-width-auto ws-sub-text"></div>
                <div class="uk-width-expand uk-text-right ws-sub-text"><a href="/user/forgot-password" :title="$t('text.title.forgot-password')">{{ $t("text.forgot-password") }}</a></div>
            </div>
            <button class="uk-button uk-button-default uk-width-1-1 uk-margin-medium-top" type="submit">
                <span v-if="userStore.isLoading" uk-spinner="ratio:1"></span>
                <span v-else>{{ $t("text.login") }}</span>
            </button>
            <p class="uk-text-warning" v-if="showErrorMessage">{{ $t(userStore.loginErrorMessage) }}</p>
        </form>
    </div>
</template>
<script>
import BaseInput from "../form/base/BaseInput.vue"
import BaseSelect from "../form/base/BaseSelect.vue"
import FloatingLabelInput from "../form/base/FloatingLabelInput.vue";
import FloatingLabelPassword from "../form/base/FloatingLabelPassword.vue";
import {useField, useForm} from 'vee-validate'
import {useI18n} from 'vue-i18n'
import {object, string, email, required, StringSchema} from 'yup'


import {useUserStore} from "../../store/UserStore.js";

export default {
    name: "UserLoginForm",
    components: {
        BaseInput,
        BaseSelect,
        FloatingLabelInput,
        FloatingLabelPassword,
    },
    setup(props) {
        const {t} = useI18n({})
        const userStore = useUserStore()

        const validationSchema = object({
            email: string().required(t("text.error.input.required")).email(t("text.error.input.invalid-email")),
            password: string().required(t("text.error.input.required"))
        })
        const {handleSubmit, errors} = useForm({
            validationSchema
        })

        const {value: email, handleChange: emailChange} = useField('email')
        const {value: password, handleChange: passwordChange} = useField('password')

        const sendForm = handleSubmit((values) => {
            let appElement = document.getElementById('user-app')

            if (appElement !== null) {
                values.redirecturl = document.getElementById('user-app').getAttribute('data-referer') ?? '/'
            } else {
                values.redirecturl = props.redirectTo
            }

            userStore.loginUser(values)
                .then((response) => {
                    if (response.data.redirect_route) {
                        window.location.href = response.data.redirect_route
                    }
                }).catch((error) => {
                    console.log(error)
            });
        })
        return {
            email,
            password,
            sendForm,
            errors,
            emailChange,
            passwordChange,
            userStore,
        }
    },
    props: {
        redirectTo: {
            type: String,
            default: '/',
        }

    },
    data() {
        return {
            errorMessageEmail: '',
            errorMessagePassword: ''
        }
    },
    computed: {
        showErrorMessage() {
            return (!this.isLoading && this.errorMessage !== '');
        }
    }
}
</script>
<template>

    <div id="empty-vat-id-modal" uk-modal>
        <div class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
            <div class="uk-padding-small">
                <p>
                    <b>{{ $t('text.hint') }}:</b>
                    <br>
                    {{ $t('text.checkout.empty_vat_id_hint') }}
                </p>
                <div class="uk-margin-small-top">
                    <button class="uk-button uk-button-primary uk-modal-close"
                            type="button">
                        <span>{{ $t('text.back-link') }}</span>
                    </button>
                    <button class="uk-button uk-button-default uk-margin-small-left"
                            type="button"
                            @click="submitFormAndCloseEmptyVatAlertModal">
                        <span>{{ $t('text.continue') }}</span>
                    </button>
                </div>
            </div>
        </div>
    </div>

    <div v-if="!stepIsActive && stepIsFilled">
        <p>
            <span v-if="CheckoutStore.checkoutData.steps.address.data.gender !== 'diverse'">{{ $t('text.customer.salutation.' + CheckoutStore.checkoutData.steps.address.data.gender) }}</span>
            {{ CheckoutStore.checkoutData.steps.address.data.firstName }}
            {{ CheckoutStore.checkoutData.steps.address.data.lastName }}<br>
            {{ CheckoutStore.checkoutData.steps.address.data.email }}<br>
            {{ CheckoutStore.checkoutData.steps.address.data.phone }}
        </p>
        <div v-if="CheckoutStore.checkoutData.steps.address.data.customerType === UserConstants.CustomerTypeBusinessKey">
            <h3>{{ $t('text.checkout.sub-headline.company') }}</h3>
            <p>
                {{ CheckoutStore.checkoutData.steps.address.data.company }}<br>
                <span v-if="CheckoutStore.checkoutData.steps.address.data.department">{{ CheckoutStore.checkoutData.steps.address.data.department }}<br></span>
                <span v-if="CheckoutStore.checkoutData.steps.address.data.vatId">{{ $t('text.checkout.input.vat-id') }}: {{ CheckoutStore.checkoutData.steps.address.data.vatId }}<br></span>
                <span v-if="CheckoutStore.checkoutData.steps.address.data.internalOrderId">{{ $t('text.checkout.input.internal-order-id') }}: {{ CheckoutStore.checkoutData.steps.address.data.internalOrderId }}</span>
            </p>
        </div>
        <h3>{{ $t('text.checkout.sub-headline.billing-address') }}</h3>
        <p>
            {{ CheckoutStore.checkoutData.steps.address.data.street }} {{ CheckoutStore.checkoutData.steps.address.data.streetNumber }}<br>
            <span v-if="CheckoutStore.checkoutData.steps.address.data.streetExtra">{{ CheckoutStore.checkoutData.steps.address.data.streetExtra }}<br></span>
            {{ CheckoutStore.checkoutData.steps.address.data.zip }} {{ CheckoutStore.checkoutData.steps.address.data.city }}<br>
            {{ retrieveCountryName(CheckoutStore.checkoutData.steps.address.data.country) }}
        </p>
    </div>

    <div v-if="stepIsActive">

        <p
            class="uk-padding-small uk-padding-remove-horizontal uk-padding-remove-top"
            v-if="!CheckoutStore.checkoutData.steps.address.data.customer"
        >
            {{ $t('text.checkout.note.order-as-guest') }}
        </p>

        <BaseRadio
            v-model="CheckoutStore.checkoutData.steps.address.data.customerType"
            :options="CheckoutStore.checkoutData.steps.address.options.customerType"
            :label="$t('text.checkout.input.customerType')"
            :error-message="retrieveError('customerType')"
            class="uk-margin-small-bottom"
        />

        <BaseRadio
            v-model="CheckoutStore.checkoutData.steps.address.data.gender"
            :options="CheckoutStore.checkoutData.steps.address.options.gender"
            :label="$t('text.checkout.input.gender')"
            :error-message="retrieveError('gender')"
            class="uk-margin-medium-bottom uk-margin-remove-top"
        />

        <FloatingLabelInput
            :label="$t('text.checkout.input.firstname')"
            :error-message="retrieveError('firstName')"
            v-model="CheckoutStore.checkoutData.steps.address.data.firstName"
            type="input"
        />

        <FloatingLabelInput
            :label="$t('text.checkout.input.lastname')"
            :error-message="retrieveError('lastName')"
            v-model="CheckoutStore.checkoutData.steps.address.data.lastName"
            type="input"
        />

        <FloatingLabelInput
            :label="$t('text.checkout.input.email')"
            :error-message="retrieveError('email')"
            v-model="CheckoutStore.checkoutData.steps.address.data.email"
            type="input"
            v-if="!CheckoutStore.checkoutData.steps.address.data.customer"
        />

        <FloatingLabelInput
            :label="$t('text.checkout.input.email-confirm')"
            :error-message="retrieveError('emailConfirm')"
            :hint="$t('text.checkout.input.email-hint')"
            v-model="CheckoutStore.checkoutData.steps.address.data.emailConfirm"
            type="input"
            v-if="!CheckoutStore.checkoutData.steps.address.data.customer"
        />

        <FloatingLabelInput
            :label="$t('text.checkout.input.phone')"
            :error-message="retrieveError('phone')"
            :hint="$t('text.checkout.input.phone-hint')"
            v-model="CheckoutStore.checkoutData.steps.address.data.phone"
            type="input"
        />

        <div v-if="CheckoutStore.checkoutData.steps.address.data.customerType === UserConstants.CustomerTypeBusinessKey">

            <h3 class="uk-margin-small-bottom">{{ $t('text.checkout.sub-headline.company') }}</h3>

            <FloatingLabelInput
                :label="$t('text.checkout.input.company')"
                :error-message="retrieveError('company')"
                v-model="CheckoutStore.checkoutData.steps.address.data.company"
                type="input"
            />

            <FloatingLabelInput
                :label="$t('text.checkout.input.department')"
                :error-message="retrieveError('department')"
                v-model="CheckoutStore.checkoutData.steps.address.data.department"
                type="input"
                :required="false"
            />

            <FloatingLabelInput
                :label="$t('text.checkout.input.vat-id')"
                :error-message="retrieveError('vatId')"
                v-model="CheckoutStore.checkoutData.steps.address.data.vatId"
                type="input"
                :required="false"
            />

            <FloatingLabelInput
                :label="$t('text.checkout.input.internal-order-id')"
                :error-message="retrieveError('internalOrderId')"
                v-model="CheckoutStore.checkoutData.steps.address.data.internalOrderId"
                type="input"
                :required="false"
            />

        </div>
        <BaseButton
            v-if="formSize === 'short'"
            :full-width="true"
            :href="'#'"
            class="uk-margin-medium-top"
            @click.prevent="showCheckoutForm()"
        >
            {{ $t('text.checkout.button.start-checkout') }}
        </BaseButton>
        <template v-if="formSize === 'long' && CheckoutStore.checkoutData.current === stepName">
            <h3 class="uk-margin-small-bottom">{{ $t('text.checkout.sub-headline.address') }}</h3>

            <BaseSelect
                :label="$t('text.checkout.select.country')"
                :error-message="retrieveError('country')"
                v-model="CheckoutStore.checkoutData.steps.address.data.country"
                :options="CheckoutStore.checkoutData.steps.address.options.country"
                @change="this.previewCart($event)"
                :include-please-choose-option="true"
            />

            <div uk-grid>

            <FloatingLabelInput
                :label="$t('text.checkout.input.street')"
                :error-message="retrieveError('street')"
                :custom-element-id="googleAutocompleteInput"
                @vue:mounted="initGoogleAutocompleteService"
                v-model="CheckoutStore.checkoutData.steps.address.data.street"
                type="input"
                class="uk-width-1-1 uk-width-4-5@m"
            />

                <FloatingLabelInput
                    :label="$t('text.checkout.input.street-number')"
                    :error-message="retrieveError('streetNumber')"
                    v-model="CheckoutStore.checkoutData.steps.address.data.streetNumber"
                    type="input"
                    class="uk-width-1-1 uk-width-1-5@m uk-margin-remove-top"
                />

            </div>

            <FloatingLabelInput
                :label="$t('text.checkout.input.street-extra')"
                :error-message="retrieveError('streetExtra')"
                v-model="CheckoutStore.checkoutData.steps.address.data.streetExtra"
                type="input"
                :required="false"
            />

            <div uk-grid class="uk-margin-remove-top">

                <FloatingLabelInput
                    :label="$t('text.checkout.input.zip')"
                    :error-message="retrieveError('zip')"
                    v-model="CheckoutStore.checkoutData.steps.address.data.zip"
                    type="input"
                    class="uk-width-1-1 uk-width-1-4@m"
                />

                <FloatingLabelInput
                    :label="$t('text.checkout.input.city')"
                    :error-message="retrieveError('city')"
                    v-model="CheckoutStore.checkoutData.steps.address.data.city"
                    type="input"
                    class="uk-width-1-1 uk-width-3-4@m uk-margin-remove-top"
                />

            </div>

            <div
                class="ws-checkout-error-general uk-text-danger"
                v-if="CheckoutStore.checkoutData.error.general && (CheckoutStore.checkoutData.current === this.stepName)"
            >
                {{ CheckoutStore.checkoutData.error.general }}
            </div>

            <BaseButton
                :full-width="true"
                :href="'#'"
                @click.prevent="submitForm"
                class="uk-margin-medium-top"
            >
                {{ $t('text.checkout.button.go-to-delivery') }}
            </BaseButton>
        </template>

    </div>

    <hr class="uk-margin-medium-top uk-margin-medium-bottom">

</template>

<script>

import FloatingLabelInput from "../../form/base/FloatingLabelInput.vue";
import BaseSelect from "../../form/base/BaseSelect.vue";
import BaseRadio from "../../form/base/BaseRadio.vue";
import BaseButton from "../../form/base/BaseButton.vue";
import { useCheckoutStore } from "../../../store/CheckoutStore.js"
import { useCartStore } from "../../../store/CartStore.js";
import { usePaymentStore } from "../../../store/PaymentStore.js";
import googleAutocompleteMixin from "../../../mixins/googleAutocompleteMixin";
import { object, string, ValidationError } from 'yup';
import * as yup from 'yup';
import {modal} from "uikit";
import UserConstants from "../../../constants/userConstants";

export default {
    name: "Address",
    mixins: [googleAutocompleteMixin],
    data() {
        return {
            googleAutocompleteInput: 'google-autocomplete-checkout',
            CheckoutStore: useCheckoutStore(),
            CartStore: useCartStore(),
            PaymentStore: usePaymentStore(),
            stepName: 'address'
        }
    },
    components: {
        BaseRadio,
        FloatingLabelInput,
        BaseSelect,
        BaseButton
    },
    props: {
        stepIsActive: {
            type: Boolean,
            required: true,
        },
        stepIsFilled: {
            type: Boolean,
            default: false,
        },
        formSize: {
            type: String,
            default: 'short'
        }
    },
    computed: {
        UserConstants() {
            return UserConstants
        },
        googleAutocompleteSelectedCountryCode() {
            return this.CheckoutStore.checkoutData.steps.address.data.country;
        },
        validationSchema() {

            const requiredFieldMessage = this.$i18n.t("text.customer.registration.error.mandatory-field-empty");
            const invalidEmailMessage = this.$i18n.t("text.customer.registration.error.invalid-email-address");
            const emailMismatchMessage = this.$i18n.t("text.customer.registration.error.email-mismatch");

            return object({
                customerType: string().typeError(requiredFieldMessage),
                gender: string().typeError(requiredFieldMessage),
                firstName: string().required(requiredFieldMessage).typeError(requiredFieldMessage),
                lastName: string().required(requiredFieldMessage).typeError(requiredFieldMessage),
                phone: string().required(requiredFieldMessage).typeError(requiredFieldMessage),
                email: string()
                    .typeError(requiredFieldMessage)
                    .email(invalidEmailMessage)
                    .required(requiredFieldMessage),
                emailConfirm: string()
                    .typeError(requiredFieldMessage)
                    .email(invalidEmailMessage)
                    .oneOf([yup.ref('email')], emailMismatchMessage)
            });
        },
        vatId() {
            return this.CheckoutStore.checkoutData.steps.address.data.vatId;
        },
        customerType() {
            return this.CheckoutStore.checkoutData.steps.address.data.customerType;
        }
    },
    watch: {
        googleAutocompleteSelectedCountryCode(countryCode) {
            if (this.googelAutocompleteAvailable && countryCode) {
                this.googleAutocompleteChangeCountryCode(countryCode)
            }
        }
    },
    methods: {
        async validateForm() {
            try {
                await this.validationSchema.validate(this.CheckoutStore.checkoutData.steps.address.data, { abortEarly: false });
                return true;
            } catch (error) {
                if (error instanceof ValidationError) {
                    console.log(error.inner)
                    const errorObj = error.inner.reduce((acc, currError) => {
                        acc[currError.path] = currError.message;
                        return acc;
                    }, {});

                    this.CheckoutStore.checkoutData.error.address = errorObj;
                }
                return false;
            }
        },
        async showCheckoutForm() {
            await this.validateForm();
            this.$emit('changeFormSize', 'long')
        },
        googleAutocompleteClearAddressForm() {
            this.CheckoutStore.checkoutData.steps.address.data.street = null;
            this.CheckoutStore.checkoutData.steps.address.data.streetNumber = null;
            this.CheckoutStore.checkoutData.steps.address.data.city = null;
            this.CheckoutStore.checkoutData.steps.address.data.zip = null;
        },
        googleAutocompleteUpdateAddress() {
            this.CheckoutStore.checkoutData.steps.address.data.street = this.googleAutocompleteAddress.street;
            this.CheckoutStore.checkoutData.steps.address.data.streetNumber = this.googleAutocompleteAddress.streetNumber;
            this.CheckoutStore.checkoutData.steps.address.data.city = this.googleAutocompleteAddress.city;
            this.CheckoutStore.checkoutData.steps.address.data.zip = this.googleAutocompleteAddress.zip;
        },
        submitForm(){
            if(this.customerType === UserConstants.CustomerTypeBusinessKey && !this.vatId){
                this.openEmptyVatAlertModal();
                return
            }
            this.sendData()
        },
        submitFormAndCloseEmptyVatAlertModal() {
            this.closeEmptyVatAlertModal();
            this.sendData();
        },
        sendData() {
            this.$parent.showSpinner(this.$t('text.loading-spinner.save-checkout-data.address'))

            this.CheckoutStore.getToken(this.stepName).then(

                (result) => {

                    if (result.hasOwnProperty('data') && result.data.hasOwnProperty('token')) {

                        this.CheckoutStore.sendCheckoutData({ step: this.stepName, token: result.data.token }).then(
                            (response) => {
                                this.CartStore.fetchCartData().then(() => {
                                    this.$parent.hideSpinner()
                                })
                            }
                        )
                    }
                }

            ).then(() => {
                this.$parent.hideSpinner()
            })

        },
        retrieveError(field) {

            if (this.CheckoutStore.checkoutData.error[field]) {
                return this.CheckoutStore.checkoutData.error[field]
            }

            return '';

        },
        previewCart(event) {

            this.PaymentStore.fetchPaymentTypes({country: event.target.value})

            if (
                !this.CheckoutStore.checkoutData.steps.delivery.isFilled
                || this.CheckoutStore.checkoutData.steps.delivery.data.addressDiffers === "no"
            ) {
                this.CartStore.fetchCartData({deliveryCountry: event.target.value})
            }

        },
        retrieveCountryName(countryCode) {

            if (!this.CheckoutStore.checkoutData.steps.address.options.country) {
                return ''
            }

            for(const index in this.CheckoutStore.checkoutData.steps.address.options.country) {
                if (this.CheckoutStore.checkoutData.steps.address.options.country[index].value === countryCode) {
                    return this.CheckoutStore.checkoutData.steps.address.options.country[index].name
                }
            }
        },
        openEmptyVatAlertModal(){
            modal('#empty-vat-id-modal').show()
        },
        closeEmptyVatAlertModal(){
            modal('#empty-vat-id-modal').hide()
        }
    },
}
</script>

<template>
    <div class="ws-configurator-product-container uk-overflow-auto uk-list uk-margin-remove">
        <div
            v-for="article in sortedProducts"
            :key="article.id"
            :uk-tooltip="!ConfiguratorStore.getEntireArticleListIds.includes(article.Id) && !productCanBeAdded ? $t('configurator.cannot_add_more_articles') : ''"
            class="ws-configurator-product-tile uk-padding uk-position-relative"
        >
            <div uk-alert class="uk-width-1 uk-alert-info uk-padding-small uk-margin-bottom"
                 v-if="!article.isCompatible">
                {{ $t('configurator.text_article_not_compatible') }}
            </div>
            <div class="ws-configurator-product-tile__upper-section uk-flex uk-grid-small">
                <div class="uk-width-1-3@s uk-flex uk-flex-middle uk-flex-center@s" v-html="article.ThumbnailHtml"></div>
                <div class="uk-flex-auto uk-width-2-3@s">
                    <div class="uk-width-1">
                        <p class="uk-h3">{{ article.Bezeichnung }}</p>
                        <p v-if="article.Artikelnummer">{{ $t('text.shop.label.article-number') }}
                            {{ article.Artikelnummer }}</p>
                        <p>
                            <i class="fa-solid fa-circle " :class="'ws-' + article.DeliveryState"></i>
                            {{ article.DeliveryMessage }}
                        </p>
                    </div>
                    <div class="ws-configurator-product-tile__bottom-section uk-flex uk-flex-between uk-flex-middle">
                        <div>
                            <button
                                @click="$emit('detailsModalOpen', {article: article, category: category})"
                                class="uk-button uk-button-default uk-button-small">
                                {{ $t('configurator.article_details') }}
                            </button>
                        </div>
                        <div>
                            <span
                                class="uk-text-bolder"
                                v-if="ConfiguratorStore.getArticlePriceList[article.Id]"
                                v-html="ConfiguratorStore.getArticlePriceList[article.Id]?? null"></span>
                            <span v-else uk-spinner="ratio: 0.5"></span>
                            <br>
                            <span class="uk-text-small" v-html="$t(article.PriceInfoDescription)"></span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="uk-width-1 uk-margin-top">
                <div class="uk-width-1-1">
                    <div
                        v-if="getCompatibility(article)"
                        class="uk-alert-warning uk-padding" uk-alert
                    >
                        <div>
                            <p class="uk-h3">
                                <i class="fa-solid fa-triangle-exclamation uk-margin-right"></i>
                                {{ $t('configurator.article_only_compatible_with') }}
                            </p>
                            <ul class="uk-list">
                                <li v-for="required in getCompatibility(article)" :key="required.Id">
                                    {{ required.Bezeichnung }} | {{ required.Artikelnummer }}
                                </li>
                            </ul>
                        </div>
                        <button
                            @click="showModal(article.Id)"
                            :class="{
                                'uk-disabled':!productCanBeAdded,
                            }"
                            type="button"
                            class="uk-button uk-button-default uk-width-1 uk-button-small uk-margin-bottom"
                        >
                            {{ $t('configurator.solve_required_articles') }}
                        </button>
                        <div :id="'req-'+article.Id" uk-modal class="required-modal">
                            <div class="uk-modal-dialog uk-margin-auto-vertical uk-modal-body">
                                <div class="uk-modal-title">
                                    <h2>
                                        {{ $t('configurator.required_article') }}
                                    </h2>
                                </div>
                                <div class="uk-modal-body">
                                    <div v-for="required in getCompatibility(article)" :key="required.Id"
                                         class="uk-grid">
                                        <div class="uk-width-1-6" v-html="required.ThumbnailHtml"></div>
                                        <div class="uk-width-5-6">
                                            <div class="uk-width-1">
                                                <p class="uk-h3">{{ required.Bezeichnung }}</p>
                                                <p>{{ required.Artikelnummer }}</p>
                                            </div>
                                            <div class="uk-grid">
                                                <div class="uk-width-1-1 text-right">
                                                    <span class="uk-text-bold uk-float-right">
                                                        {{ article.SinglePriceString }}
                                                        <p v-html="$t(article.PriceInfoDescription)"></p>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="uk-width-1-1">
                                            <button
                                                @click="postConfiguratorRequiredArticleList(article.Id,'required', required.Id, 1)"
                                                class="uk-button-default uk-button-small uk-button uk-float-left">
                                                {{ $t('configurator.add_required_article_to_configurator') }}
                                            </button>
                                            <button
                                                @click="postConfiguratorRequiredExistingArticleList(article.Id,'required', required.Id, 1)"
                                                class="uk-button-default uk-button-small uk-button uk-float-right">
                                                {{ $t('configurator.do_without_required_article') }}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div class="uk-modal-footer">
                                    <button class="uk-modal-close uk-button uk-button-default uk-button-small"
                                            type="button">
                                        {{ $t('configurator.close_modal') }}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else
                         class="uk-child-width-1-2 uk-grid-medium"
                         uk-grid
                    >
                        <div v-if="!ConfiguratorStore.getExistingArticleListIds.includes(article.Id)">
                            <button
                                :uk-spinner="spinnerButton[article.Id]"
                                @click="ConfiguratorStore.postConfiguratorExistingArticleList(category.identifier, article.Id, 1)"
                                class="uk-button uk-button-small uk-width-1-1"
                                :class="{
                                    'uk-disabled': !isProductCompatible(article) || !productCanBeAdded || Object.keys(spinnerButton).length || ConfiguratorStore.getEntireArticleListIds.includes(article.Id),
                                    'uk-button-default': !(!isProductCompatible(article) || !productCanBeAdded)
                                }"
                            >{{ $t('configurator.item_already_exists') }}
                            </button>
                        </div>

                        <div v-if="ConfiguratorStore.getExistingArticleListIds.includes(article.Id)">
                            <button
                                :uk-spinner="spinnerButton[article.Id]"
                                class="uk-button uk-button-default uk-button-small uk-width-1-1"
                                :class="{
                                    'uk-disabled uk-button-default': Object.keys(spinnerButton).length
                                }"
                                @click="ConfiguratorStore.postConfiguratorExistingArticleList(category.identifier, article.Id, 0)"
                            >
                                {{ $t('configurator.remove_article_from_existing_configurator') }}
                            </button>
                        </div>

                        <div v-if="!ConfiguratorStore.getArticleListIds.includes(article.Id)">
                            <button
                                :uk-spinner="spinnerButton[article.Id]"
                                @click="ConfiguratorStore.postConfiguratorArticleList(category.identifier, article.Id, 1)"
                                class="uk-button uk-button-small uk-width-1-1 uk-text-small@s"
                                :class="{
                                    'uk-disabled': !this.isProductCompatible(article) || !this.productCanBeAdded || Object.keys(this.spinnerButton).length,
                                    'uk-button-default': !(!isProductCompatible(article) || !productCanBeAdded),
                                }"
                            >{{ $t('configurator.add_item') }}
                            </button>
                        </div>

                        <div v-if="ConfiguratorStore.getArticleListIds.includes(article.Id)">
                            <button
                                :uk-spinner="spinnerButton[article.Id]"
                                class="uk-button uk-button-default uk-button-small uk-width-1-1 uk-text-small@s"
                                :class="{
                                    'uk-disabled': Object.keys(spinnerButton).length
                                }"
                                @click="ConfiguratorStore.postConfiguratorArticleList(category.identifier, article.Id, 0)"
                            >
                                {{ $t('configurator.remove_article_from_configurator') }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import {modal} from "uikit";
import {defineComponent} from "vue";
import axios from "axios";

import {useConfiguratorStore} from "../../store/ConfiguratorStore";

import {useRouter} from "vue-router";

export default defineComponent({
    name: 'ConfiguratorCategoryProducts',
    props: {
        products: {},
        category: {}
    },
    data() {
        return {
            ConfiguratorStore: useConfiguratorStore(),
            router: useRouter()
        };
    },
    computed: {
        sortedProducts() {
            return this.products.map((p) => {
                p.isCompatible = this.isProductCompatible(p)
                return p
            }).sort((a, b) => {
                if (a.isCompatible && !b.isCompatible) {
                    return -1;
                }
                if (!a.isCompatible && b.isCompatible) {
                    return 1;
                }
                return 0;
            });
        },
        spinnerButton() {
            return this.ConfiguratorStore.getSpinnerButton;
        },
        isProductCompatible() {

            return product => {

                if (product.compatibility === undefined) {

                    return true;
                }

                let articleList = this.ConfiguratorStore.getEntireArticleListIds
                const excludingIdsList = this.ConfiguratorStore.getCategories.find(category => category.identifier === product.category).articles.map(a => a.Id);

                const availabilityIdsSet = new Set(this.ConfiguratorStore.getAllAvailableConfiguratorProducts)
                articleList = articleList.filter(id => availabilityIdsSet.has(id) && id !== product.Id && !excludingIdsList.includes(id))

                return articleList.every((addedId) => {
                    return product.compatibility.some((compatibilityObj) => {
                        return compatibilityObj.source.Id === addedId;
                    })
                })
            }
        },
        productCanBeAdded() {

            const countProductsInCategory = this.ConfiguratorStore.getProductCountFromCategory[this.category.identifier];

            if (countProductsInCategory === 0 || countProductsInCategory === undefined) {

                return true
            }

            return this.category.multiselect &&
                this.category.multiselect_count > countProductsInCategory;
        },
        getCompatibility() {

            return article => {
                if (article.compatibility === undefined) {
                    return null
                }

                const source = article.compatibility.find(item => this.ConfiguratorStore.getEntireArticleListIds.includes(item.source.Id))

                if (source === undefined) {
                    return null
                }

                if (source.required.length === 0) {
                    return null
                }

                if (source.required) {
                    if (source.required.filter(item => this.ConfiguratorStore.getEntireArticleListIds.includes(item.Id)).length) {
                        return null
                    }
                }

                return source.required
            }

        },
    },
    methods: {
        showModal(id) {
            modal('#req-' + id).show()
        },

        addArticleToRequiredArticlesRenounced(modalId, requiredId) {
            this.ConfiguratorStore.addArticleToRequiredArticlesRenounced(requiredId, 1).then(() => {
                modal('#req-' + modalId).hide()
            })
        },
    }
});
</script>
<template>
    <a @click.prevent="openReturn()" class="uk-link uk-text-secondary">
        <i class="fa-regular fa-file-import uk-margin-small-right"></i>{{ $t('text.user.profile.return.create-return-button') }}
    </a>
    <div :id="'order-return-'+order.OrderId" uk-modal>
        <div class="uk-modal-dialog uk-modal-body">
            <h1>{{ $t('text.user.profile.return.create-return-header') }}</h1>
            <p>{{ $t('text.user.profile.return.create-return-info') }}</p>
            <FloatingLabelInput
                :label="$t('text.user.profile.return.customer-email-address')"
                v-model="email"
                :class="['uk-margin-medium-bottom', 'uk-margin-medium-top']"
            />
            <div class="uk-flex uk-flex-wrap">
                <template v-for="(item, index) in order.Items">
                    <BaseCheckbox
                        :label="item.ProductName"
                        v-model="selectedItems[index]"
                        :class="['uk-width-1-1']"
                    />
                </template>
                <BaseTextarea
                    :label="$t('text.user.profile.return.return-reason')"
                    v-model="reason"
                    :class="['uk-width-1-1', 'uk-margin-medium-top']"
                    :required="false"
                />
                <BaseButton
                    v-if="buttonIsReady"
                    @click.prevent="createReturn()"
                    :fullWidth="true"
                    href="#"
                    :class="['uk-margin-medium-top']"
                >
                    {{ $t('text.user.profile.return.create-return-button') }}
                </BaseButton>
            </div>
        </div>
    </div>
</template>

<script>
import {modal, notification} from "uikit";
import FloatingLabelInput from "../../../form/base/FloatingLabelInput.vue";
import BaseCheckbox from "../../../form/base/BaseCheckbox.vue";
import BaseTextarea from "../../../form/base/BaseTextarea.vue";
import BaseButton from "../../../form/base/BaseButton.vue";
import {useUserStore} from "../../../../store/UserStore";
import ApiService from "../../../../service/ApiService";
export default {
    name: 'Return',
    components: {BaseButton, BaseCheckbox, FloatingLabelInput, BaseTextarea},
    props: ['order'],
    data() {
        return {
            email: '',
            reason: '',
            selectedItems: [],
            userStore: useUserStore(),
            submitted: false,
            errors: []
        }
    },
    computed: {
        buttonIsReady() {
            return this.selectedItems.some(item => item === true);
        }
    },
    created() {
        this.email = this.userStore.userData.Email
    },
    methods: {
        showNotification(message, status = "success") {
            const timeout = 5000
            notification({
                message: this.$t(message),
                status: status,
                pos: 'top-center',
                timeout: timeout
            })
            setTimeout(() => this.privacyError = false, timeout)
        },
        openReturn() {
            modal('#order-return-'+this.order.OrderId).show()
        },
        createReturn() {
            const payload = {
                'email': this.email,
                'reason': this.reason,
                'items': this.selectedItems.map((item, index) => {
                    return {
                        'productNumber': this.order.Items[index].ProductNumber,
                        'productName': this.order.Items[index].ProductName,
                        'return': item
                    }
                }).filter(item => item.return)
            }

            ApiService.postReturnForm(payload)
                .then((result) => {
                    if (result.status === 200 && result.data.success === true) {
                        modal('#order-return-'+this.order.OrderId).hide()
                        this.showNotification('text.user.profile.return.successful')
                        this.submitted = true
                    }
                })
                .catch((error) => this.errors = error)
        }
    }
}
</script>
<template>
    <label :for="uuid">{{ label }}<span v-if="required"><sup>*</sup></span></label>
    <input
        v-bind="$attrs"
        :placeholder="label"
        class="uk-input"
        :value="modelValue"
        @input="$emit('update:modelValue', $event.target.value)"
        :id="uuid"
        :aria-describedby="errorMessage ? `${uuid}-error` : null"
        :aria-invalid="errorMessage ? true : null"
    >
    <p
        class="uk-text-warning"
        v-if="errorMessage"
        :id="`${uuid}-error`"
        aria-live="assertive"
    >
        {{ errorMessage }}
    </p>
</template>

<script>
import UniqueID from "../../../includes/UniqueID.js";

export default {
    name: 'BaseInput',
    props: {
        label: {
            type: String,
            default: ''
        },
        modelValue: {
            type: [String, Number],
            default: ''
        },
        required: {
            type: Boolean,
            default: true
        },
        errorMessage: {
            type: String,
            default: ''
        }
    },
    setup() {
        const uuid = UniqueID().getID()
        return {
            uuid
        }
    }
};
</script>

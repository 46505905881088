<template>
    <div id="ws-back-mobile" class="uk-padding">
        <a v-if="showBackButton" class="uk-link-text" @click="doBackLink"><i class="fal fa-angle-left"></i>&nbsp;{{ $t('text.back-link') }}</a>
    </div>
    <div v-if="navigationData.currentNavLabel" class="uk-padding uk-padding-remove-bottom">
        <h1>{{ navigationData.currentNavLabel }}</h1>
    </div>
    <nav>
        <table class="uk-table uk-table-divider">
            <tbody>
            <tr v-for="page in navigationData.pages">
                <td class="uk-width-auto uk-cover-container uk-padding-remove-right">
                    <div style="min-width: 30px" v-if="page.imageHtml" v-html="page.imageHtml"></div>
                    <a class="uk-link-text uk-position-cover" @click="doLink(page)"></a>
                </td>
                <td class="uk-width-expand uk-text-uppercase uk-cover-container">
                    {{ page.label }}
                    <a class="uk-link-text uk-position-cover" @click="doLink(page)"></a>
                </td>
                <td style="min-width: 20px" class="uk-cover-container">
                    <i class="fal fa-angle-right"></i>
                    <a class="uk-link-text uk-position-cover" @click="doLink(page)"></a>
                </td>
            </tr>
            </tbody>
        </table>
    </nav>
</template>

<script>
import ApiService from "../../service/ApiService.js";

export default {
    name: "DynamicNavigation",
    data() {
        return {
            navigationData: {},
            showBackButton: false
        }
    },
    created() {
        this.loadNavigationData({rid: this.rid})
    },
    methods: {
        loadNavigationData(params) {
            return ApiService.getNavigationData(params).then((response) => {
                this.navigationData = response.data
            })
        },
        doLink(page) {
            if (page.hasCategories) {
                this.loadNavigationData({rid: this.rid, pid: page.id}).then(() => {
                    this.showBackButton = true;
                })

            } else {
                window.location = page.url;
            }
        },
        doBackLink() {
            this.loadNavigationData({rid: this.rid}).then(() => {
                this.showBackButton = false;
            })
        }
    }
}
</script>

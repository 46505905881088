<template>

    <div>

        <div v-for="item in this.itemList" :key="item.Id">


            <div class="uk-grid-medium" uk-grid>

                <div class="uk-width-1-1 uk-width-auto@s">
                    <a :href="item.DetailUrl" class="uk-link-reset" v-html="item.ListImageHtml">
                    </a>
                </div>

                <div class="uk-width-1-1 uk-width-expand@s">

                    <div class="uk-grid-small" uk-grid>

                        <div class="uk-width-1-1 uk-width-2-3@s">

                            <a :href="item.DetailUrl" class="uk-link-reset">
                                <h4>{{ item.Bezeichnung }}</h4>
                                <p>{{ item.Artikelnummer }}</p>
                                <p>{{item.GewichtBrutto }}</p>
                            </a>
                        </div>

                        <div class="uk-width-1-1 uk-width-1-3@s">
                            <div class="uk-text-right@s uk-margin-small-bottom">
                                <span class="ws-price-reduced">{{ item.PriceString }}</span> *
                            </div>
                            <div class="ws-price-default uk-text-right@s">{{this.CartStore.cartData.ZeroPriceString}}</div>
                        </div>
                    </div>
                    <div class="uk-grid-small uk-margin-small-bottom" uk-grid>
                        <BaseButton
                            :class="'uk-button uk-button-default uk-margin-small-bottom uk-width-1-1'"
                            :href="'#'"
                            :disabled="loading[item.Id]"
                            :loading="loading[item.Id]"
                            @click.prevent="removeGiftItem(item.Id)"
                        >{{ $t('text.availablegiftitems.remove') }}</BaseButton>
                    </div>
                </div>
            </div>

        </div>

        <div v-if="this.itemList === undefined" class="uk-text-center">
            <div uk-spinner="ratio: 3"></div>
        </div>

        <hr v-else>

    </div>

</template>

<script>
import {useCartStore} from "../../store/CartStore.js";
import BaseButton from "../form/base/BaseButton.vue";

export default {
    name: "GiftItemList",
    components: {BaseButton},
    setup() {
        const CartStore = useCartStore()

        return {
            CartStore
        }
    },
    data() {
        return {
            loading: {}
        }
    },
    props: {
        itemList: {
            type: Array,
            required: true
        }
    },
    methods: {
        async removeGiftItem(item) {
            this.loading[item] = true;
            const response = await this.CartStore.removeGiftItemFromCart(item)
            delete this.loading[item]
        }
    }
}
</script>

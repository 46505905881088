export default {
    data() {
        return {
            /**
             * Reference the html input ID in which you want to load the autocomplete widget
             */
            googleAutocompleteInput: '',
            googleAutocompleteAddress: {
                street: null,
                streetNumber: null,
                city: null,
                zip: null,
                countryCode: null,
            },
            googleAutocompleteService: null,
            googleMapsError: false
        }
    },
    mounted() {
        document.addEventListener('GoogleMapsLoaded', this.initGoogleAutocompleteService);
    },
    beforeDestroy() {
        document.removeEventListener('GoogleMapsLoaded', this.initGoogleAutocompleteService);
    },
    computed: {
        /**
         * To reference you country code property of you component
         */
        googleAutocompleteSelectedCountryCode() {
            throw new Error('googleAutocompleteSelectedCountryCode not implemented');
        },
        googelAutocompleteAvailable() {
            return !!this.googleAutocompleteService;
        },
    },
    methods: {
        /**
         * Use to init the autocomplete widget.
         */
        initGoogleAutocompleteService() {

            if (typeof google === 'undefined' || !google.maps) {
                console.error('Google Maps API is not loaded');
                return;
            }

            const inputElement = document.getElementById(this.googleAutocompleteInput);

            if (!inputElement) {
                return;
            }

            if (window.googleMapsError) {
                console.log('Google Maps API failed to load.');
                return;
            }

            try {
                this.googleAutocompleteService = new window.google.maps.places.Autocomplete(
                    inputElement,
                    {
                        types: ['address'],
                        fields: ['address_components']
                    }
                );

                if (this.googleAutocompleteSelectedCountryCode) {
                    this.googleAutocompleteService.setComponentRestrictions({country: [this.googleAutocompleteSelectedCountryCode.toUpperCase()]});
                }

                this.googleAutocompleteService.addListener("place_changed", this.googleAutocompleteGetAddressComponents);
            } catch (e) {
                //Covers the case of a wrong/none exiting api key or unavailable of the Google API.
                console.log(e)
                this.googleAutocompleteService = null;
            }
        },
        /**
         * Implement this function to clear you address form when a new address is selected.
         * If you don't need clear anything, implement the function without logic inside.
         */
        googleAutocompleteClearAddressForm() {
            throw new Error('googleAutocompleteClearAddressForm not implemented');
        },
        /**
         * Implement this function to update the address properties of you component when a new address is selected.
         */
        googleAutocompleteUpdateAddress() {
            throw new Error('googleAutocompleteUpdateAddress not implemented');
        },
        googleAutocompleteGetAddressComponents() {
            this.googleAutocompleteClearAddressForm();
            this.googleAutocompleteClearResult();

            let place = {
                address_components: [],
                ...this.googleAutocompleteService.getPlace()
            }

            place.address_components.forEach((component) => {
                component.types.forEach((type) => {
                    switch (type) {
                        case 'route':
                            this.googleAutocompleteAddress.street = component.long_name;
                            break;
                        case 'street_number':
                            this.googleAutocompleteAddress.streetNumber = component.long_name;
                            break;
                        case 'locality':
                            this.googleAutocompleteAddress.city = component.long_name;
                            break;
                        case 'postal_code':
                            this.googleAutocompleteAddress.zip = component.long_name;
                            break;
                        case 'country':
                            this.googleAutocompleteAddress.countryCode = component.short_name;
                            break;
                    }
                })
            })

            this.googleAutocompleteUpdateAddress();
        },

        /**
         * Use this function to change the country code filter in the Google autocomplete.
         */
        googleAutocompleteChangeCountryCode(countryCode) {
            this.googleAutocompleteService.setComponentRestrictions({country: [countryCode.toUpperCase()]});
        },
        googleAutocompleteClearResult() {
            this.googleAutocompleteAddress.street = null;
            this.googleAutocompleteAddress.streetNumber = null;
            this.googleAutocompleteAddress.city = null;
            this.googleAutocompleteAddress.zip = null;
            this.googleAutocompleteAddress.countryCode = null;
        }
    },
}
import {defineStore} from "pinia"
import ApiService from "../service/ApiService"

export const useUserStore = defineStore('UserStore', {
    state: () => ({
        userData: {
            loggedIn: false,
            deliveryAddressDiffersFromMain: 'no'
        },
        userRegisterOptions: {
            gender: [],
            customerType: [],
            country: []
        },
        userRegisterData: {
            customerType: '',
            gender: '',
            firstname: '',
            lastname: '',
            email: '',
            emailConfirm: '',
            password: '',
            passwordConfirm: '',
            phone: '',
            zip: '',
            city: '',
            street: '',
            streetNumber: '',
            country: '',
            company: '',
            department: '',
            vatId: ''
        },
        userAddresses: [],
        loginErrorMessage: '',
        isLoading: false,
        newsletter: null
    }),
    actions: {
        fetchUserData() {
            this.isLoading = true
            ApiService.getNewsletterStatus().then(response =>  this.newsletter = response.data?? null)
            return ApiService.getUserData()
                .then((response) => {
                    if (response.data.success && response.data.user) {
                        this.userData = response.data.user
                    }
                }).finally(() => {
                    this.isLoading = false
                })
        },
        fetchNewsletterStatus()
        {
            ApiService.getNewsletterStatus().then(response =>  this.newsletter = response.data?? null)
        },
        fetchRegisterData() {
            this.isLoading = true
            return ApiService.getUserRegisterData()
                .then((response) => {

                    if (
                        response.data.success === true
                        && response.data.hasOwnProperty('data')
                        && response.data.data.hasOwnProperty('gender')
                        && response.data.data.hasOwnProperty('country')
                    ) {
                        this.userRegisterOptions = response.data.data
                    }

                    return response

                }).finally(() => {
                    this.isLoading = false
                })
        },
        loginUser(payload) {
            this.isLoading = true
            return ApiService.loginUser(payload.email, payload.password, payload.redirecturl)
                .then((response) => {
                    if (response.data.success) {
                        this.userData = response.data
                        this.loggedIn = true
                    } else {
                        this.loginErrorMessage = 'text.' + response.data.statusText
                    }
                    return response
                }).finally(() => {
                    this.isLoading = false
                })
        },
        registerUser(payload) {
            this.isLoading = true
            return ApiService.registerUser(payload)
                .then((response) => {
                    this.userData = response.data
                    this.loggedIn = true
                    return response
                }).finally(() => {
                    this.isLoading = false
                })
        },
        fetchUserAddresses() {
            this.isLoading = true
            return ApiService.fetchUserAddresses()
                .then((response) => {
                    this.userAddresses = response.data
                })
                .finally(() => {
                    this.isLoading = false
                })
        },
        removeUserAddress(addressIndex) {
            delete this.userAddresses[addressIndex]
        },
        deleteUserAddress(index) {
            this.isLoading = true
            return ApiService.deleteUserAddress(index)
            .then((response) => {
                this.userAddresses = response.data
              })
                .finally(() => {
                    this.isLoading = false
                })
        },
        saveUserAddresses() {
            this.isLoading = true
            return ApiService.sendUserAddresses()
                .then((response) => {
                    this.userAddresses = response.data
                })
                .finally(() => {
                    this.isLoading = false
                })
        },
        saveUserAddress(payload) {
            this.isLoading = true
            return ApiService.saveUserAddress(payload)
            .then((response) => {
                this.userAddresses = response.data
              })
            .finally(() => {
                  this.isLoading = false
              })
        },
        setPaymentPreference(paymentId) {
            let payload = {
                paymentId: paymentId
            }
            this.isLoading = true
            return ApiService.sendPaymentPreference(payload)
                .then((response) => {
                    this.userData.PreferredPayment = paymentId
                })
                .finally(() => {
                    this.isLoading = false
                })
        },
        setDeliveryAddressSelection(selection) {
            this.isLoading = true
            return ApiService.setUserDeliveryAddressSelection(selection)
                .then((response) => {
                    //@todo Response success noch auswerten
                    this.userData.deliveryAddressDiffersFromMain = selection
                })
                .finally(() => {
                    this.isLoading = false
                })
        },
        subscribeNewsletter() {
            this.isLoading = true
            return ApiService.subscribeNewsletter()
                .finally(() => this.isLoading = false)
        },
        unsubscribeNewsletter() {
            this.isLoading = true
            return ApiService.unsubscribeNewsletter()
                .finally(() => this.isLoading = false)
        },
        requestAccountBalance(){
            this.isLoading = true
            return ApiService.requestAccountBalance()
                .finally(() => this.isLoading = false)
        }
    },
    getters: {
        isLoggedIn(state) {
            return (state.userData.loggedIn)
        },
        getFirstName(state) {
            return (state.userData.Firstname)
        },
        getLastName(state) {
            return (state.userData.Lastname)
        },
        getGender(state) {
            return (state.userData.Gender)
        },
        getCustomerNumber(state) {
            return (state.userData.CustomerNumber)
        },
        getLastOrder(state) {
            return (state.userData.LastOrder)?? {}
        },
        getVatId(state) {
            return (state.userData.VatId)
        },
        getVatIdValid(state) {
            return (state.userData.VatIdValid)
        },
        getCustomerType(state) {
            return (state.userData.CustomerType)
        }
    }
})
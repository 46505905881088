<template>
    <div v-if="!vatIdValid && userStore.getCustomerType === UserConstants.CustomerTypeBusinessKey" uk-alert class="uk-alert-warning">
        <a href class="uk-alert-close" uk-close></a>
        <p>{{ $t('text.error.vat_id_invalid') }}: {{ vatId }}</p>
        <p>{{ $t('text.error.invalid_vatid_info_message') }}</p>
    </div>
</template>

<script>
import {useUserStore} from "../../store/UserStore";
import {useCheckoutStore} from "../../store/CheckoutStore";
import UserConstants from "../../constants/userConstants";

export default {
    name: "VatStatus",
    data() {
        return {
            userStore: useUserStore(),
            checkoutStore: useCheckoutStore()
        }
    },
    created() {
        this.userStore.fetchUserData();
    },
    computed: {
        UserConstants() {
            return UserConstants
        },
        vatIdValid() {
            return this.checkoutStore.checkoutData.steps.address.data.vatIdValid ?? this.userStore.getVatIdValid;
        },
        vatId() {
            return this.checkoutStore.checkoutData.steps.address.data.vatId ?? this.userStore.getVatId;
        }
    }
}
</script>
<template>
    <div :class="$attrs.class">
        <input
            type="checkbox"
            :checked="modelValue"
            @change="$emit('update:modelValue', $event.target.checked)"
            :class="'uk-checkbox uk-margin-small-right ws-checkbox ' + inputClass"
            :id="uuid"
        />
        <label
            v-if="label"
            v-html="label"
            :for="uuid"
            :class="'ws-checkbox-label ' + labelClass"
        ></label>
    </div>
    <p
        class="ws-checkout-error-field uk-text-danger"
        v-if="errorMessage"
        :id="uuid + '-error'"
        aria-live="assertive"
    >
        {{ $t(errorMessage) }}
    </p>
</template>

<script>
    import UniqueID from "../../../includes/UniqueID";

    export default {
        name: 'BaseCheckbox',
        props: {
            label: {
                type: String,
                default: '',
            },
            modelValue: {
                type: Boolean,
                default: false,
            },
            errorMessage: {
                type: String,
                default: '',
            },
            inputClass: {
                type: String,
                default: '',
            },
            labelClass: {
                type: String,
                default: '',
            }
        },
        setup() {
            const uuid = UniqueID().getID()
            return {
                uuid
            }
        }
    };
</script>

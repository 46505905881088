<template>
    <div class="user-app-wishlist">

        <div>
            <h1 class="ws-text-sauterblau">{{ $t('text.user.profile.wishlist.headline') }}</h1>

            <p>{{ $t('text.user.profile.wishlist.introduction') }}</p>

            <div uk-grid>

                <div class="uk-width-expand"></div>

                <div class="uk-width-auto">
                    <a @click.prevent="switchToListMode()">
                        <i class="fa-solid fa-bars fa-xl" :class="[isListModeActive() ? '' : 'ws-text-mittelgrau']"></i>
                    </a>
                </div>

                <div class="uk-width-auto">
                    <a @click.prevent="switchToCardMode()">
                        <i class="fa-solid fa-grid-2 fa-xl" :class="[isCardModeActive() ? '' : 'ws-text-mittelgrau']"></i>
                    </a>
                </div>

            </div>


            <div v-if="isListModeActive()">

                <WishlistSingleItemListView
                    v-for="wishlistItem in getWishlist()"
                    :wishlist-item="wishlistItem"
                    @wishlist-item-removed="removeItem()"
                ></WishlistSingleItemListView>

            </div>

            <div v-else-if="isCardModeActive()">
                <div class="ws-article-cards uk-grid-small uk-child-width-1-1 uk-child-width-1-4@s uk-grid-match uk-margin-top" uk-grid uk-height-match="target: > div .uk-card-body">

                    <WishlistSingleItemCardView
                        v-for="wishlistItem in getWishlist()"
                        :wishlist-item="wishlistItem"
                        @wishlist-item-removed="removeItem()"
                    ></WishlistSingleItemCardView>

                </div>
            </div>

        </div>

    </div>

</template>

<script>

import {useUserProfileStore} from "../../../store/UserProfileStore"
import {useCartStore} from "../../../store/CartStore";
import BaseButton from "../../form/base/BaseButton.vue";
import WishlistButton from "../../cart/WishlistButton.vue";
import WishlistSingleItemListView from "./wishlist/SingleItemListView.vue";
import WishlistSingleItemCardView from "./wishlist/SingleItemCardView.vue";

export default {
    name: 'Wishlist',
    components: {
        WishlistSingleItemCardView,
        WishlistSingleItemListView,
        WishlistButton,
        BaseButton
    },
    data() {
        return {
            page: 1,
            viewMode: 'list',
            viewModeList: 'list',
            viewModeCard: 'card',
            userProfileStore: useUserProfileStore(),
            cartStore: useCartStore()
        }
    },
    beforeMount() {
        this.cartStore.fetchCartData({}, 'wishlist')
    },
    methods: {
        getWishlist() {
            if (this.cartStore.wishlistData.Items) {
                return Object.values(this.cartStore.wishlistData.Items)
            }

            return {}
        },
        switchToCardMode() {
            this.viewMode = this.viewModeCard
        },
        switchToListMode() {
            this.viewMode = this.viewModeList
        },
        isListModeActive() {
            return this.viewMode === this.viewModeList
        },
        isCardModeActive() {
            return this.viewMode === this.viewModeCard
        },

    }
};

</script>

<template>
    <div class="user-app-order-list">

        <div v-if="showOrderDetails()">
            <OrderDetails :order="userProfileStore.orderList.items[userProfileStore.currentOrderId]"></OrderDetails>
        </div>

        <div v-else>
            <h1 class="ws-text-sauterblau">{{ $t('text.user.profile.order-list.headline') }}</h1>

            <p>{{ $t('text.user.profile.order.introduction') }}</p>

            <p>{{ $t('text.user.profile.your-customer-number') }}: <span class="uk-text-bold">{{ userStore.getCustomerNumber }}</span></p>

            <Order v-for="singleOrder in getOrderList()" :order="singleOrder"></Order>

            <div class="uk-grid-medium" uk-grid>

                <div class="uk-width-1-2">
                    <BaseButton
                        v-if="(page > 1)"
                        :full-width="true"
                        :href="'#'"
                        @click.prevent="loadPreviousPage()"
                    >
                        Zurück
                    </BaseButton>
                </div>

                <div  class="uk-width-1-2">
                    <BaseButton
                        v-if="(page < userProfileStore.orderList.pageCount)"
                        :full-width="true"
                        :href="'#'"
                        @click.prevent="loadNextPage()"
                    >
                        Weiter
                    </BaseButton>
                </div>

            </div>

        </div>

        <Contact />

    </div>

</template>

<script>

import {useUserStore} from "../../../store/UserStore"
import {useUserProfileStore} from "../../../store/UserProfileStore"
import Order from './order/Order.vue'
import BaseButton from "../../form/base/BaseButton.vue";
import Contact from "./partials/Contact.vue";
import OrderDetails from "./order/OrderDetails.vue";

export default {
    name: 'OrderList',
    components: {OrderDetails, Contact, BaseButton, Order},

    data() {
        return {
            page: 1,
        }
    },

    setup() {

        const userStore = useUserStore()
        const userProfileStore = useUserProfileStore()

        return {
            userStore,
            userProfileStore
        }

    },
    beforeMount() {
        this.userProfileStore.loadOrderList()
    },

    methods: {

        loadNextPage() {
            this.userProfileStore.loadOrderList({'page': (this.page)})
            this.page++
        },

        loadPreviousPage() {
            this.userProfileStore.loadOrderList({'page': (this.page - 2)})
            this.page--
        },

        showOrderDetails() {
            return this.userProfileStore.currentOrderId > 0
        },

        getOrderList() {

            if(this.userProfileStore.orderList.items) {
                return Object.values(this.userProfileStore.orderList.items).reverse()
            }

            return []

        }

    }
};

</script>

<template>

    <div v-if="stepIsActive">

        <div v-if="!CheckoutStore.checkoutData.steps.address.data.customer" class="uk-background-muted uk-padding">

            <h3 class="ws-checkout-step-active">{{ $t('text.checkout.sub-headline.create-account-now') }}</h3>

            <div class="uk-margin-large-top uk-margin-medium-bottom" v-html="$t('text.checkout.create-account.description')"></div>

            <BaseButton href="#" @click.prevent="showCreateAccountForm = true" :full-width="true" v-if="!showCreateAccountForm">{{ $t('text.checkout.create-account.button-label') }}</BaseButton>

            <p v-if="!showCreateAccountForm" v-html="$t('text.checkout.create-account.hint')"></p>

            <div v-if="showCreateAccountForm">

                <p
                    class="ws-checkout-error-field uk-text-danger"
                    v-if="retrieveError('checkout_step_confirmation')"
                    :id="uuid + '-error'"
                    aria-live="assertive"
                >
                    {{ $t(retrieveError('checkout_step_confirmation')) }}
                </p>

                <div>
                    <FloatingLabelInput
                        :label="$t('text.checkout.input.password')"
                        :error-message="retrieveError('password')"
                        v-model="CheckoutStore.checkoutData.steps.confirmation.data.password"
                        type="password"
                    />
                </div>
                <div>
                    <FloatingLabelInput
                        :label="$t('text.checkout.input.password-confirm')"
                        :error-message="retrieveError('passwordConfirm')"
                        v-model="CheckoutStore.checkoutData.steps.confirmation.data.passwordConfirm"
                        type="password"
                    />
                </div>

                <p>{{ $t('text.checkout.create-account.creation-hint')}}</p>

            </div>

        </div>

        <hr class="uk-margin-medium-top uk-margin-medium-bottom">

        <div uk-grid>
            <div class="uk-width-auto"><span v-html="catalogueImage"></span></div>
            <BaseCheckbox
                :label="$t('text.checkout.input.get-catalogue')"
                :error-message="retrieveError('getCatalogue')"
                v-model="CheckoutStore.checkoutData.steps.confirmation.data.getCatalogue"
                class="uk-width-expand"
            />
        </div>

        <hr class="uk-margin-medium-top uk-margin-medium-bottom">

        <div>
            <BaseTextarea
                :placeholder="$t('text.checkout.input.comment')"
                :error-message="retrieveError('comment')"
                v-model="CheckoutStore.checkoutData.steps.confirmation.data.comment"
                type="input"
            />
        </div>

        <p v-html="$t('text.checkout.revocation-hint')"></p>

        <div>
            <BaseCheckbox
                :label="$t('text.checkout.input.terms-accepted')"
                :error-message="retrieveError('termsAccepted')"
                v-model="CheckoutStore.checkoutData.steps.confirmation.data.termsAccepted"
            />
        </div>

        <div
            class="ws-checkout-error-general uk-text-danger"
            v-if="CheckoutStore.checkoutData.error.general && (CheckoutStore.checkoutData.current === this.stepName)"
        >
            {{ $t(errorMessage) }}
        </div>

        <BaseButton
            :full-width="true"
            :href="'#'"
            @click.prevent="sendForm()"
            class="uk-margin-medium-top"
            :id="'orderConfirmButton'"
        >
            {{ $t('text.checkout.button.buy-now') }}
        </BaseButton>

    </div>


</template>

<script>
import {useCheckoutStore} from "../../../store/CheckoutStore.js";
import BaseButton from "../../form/base/BaseButton.vue";
import BaseCheckbox from "../../form/base/BaseCheckbox.vue";
import BaseTextarea from "../../form/base/BaseTextarea.vue";
import FloatingLabelInput from "../../form/base/FloatingLabelInput.vue";
import TrackingService, {TrackingCheckoutStepCode} from "../../../service/TrackingService";

export default {
    name: "Confirmation",
    data() {
        return {
            showCreateAccountForm: false,
            CheckoutStore: useCheckoutStore(),
            stepName: 'confirmation'
        }
    },
    components: {
        BaseButton,
        BaseCheckbox,
        BaseTextarea,
        FloatingLabelInput,
    },
    props: {
        stepIsActive: {
            type: Boolean,
            required: true,
        },
        catalogueImage: {
            type: String,
            required: true,
        },
        submitOrder: {
            type: Boolean,
            required: false,
        }
    },
    watch: {
        submitOrder(newVal, oldVal) {
            if (newVal) {
                this.sendForm()
            }
        }
    },
    computed: {
        errorMessage() {
            return ('text.checkout.payment.error.'+this.CheckoutStore.checkoutData.error.general).trim().toLowerCase()
        }
    },
    methods: {
        sendForm() {
            this.$parent.showSpinner(this.$t('text.loading-spinner.commit-order'), true)
            TrackingService.trackCheckoutByStep(TrackingCheckoutStepCode.sendOder);
            this.CheckoutStore.getToken(this.stepName).then(
                async (result) => {
                    if (result.hasOwnProperty('data') && result.data.hasOwnProperty('token')) {
                        await this.CheckoutStore.sendCheckoutData({ step: this.stepName, token: result.data.token })
                    }
                    this.$parent.hideSpinner()
                }
            )
        },
        retrieveError(field) {
            if (this.CheckoutStore.checkoutData.error.confirmation && this.CheckoutStore.checkoutData.error.confirmation[field]) {
                this.$parent.hideSpinner()
                return this.CheckoutStore.checkoutData.error.confirmation[field]
            }

            return '';
        },
    }
}
</script>

<template>
    <Header :headline="$t('text.user.profile.personal-data.headline')" />
    <div v-if="isInEditMode()">
        <form v-if="isEditMode('customer-data')">
            <PersonalForm />
        </form>
        <form v-else-if="isEditMode('password')">
            <PasswordForm />
        </form>
    </div>
    <div class="uk-margin-large-top" v-else>
        <SubHeader :headerTest="$t('text.user.profile.personal-data.profile-header')"/>
        <div class="uk-child-width-1-1 uk-child-width-1-4@s" uk-grid>
            <div>
                <div class="uk-card uk-card-body uk-card-default">
                    <h3>{{ $t('text.user.profile.personal-data.customer-data.headline') }}</h3>
                    <p>
                        {{ $t('text.customer.salutation.' + userData('Gender')) }} {{ userData('Firstname') }} {{ userData('Lastname') }}<br>
                        {{ userData('Email') }}<br>
                        {{ userData('Phone') }}
                    </p>
                    <a
                        href="#"
                        @click.prevent="edit('customer-data')"
                    >
                        {{ $t('text.user.profile.personal-data.edit-link') }}
                    </a>
                </div>
            </div>
            <div>
                <div class="uk-card uk-card-body uk-card-default">
                    <h3>{{ $t('text.user.profile.personal-data.password.headline') }}?</h3>
                    <p>{{ $t('text.user.profile.personal-data.password.description') }}</p>
                    <a
                        href="#"
                        @click.prevent="edit('password')"
                        class="uk-margin-large-top uk-text-decoration-none uk-link"
                    >
                        {{ $t('text.user.profile.personal-data.edit-link') }}
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import { useUserStore } from "../../../store/UserStore";
import { useUserProfileStore } from "../../../store/UserProfileStore";
import BaseInput from "../../form/base/BaseTextarea.vue";
import FloatingLabelInput from "../../form/base/FloatingLabelInput.vue";
import BaseButton from "../../form/base/BaseButton.vue";
import PasswordForm from "./partials/PasswordForm.vue";
import PersonalForm from "./partials/PersonalForm.vue";
import SubHeader from "./partials/SubHeader.vue";
import Header from "./partials/Header.vue";

export default {
    name: 'PersonalData',
    components: {Header, SubHeader, PersonalForm, PasswordForm, BaseButton, FloatingLabelInput, BaseInput},
    setup() {

        const userStore = useUserStore()
        const userProfileStore = useUserProfileStore()

        return {
            userStore,
            userProfileStore
        }
    },
    methods: {
        isInEditMode() {
            return this.userProfileStore.personalDataEditModeActive
        },
        isEditMode(editMode) {
            return this.userProfileStore.personalDataEditMode === editMode
        },
        userData(key) {
            return this.userStore.userData[key] ?? ''
        },
        edit(editMode) {
            this.userProfileStore.setPersonalDataEditMode(editMode)
        }
    }
};

</script>

<template>
    <div class="uk-margin-large-bottom" uk-grid>
        <h2
            class="uk-width-expand"
            :class="[{'ws-checkout-step-active':(currentStep === expectedStep)}, {'ws-checkout-step-unfilled': (currentStep !== expectedStep && !showEditLink)}]"
        >
            <slot></slot>
        </h2>
        <a
            class="uk-display-block uk-width-auto"
            @click="showStep(expectedStep)"
            v-if="showEditLink && (currentStep !== expectedStep)"
        >
            <i class="fa fa-light fa-pencil"></i> {{ $t('text.checkout.edit-step-link') }}
        </a>
    </div>
</template>

<script>

let headingCount = 0;

import {useCheckoutStore} from "../../store/CheckoutStore.js"

export default {
    name: "StepHeading",
    props: {
        currentStep: {
            type:     String,
            required: true
        },
        expectedStep: {
            type:     String,
            required: true
        },
        showEditLink: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        showStep(step) {
            this.CheckoutStore.setCheckoutStep({step: step})
        },
    },
    setup() {
        const CheckoutStore = useCheckoutStore()
        return {
            CheckoutStore
        }
    }
}
</script>

<template>
    <div class="ws-hint-box">
        <div class="uk-grid-medium" uk-grid>
            <div class="uk-width-auto ws-hintbox-icon"><i :class="iconCss"></i></div>
            <div class="uk-width-expand ws-hint-box-content" v-html="this.content">
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "HintBox",
    props: {
        icon: {
            type: String,
            default: 'fa-square-check'
        },
        content: {
            type: String,
            default: ''
        }
    },
    computed: {
        iconCss() {
            return 'fa-solid ' + this.icon
        }
    }
}
</script>

<template>
    <div class="ws-voucher">
        <div class="uk-margin-medium-bottom" v-show="hasActiveVoucherCodes">
            <h3>{{ $t('text.active-voucher-codes')}}</h3>
            <div class="uk-grid-medium" v-for="item in this.voucherList" uk-grid>
                <div class="uk-width-expand">{{ item.token }}</div>
                <div class="uk-width-auto ws-cursor-pointer" :uk-tooltip="$t('text.remove-cart-voucher')" @click="removeVoucherCode(item.token)"><i class="fal fa-trash-alt"></i></div>
            </div>
        </div>
        <div>
            <FloatingLabelInput
                type="input"
                @keyup="updateGui"
                v-model="voucherCode"
                :label="$t('text.voucher-code')"
                :required="false"
                :inputClass="'uk-background-muted'"
            ></FloatingLabelInput>
        </div>
        <div v-show="hasError">
            <div class="uk-alert-danger" uk-alert>
                <a class="uk-alert-close" uk-close></a>
                <p>{{ errorMessage }}</p>
            </div>
        </div>
        <div v-show="isActive" class="uk-margin-small-top">
            <BaseButton :full-width="true" @click="applyVoucherCode">{{ $t('text.apply-voucher-code') }}</BaseButton>
        </div>
    </div>
</template>

<script>
import BaseButton from "../form/base/BaseButton.vue";
import FloatingLabelInput from "../form/base/FloatingLabelInput.vue";
import ApiService from "../../service/ApiService";
import {useCartStore} from "../../store/CartStore";

export default {
    name: "Voucher",
    components: {
        FloatingLabelInput,
        BaseButton
    },
    props: {
        cart: {
            type: Object,
            required: true
        }
    },
    setup() {
        const cartStore = useCartStore()
        return {
            cartStore
        }
    },
    data() {
        return {
            voucherCode: '',
            isActive: false,
            errorMessage: ''
        }
    },
    methods: {
        updateGui() {
            this.isActive = this.voucherCode.length > 5
        },
        applyVoucherCode() {
            this.errorMessage = ''
            this.loadingSpinner.show(this.$t('text.apply-voucher-to-cart'))
            ApiService.applyVoucherCode(this.voucherCode).then((response) => {
                if(response.data.success) {
                    this.voucherCode = ''
                    this.isActive = false
                    this.cartStore.fetchCartData().then(() => {
                        this.loadingSpinner.hide()
                    })
                } else {
                    this.errorMessage = response.data.message
                    this.loadingSpinner.hide()
                }
            })
        },
        removeVoucherCode(token) {
            this.loadingSpinner.show(this.$t('text.remove-voucher-from-cart'))
            ApiService.removeVoucherCode(token).then((response) => {
                if(response.data.success) {
                    this.cartStore.fetchCartData().then(() => {
                        this.loadingSpinner.hide()
                    })
                } else {
                    this.errorMessage = response.data.message
                    this.loadingSpinner.hide()
                }
            })
        }
    },
    computed: {
        hasError() {
            return this.errorMessage.length > 0
        },
        hasActiveVoucherCodes() {
            if(!this.cartStore.cartData.voucherTokenList) {
                return false
            }
            return this.cartStore.cartData.voucherTokenList.length > 0
        },
        voucherList() {
            return this.cartStore.cartData.voucherTokenList
        }
    }
}
</script>

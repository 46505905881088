<template>
    <h1 class="uk-text-primary">{{ $t('text.hallo') }} {{ userStore.getFirstName }} {{ userStore.getLastName }}</h1>
    {{ $t('text.user.profile.welcome-text') }}
    <p>{{ $t('text.user.profile.your-customer-number') }}: <span class="uk-text-bold">{{
            userStore.getCustomerNumber
        }}</span>
    </p>
    <div class="component-grid uk-child-width-1-2 uk-child-width-1-4@s uk-margin-medium-top" uk-grid>
        <div
            class="uk-height-small uk-text-center uk-link uk-text-secondary uk-flex uk-flex-center uk-flex-middle"
            v-for="item in components"
            @click="userProfileStore.setCurrentTab(item.id, true)"
        >
            <div class="uk-card uk-card-body uk-flex uk-flex-center uk-flex-middle uk-height-1-1 uk-width-1-1">
                <div>
                    <i class="fa-light fa-3x" :class="item.icon"></i>
                    <p>
                        {{ $t("text.user.profile.navigation." + item.id) }}
                    </p>
                </div>
            </div>
        </div>
    </div>
    <LastOrder/>
    <ContactSection/>
</template>

<script>
import LastOrder from "./dashboard/LastOrder.vue";
import ContactSection from "./ContactSection.vue";

export default {
    name: "ComponentGrid",
    components: {
        LastOrder,
        ContactSection
    },
    props: {
        components: {
            type: Array,
            required: true
        },
        userStore: {
            type: Object,
            required: true
        },
        userProfileStore: {
            type: Object,
            required: true
        }
    }
}
</script>
<template>
    <div class="uk-margin-medium-bottom" :class="$attrs.class">
        <div class="ws-input-floating-label uk-padding-small uk-padding-remove-vertical ws-global-element-border" :class="{'error': !!errorMessage}">
            <input
                :type="$attrs.type"
                :placeholder="PlaceholderText"
                class="uk-input uk-margin-small-top uk-margin-small-bottom"
                :class="inputClass"
                :value="modelValue"
                @input="$emit('update:modelValue', $event.target.value)"
                :id="elementId"
                :aria-describedby="errorMessage ? elementId + '-error' : null"
                :aria-invalid="errorMessage ? true : null"
                :readonly="readOnly"
            >
            <label
                :for="elementId"
                class="uk-padding-small uk-padding-remove-vertical"
                v-if="label"
            >
                {{label}}<span v-if="required"><sup>*</sup></span>
            </label>
        </div>
        <p
            class="ws-checkout-error-field uk-text-danger"
            v-if="errorMessage"
            :id="elementId + '-error'"
            aria-live="assertive"
        >
            {{ $t(errorMessage) }}
        </p>
        <p
            class="uk-text-small uk-padding-small uk-padding-remove-horizontal uk-padding-remove-top"
            v-if="hint"
            v-html="hint"
        >
        </p>
    </div>
</template>

<script>
import UniqueID from "../../../includes/UniqueID.js";

export default {
    name: 'FloatingLabelInput',
    props: {
        label: {
            type: String,
            default: ''
        },
        placeholder: {
            type: String,
            default: ''
        },
        modelValue: {
            type: [String, Number],
            default: ''
        },
        required: {
            type: Boolean,
            default: true
        },
        hint: {
            type: String,
            default: ''
        },
        errorMessage: {
            type: String,
            default: ''
        },
        inputClass: {
            type: String,
            default: ''
        },
        readOnly: {
            type: Boolean,
            default: false
        },
        customElementId: {
            type: String,
            default: null,
            required: false,
        }
    },
    setup(props) {
        let elementId;

        if (props.customElementId) {
            elementId = props.customElementId;
        } else {
            elementId = UniqueID().getID()
        }

        return {
            elementId
        }
    },
    computed: {
        PlaceholderText() {
            let text = this.placeholder ? this.placeholder : this.label
            if(this.required) {
                text = text + '*'
            }
            return text
        }
    }
};
</script>

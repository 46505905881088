import {defineStore} from "pinia"
import ApiService from "../service/ApiService"

export const usePaymentStore = defineStore('PaymentStore', {
    state: () => ({
        paymentTypes: []
    }),
    actions: {
        fetchPaymentTypes(payload) {
            return ApiService.getPaymentTypes(payload)
                .then(response => {
                    this.paymentTypes = response.data
                    return response
                })
        }
    }
})
<template>


    <div v-if="!stepIsActive && stepIsFilled">

        <h3>
            {{ $t('text.checkout.sub-headline.delivery-type') }}
        </h3>

        <div v-for="option in CheckoutStore.checkoutData.steps.delivery.options.deliveryService">
            <div v-if="isSelectedDeliveryService(option.value)">
                <div
                    class="ws-selector ws-delivery-service-selector uk-margin-small-top uk-margin-small-bottom"
                    :class="{'selected': isSelectedDeliveryService(option.value)}"
                >
                    <div uk-grid class="uk-padding-small ws-delivery-option-title">

                        <div class="uk-width-expand" v-html="$t(option.name)"></div>
                        <div class="uk-width-auto">{{ option.price.asString }}</div>
                        <div class="uk-box-sizing-border ws-width-fixed ws-width-fixed-105" v-if="option.iconHtml" v-html="option.iconHtml"></div>

                    </div>
                    <div v-if="hasAdditionalText(option)" class="ws-delivery-option-text uk-background-muted uk-padding-small" v-html="option.additionalText"></div>
                </div>
            </div>
        </div>

        <div v-if="serviceHasAddressType(addressFormStandard)">

            <div v-if="CheckoutStore.checkoutData.steps.delivery.data.addressDiffers === 'yes'">
                <p v-html="$t('text.checkout.delivery.address-differs.yes')"></p>
                <p>
                    <span v-if="CheckoutStore.checkoutData.steps.delivery.data.gender !== 'diverse'">{{ $t('text.customer.salutation.' + CheckoutStore.checkoutData.steps.delivery.data.gender) }}</span>
                    {{ CheckoutStore.checkoutData.steps.delivery.data.firstName }}
                    {{ CheckoutStore.checkoutData.steps.delivery.data.lastName }}<br>
                    {{ CheckoutStore.checkoutData.steps.delivery.data.street }} {{ CheckoutStore.checkoutData.steps.delivery.data.streetNumber }}<br>
                    <span v-if="CheckoutStore.checkoutData.steps.delivery.data.streetExtra">{{ CheckoutStore.checkoutData.steps.delivery.data.streetExtra }}<br></span>
                    {{ CheckoutStore.checkoutData.steps.delivery.data.zip }} {{ CheckoutStore.checkoutData.steps.delivery.data.city }}<br>
                    {{ retrieveCountryName(CheckoutStore.checkoutData.steps.delivery.data.country) }}
                </p>
            </div>

            <div v-if="CheckoutStore.checkoutData.steps.delivery.data.addressDiffers === 'no'">
                <p v-html="$t('text.checkout.delivery.address-differs.no')"></p>
            </div>

        </div>

        <div v-if="serviceHasAddressType(addressFormPackstation)">

            <p v-html="$t('text.checkout.delivery.type.packstation')"></p>

            <p>
                <span v-if="CheckoutStore.checkoutData.steps.delivery.data.gender !== 'diverse'">{{ $t('text.customer.salutation.' + CheckoutStore.checkoutData.steps.delivery.data.gender) }}</span>
                {{ CheckoutStore.checkoutData.steps.delivery.data.firstName }}
                {{ CheckoutStore.checkoutData.steps.delivery.data.lastName }}<br>
                {{ CheckoutStore.checkoutData.steps.delivery.data.postnummer }}<br>
                {{ CheckoutStore.checkoutData.steps.delivery.data.zip }} {{ CheckoutStore.checkoutData.steps.delivery.data.city }}<br>
                {{ retrieveCountryName(CheckoutStore.checkoutData.steps.delivery.data.country) }}
            </p>

        </div>

    </div>

    <div v-if="stepIsActive">

<!--        <div v-if="serviceHasAddressType(addressFormStandard) || !CheckoutStore.checkoutData.steps.delivery.data.deliveryService">-->
        <div>
            <h3>{{ $t('text.checkout.sub-headline.delivery-type-standard') }}</h3>
            <BaseSwitch
                v-model="CheckoutStore.checkoutData.steps.delivery.data.addressDiffers"
                :label="CheckoutStore.checkoutData.steps.delivery.options.addressDiffers[0].name"
                :error-message="retrieveError('addressDiffers')"
                :horizontal="false"
                class="uk-margin-medium-bottom uk-hidden@m"
                v-bind="{
                    ...$attrs,
                    onChange: ($event) => { changeDeliveryAddressSelection($event.target) }
                }"
            />

            <BaseRadio
                v-model="CheckoutStore.checkoutData.steps.delivery.data.addressDiffers"
                :options="CheckoutStore.checkoutData.steps.delivery.options.addressDiffers"
                :error-message="retrieveError('addressDiffers')"
                :horizontal="false"
                class="uk-margin-medium-bottom uk-visible@m"
                v-bind="{
                    ...$attrs,
                    onChange: ($event) => { changeDeliveryAddressSelection($event.target) }
                }"
            />

            <div v-if="CheckoutStore.checkoutData.steps.delivery.data.addressDiffers === 'yes'">
                <AddressSection
                    v-if="CheckoutStore.checkoutData.isLoggedIn"
                    :highlighted-index="CheckoutStore.getSelectedAddressUuid"
                    :selectable="true"
                    @select-address="selectAddress"
                />
                <div v-else>

                    <BaseRadio
                        v-model="CheckoutStore.checkoutData.steps.delivery.data.gender"
                        :options="CheckoutStore.checkoutData.steps.delivery.options.gender"
                        :error-message="retrieveError('gender')"
                        class="uk-margin-small-bottom"
                    />

                    <FloatingLabelInput
                        :label="$t('text.checkout.input.firstname')"
                        :error-message="retrieveError('firstName')"
                        v-model="CheckoutStore.checkoutData.steps.delivery.data.firstName"
                        type="input"
                    />

                    <FloatingLabelInput
                        :label="$t('text.checkout.input.lastname')"
                        :error-message="retrieveError('lastName')"
                        v-model="CheckoutStore.checkoutData.steps.delivery.data.lastName"
                        type="input"
                    />

                    <FloatingLabelInput
                        :label="$t('text.checkout.input.company')"
                        :error-message="retrieveError('company')"
                        v-model="CheckoutStore.checkoutData.steps.delivery.data.company"
                        type="input"
                        :required="false"
                    />

                    <FloatingLabelInput
                        :label="$t('text.checkout.input.department')"
                        :error-message="retrieveError('department')"
                        v-model="CheckoutStore.checkoutData.steps.delivery.data.department"
                        type="input"
                        :required="false"
                    />

                    <DeliveryCountry
                        v-model="CheckoutStore.checkoutData.steps.delivery.data.country"
                        :options="CheckoutStore.checkoutData.steps.delivery.options.country"
                        :error-message="retrieveError('country')"
                    />

                    <div uk-grid class="uk-margin-remove-top">

                        <FloatingLabelInput
                            :label="$t('text.checkout.input.street')"
                            :error-message="retrieveError('street')"
                            :custom-element-id="googleAutocompleteInput"
                            @vue:mounted="initGoogleAutocompleteService"
                            v-model="CheckoutStore.checkoutData.steps.delivery.data.street"
                            type="input"
                            class="uk-width-1-1 uk-width-4-5@m"
                        />

                        <FloatingLabelInput
                            :label="$t('text.checkout.input.street-number')"
                            :error-message="retrieveError('streetNumber')"
                            v-model="CheckoutStore.checkoutData.steps.delivery.data.streetNumber"
                            type="input"
                            class="uk-width-1-1 uk-width-1-5@m uk-margin-remove-top"
                        />

                    </div>

                    <FloatingLabelInput
                        :label="$t('text.checkout.input.street-extra')"
                        :error-message="retrieveError('streetExtra')"
                        v-model="CheckoutStore.checkoutData.steps.delivery.data.streetExtra"
                        type="input"
                        :required="false"
                    />

                    <div uk-grid class="uk-margin-remove-top">

                        <FloatingLabelInput
                            :label="$t('text.checkout.input.zip')"
                            :error-message="retrieveError('zip')"
                            v-model="CheckoutStore.checkoutData.steps.delivery.data.zip"
                            type="input"
                            class="uk-width-1-1 uk-width-1-4@m"
                        />

                        <FloatingLabelInput
                            :label="$t('text.checkout.input.city')"
                            :error-message="retrieveError('city')"
                            v-model="CheckoutStore.checkoutData.steps.delivery.data.city"
                            type="input"
                            class="uk-width-1-1 uk-width-3-4@m uk-margin-remove-top"
                        />

                    </div>
                </div>

            </div>

        </div>

        <div v-if="serviceHasAddressType(addressFormPackstation) && !CheckoutStore.checkoutData.isLoggedIn">

            <h3>{{ $t('text.checkout.sub-headline.delivery-type-packstation') }}</h3>

            <div>
                <BaseRadio
                    v-model="CheckoutStore.checkoutData.steps.delivery.data.gender"
                    :options="CheckoutStore.checkoutData.steps.delivery.options.gender"
                    :error-message="retrieveError('gender')"
                    class="uk-margin-small-bottom"
                />
            </div>

            <div>
                <FloatingLabelInput
                    :label="$t('text.checkout.input.firstname')"
                    :error-message="retrieveError('firstName')"
                    v-model="CheckoutStore.checkoutData.steps.delivery.data.firstName"
                    type="input"
                />
            </div>

            <div>
                <FloatingLabelInput
                    :label="$t('text.checkout.input.lastname')"
                    :error-message="retrieveError('lastName')"
                    v-model="CheckoutStore.checkoutData.steps.delivery.data.lastName"
                    type="input"
                />
            </div>

            <div>
                <FloatingLabelInput
                    :label="$t('text.checkout.input.postnummer')"
                    :error-message="retrieveError('postnummer')"
                    v-model="CheckoutStore.checkoutData.steps.delivery.data.postnummer"
                    type="input"
                />
            </div>

            <DeliveryCountry
                v-model="CheckoutStore.checkoutData.steps.delivery.data.country"
                :options="CheckoutStore.checkoutData.steps.delivery.options.country"
                :error-message="retrieveError('country')"
            />

            <div>
                <DhlServicePointSelect></DhlServicePointSelect>
            </div>
        </div>

        <div
            class="ws-checkout-error-general uk-text-danger"
            v-if="CheckoutStore.checkoutData.error.general && (CheckoutStore.checkoutData.current === this.stepName)"
        >
            {{ CheckoutStore.checkoutData.error.general }}
        </div>

        <div class="uk-margin-large-top">
            <h3>{{ $t('text.checkout.sub-headline.delivery-service') }}</h3>
            <div
                v-for="option in CheckoutStore.checkoutData.steps.delivery.options.deliveryService"
                @click.prevent="selectDeliveryService(option.value)"
                class="ws-selector ws-delivery-service-selector uk-margin-small-top uk-margin-small-bottom"
                :class="{'selected': isSelectedDeliveryService(option.value)}"
            >
                <div uk-grid class="uk-padding-small ws-delivery-option-title">

                    <div class="uk-width-expand" v-html="$t(option.name)"></div>
                    <div class="uk-width-auto">{{ option.price.asString }}</div>
                    <div class="uk-box-sizing-border ws-width-fixed ws-width-fixed-105" v-if="option.iconHtml" v-html="option.iconHtml"></div>

                </div>
                <div v-if="isSelectedDeliveryService(option.value) && hasAdditionalText(option)" class="ws-delivery-option-text uk-background-muted uk-padding-small" v-html="option.additionalText"></div>
            </div>

        </div>

        <BaseButton
            :full-width="true"
            :href="'#'"
            @click.prevent="sendForm()"
            class="uk-margin-medium-top"
        >
            {{ $t(nextStepTranslationKey) }}
        </BaseButton>

    </div>

    <hr class="uk-margin-medium-top uk-margin-medium-bottom">


</template>

<script>
import {useCheckoutStore} from "../../../store/CheckoutStore.js"
import {useCartStore} from "../../../store/CartStore.js";
import {useUserStore} from "../../../store/UserStore.js";
import BaseCheckbox from "../../form/base/BaseCheckbox.vue";
import FloatingLabelInput from "../../form/base/FloatingLabelInput.vue";
import BaseRadio from "../../form/base/BaseRadio.vue";
import BaseSelect from "../../form/base/BaseSelect.vue";
import BaseButton from "../../form/base/BaseButton.vue";
import DeliveryCountry from "../../form/checkout/DeliveryCountry.vue";
import DhlServicePointSelect from "./delivery/DhlServicePointSelect.vue";
import AddressEditForm from "../../user/UserProfile/address/AddressEditForm.vue";
import AddressSection from "../../user/UserProfile/address/AddressSection.vue";
import googleAutocompleteMixin from "../../../mixins/googleAutocompleteMixin";
import BaseSwitch from "../../form/base/BaseSwitch.vue";

export default {
    name: "Delivery",
    mixins: [googleAutocompleteMixin],
    setup() {

        const CheckoutStore = useCheckoutStore()
        const CartStore = useCartStore()
        const UserStore = useUserStore()
        const stepName = 'delivery'


        const addressFormEmpty = 'empty'
        const addressFormStandard = 'standard'
        const addressFormPackstation = 'packstation'

        CheckoutStore.$subscribe((mutation, state) => {

            if (!mutation.hasOwnProperty('events') || mutation.events === undefined) {
                return
            }

            let key = mutation.events.key;
            let newValue = mutation.events.newValue;
            let oldValue = mutation.events.oldValue;

            // Nur aktiv werden, wenn entweder auf den Delivery-Step gewechselt wird oder vorher der Address-Step comittet wurde
            if (
                !(key === "current" && newValue === "delivery")
                && !(key === "checkoutData" && oldValue.current === "address")
                && !(key === "checkoutData" && newValue.current === "delivery")
            ) {
                return
            }

            const deliveryStep = state.checkoutData.steps.delivery
            const addressData = state.checkoutData.steps.address.data

            // Wenn wir schon Daten haben und diese eine abweichende Lieferadresse sind, machen wir nix
            if (deliveryStep.isFilled && deliveryStep.data.addressDiffers === "yes") {
                return
            }

            deliveryStep.data.gender = addressData.gender
            deliveryStep.data.firstName = addressData.firstName
            deliveryStep.data.lastName = addressData.lastName
            deliveryStep.data.company = addressData.company
            deliveryStep.data.department = addressData.department
            deliveryStep.data.street = addressData.street
            deliveryStep.data.streetNumber = addressData.streetNumber
            deliveryStep.data.streetExtra = addressData.streetExtra
            deliveryStep.data.zip = addressData.zip
            deliveryStep.data.city = addressData.city
            deliveryStep.data.country = addressData.country

        });

        return {
            CheckoutStore,
            CartStore,
            UserStore,
            addressFormEmpty,
            addressFormStandard,
            addressFormPackstation,
            stepName,
        }
    },
    components: {
        BaseSwitch,
        AddressSection,
        AddressEditForm,
        BaseButton,
        BaseCheckbox,
        BaseSelect,
        FloatingLabelInput,
        BaseRadio,
        DhlServicePointSelect,
        DeliveryCountry
    },
    props: {
        stepIsActive: {
            type: Boolean,
            required: true,
        },
        stepIsFilled: {
            type: Boolean,
            default: false,
        },
        nextStepTranslationKey: {
            type: String,
            default: '',
        }
    },
    data() {
        return {
            googleAutocompleteInput: 'autocomplete-input-delivery',
        }
    },
    computed: {
        googleAutocompleteSelectedCountryCode() {
            return this.CheckoutStore.checkoutData.steps.delivery.data.country;
        },
    },
    watch: {
        googleAutocompleteSelectedCountryCode(countryCode) {
            if (this.googelAutocompleteAvailable && countryCode) {
                this.googleAutocompleteChangeCountryCode(countryCode)
            }
        }
    },
    async beforeMount() {
        await this.UserStore.fetchUserAddresses()
        const defaultAddress = this.UserStore.userAddresses.filter(address => address.defaultDeliveryAddress)
        if (defaultAddress.length) {
            await this.selectAddress(defaultAddress[0].addressUuid)
        } else if (this.UserStore.userAddresses.length) {
            await this.selectAddress(this.UserStore.userAddresses[0].addressUuid)
        }
    },
    methods: {
        googleAutocompleteClearAddressForm() {
            this.CheckoutStore.checkoutData.steps.delivery.data.street = null;
            this.CheckoutStore.checkoutData.steps.delivery.data.streetNumber = null;
            this.CheckoutStore.checkoutData.steps.delivery.data.city = null;
            this.CheckoutStore.checkoutData.steps.delivery.data.zip = null;
        },
        googleAutocompleteUpdateAddress() {
            this.CheckoutStore.checkoutData.steps.delivery.data.street = this.googleAutocompleteAddress.street;
            this.CheckoutStore.checkoutData.steps.delivery.data.streetNumber = this.googleAutocompleteAddress.streetNumber;
            this.CheckoutStore.checkoutData.steps.delivery.data.city = this.googleAutocompleteAddress.city;
            this.CheckoutStore.checkoutData.steps.delivery.data.zip = this.googleAutocompleteAddress.zip;
        },
        isPackstationAddress(type) {
            return type === 'packstation'
        },
        async selectAddress(addressUuid) {

            const address = this.getAddressByUuid(addressUuid)

            if (address) {

                this.CheckoutStore.setGender(address.gender)
                this.CheckoutStore.setFirstName(address.firstname)
                this.CheckoutStore.setLastName(address.lastname)
                this.CheckoutStore.setCompany(address.company)
                this.CheckoutStore.setZip(address.zip)
                this.CheckoutStore.setCity(address.city)
                this.CheckoutStore.setStreet(address.street)
                this.CheckoutStore.setStreetNumber(address.streetNumber)
                this.CheckoutStore.setCountry(address.country)
                this.CheckoutStore.setStreetExtra(address.streetExtra)
                this.CheckoutStore.setAddressType(address.addressType)

                this.CheckoutStore.setSelectedAddressIndex(address.addressUuid)
                this.CheckoutStore.setSelectedAddressUuid(address.addressUuid)
                await this.CartStore.fetchCartData({
                    'deliveryAddressUuid': this.CheckoutStore.checkoutData.deliveryAddressUuid?? null,
                    'deliveryCountry': address.country,
                })

                try {
                    const services = await this.CheckoutStore.getDeliveryServices({
                        country: address.country,
                        addressType: address.addressType
                    })
                    this.CheckoutStore.checkoutData.steps.delivery.options.deliveryService = services.data.services
                } catch (e) {
                    console.log(e)
                }
            }
        },
        getAddressByUuid(addressUuid) {
            for (const key in this.UserStore.userAddresses) {
                if (this.UserStore.userAddresses[key].addressUuid === addressUuid) {
                    return this.UserStore.userAddresses[key];
                }
            }

            return null;
        },
        getCountry(isoCode) {
            if (this.UserStore.userData) {
                for (const countryKey in this.UserStore.userData.countries) {
                    if (this.UserStore.userData.countries[countryKey].value === isoCode) {
                        return this.UserStore.userData.countries[countryKey].name;
                    }
                }
            }

            return 'Country not found';
        },
        sendForm() {

            this.$parent.showSpinner(this.$t('text.loading-spinner.save-checkout-data.delivery'))

            this.CheckoutStore.getToken(this.stepName).then(

                (result) => {

                    if (result.hasOwnProperty('data') && result.data.hasOwnProperty('token')) {

                        this.CheckoutStore.sendCheckoutData({ step: this.stepName, token: result.data.token }).then(
                            (response) => {
                                this.CartStore.fetchCartData().then(() => {
                                    this.$parent.hideSpinner()
                                })
                            }
                        )

                    }

                }

            ).then(() => {
                this.$parent.hideSpinner()
            })

        },
        retrieveError(field) {

            if (this.CheckoutStore.checkoutData.error.delivery && this.CheckoutStore.checkoutData.error.delivery[field]) {
                return this.CheckoutStore.checkoutData.error.delivery[field]
            }

            return '';

        },
        serviceHasAddressType(addressType) {
            let serviceList = this.CheckoutStore.checkoutData.steps.delivery.options.deliveryService;
            for (let item in serviceList) {
                if (
                    serviceList[item].value === this.CheckoutStore.checkoutData.steps.delivery.data.deliveryService
                    && serviceList[item].addressType === addressType
                ) {
                    return true
                }
            }
            return false
        },
        selectDeliveryService(serviceId) {
            let deliveryCountry = this.CheckoutStore.checkoutData.steps.address.data.country

            if (this.CheckoutStore.checkoutData.steps.delivery.data.addressDiffers === 'yes') {
                deliveryCountry = this.CheckoutStore.checkoutData.steps.delivery.data.country
            }

            this.CheckoutStore.checkoutData.steps.delivery.data.deliveryService = serviceId;
            return this.CartStore.fetchCartData({
                'serviceId': serviceId,
                'deliveryCountry': deliveryCountry,
                'deliveryAddressUUid': this.CheckoutStore.checkoutData.deliveryAddressUuid?? null,
            })
        },
        isSelectedDeliveryService(serviceId) {

            if (this.CheckoutStore.checkoutData.steps.delivery.data.deliveryService === serviceId) {
                return true
            }

            if (this.CheckoutStore.checkoutData.steps.delivery.options.deliveryService.length === 1) {
                this.selectDeliveryService(this.CheckoutStore.checkoutData.steps.delivery.options.deliveryService[0].value).then((response) => {
                    // @todo ggf. response checken
                })
            }

            return false;

        },
        hasAdditionalText(option) {
            return (option.additionalText) && (option.additionalText !== '');
        },
        changeDeliveryAddressSelection(element) {
            return this.UserStore.setDeliveryAddressSelection(element.value)
        },
        retrieveCountryName(countryCode) {

            if (!this.CheckoutStore.checkoutData.steps.delivery.options.country) {
                return ''
            }

            for(const index in this.CheckoutStore.checkoutData.steps.delivery.options.country) {
                if (this.CheckoutStore.checkoutData.steps.delivery.options.country[index].value === countryCode) {
                    return this.CheckoutStore.checkoutData.steps.delivery.options.country[index].name
                }
            }
        },
    },
}
</script>

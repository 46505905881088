<template>
    <div class="uk-grid-small uk-margin-small-top" uk-grid>
        <template v-if="loggedIn">
            <div v-if="alreadyInWishlist">
                <div class="uk-link uk-border-black uk-border-solid uk-text-middle uk-text-emphasis" @click="removeFromWishlist" >
                    <p class="uk-margin-small-left uk-margin-small-right uk-margin-remove-bottom">
                        <i class="fa-solid fa-trash-alt uk-margin-small-right" />
                        {{ $t('configurator.remove_from_wishlist') }}
                    </p>
                </div>
            </div>
            <div v-else>
                <div class="uk-link uk-border-black uk-border-solid uk-text-middle uk-text-emphasis" @click="addToWishlist">
                    <p class="uk-margin-small-left uk-margin-small-right uk-margin-remove-bottom">
                        <i class="fa-solid fa-bookmark uk-margin-small-right" />
                        {{ $t('configurator.add_to_wishlist') }}
                    </p>
                </div>
            </div>
        </template>
        <div>
            <div class="uk-link uk-border-black uk-border-solid uk-text-middle uk-text-emphasis uk-position-relative" @click="showShareButtons">
                <p class="uk-text- uk-padding-remove uk-margin-small-left uk-margin-small-right uk-margin-remove-bottom">
                    <i class="fa-solid fa-share-nodes uk-margin-small-right" />
                    {{ $t('configurator.share_configuration') }}
                </p>
                <div class="uk-padding-remove uk-width-medium" uk-dropdown="pos: bottom-center">
                    <div class="uk-flex uk-flex-wrap">
                        <div class="uk-width-1-2 uk-text-center">
                            <a :href="whatsAppLink" target="_blank" class="uk-button">
                                <i class="fa-2x fa-brands fa-whatsapp"></i>
                            </a>
                        </div>
                        <div class="uk-width-1-2 uk-text-center">
                            <a href="#" @click.prevent="toggleEmailInput" target="_blank" class="uk-button">
                                <i class="fa-2x fa-solid fa-envelope"></i>
                            </a>
                        </div>
                        <FloatingLabelInput
                            v-if="showEmailInput"
                            :label="$t('text.email')"
                            v-model="email"
                            :class="[
                                'uk-margin-medium-left',
                                'uk-margin-medium-right',
                                'uk-width-1-1'
                            ]"
                        />
                        <BaseButton
                            v-if="validEmail && showEmailInput"
                            :href="'mailto:' + email + '?subject=' + subject + '&body=' + body"
                            :fullWidth="true"
                            target="_blank"
                            :class="[
                                'uk-margin-medium-left',
                                'uk-margin-medium-right',
                                'uk-button',
                                'uk-button-primary',
                                'uk-margin-small-bottom',
                                'uk-width-1-1'
                            ]"
                        >
                            {{ $t('configurator.share_configuration') }}
                        </BaseButton>
                    </div>
                </div>
            </div>
        </div>


    </div>
</template>

<script>
import {useConfiguratorStore} from "../../store/ConfiguratorStore";
import {useCartStore} from "../../store/CartStore";
import {useUserStore} from "../../store/UserStore";
import BaseButton from "../form/base/BaseButton.vue";
import FloatingLabelInput from "../form/base/FloatingLabelInput.vue";

export default {
    name: 'ConfiguratorRememberShare',
    components: {FloatingLabelInput, BaseButton},
    data() {
        return {
            configuratorStore: useConfiguratorStore(),
            cartStore: useCartStore(),
            userStore: useUserStore(),
            showEmailInput: false,
            email: '',
            subject: '',
            body: ''
        }
    },
    async created() {
        await this.userStore.fetchUserData()
        this.subject = document.getElementsByTagName('title')[0].innerText
        this.body = window.location.href
    },
    computed: {
        whatsAppLink() {
            return 'https://wa.me/?text=' + window.location.href;
        },
        alreadyInWishlist() {
            if (this.cartStore.wishlistData.Items) {
                return this.cartStore.wishlistData.Items.some(item => {
                    return item.Artikelnummer === this.configuratorStore.configuratorProductUniqueIdentifier
                });
            }
            return false
        },
        loggedIn() {
            return this.userStore.userData.loggedIn
        },
        validEmail() {
            const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,8}$/
            return emailRegex.test(this.email)
        }
    },
    methods: {
        toggleEmailInput()
        {
            this.showEmailInput = !this.showEmailInput
        },
        addToWishlist() {
            this.cartStore.addCartItem({
                pid: this.configuratorStore.configuratorProduct.Id,
                cartItemsCount: 1,
                type: 'wishlist'
            })
        },
        removeFromWishlist() {
            this.cartStore.removeCartItem({
                pid: this.configuratorStore.configuratorProduct.Id,
                type: 'wishlist'
            })
        },
        showShareButtons() {

        }
    }
}
</script>
<template>
    <CartMobile />
    <div class="uk-grid-medium" uk-grid>
        <div uk-spinner="ratio: 3" class="uk-position-center" v-if="loading"></div>
        <template v-else-if="itemsQuantity">
            <div class="uk-width-1-1 uk-width-2-3@m">
                <!--
                    Wird mit Epic SAT-1559 nochmal überarbeitet
                //-->
                <!--div
                    v-if="itemsQuantity > 1 || isOnlyUnavailable"
                    class="uk-flex uk-text-small uk-border-solid uk-border-primary uk-padding-small uk-margin-medium-bottom"
                >
                    <div class="uk-padding-small">
                        <i class="fa-solid fa-2x fa-triangle-exclamation uk-float-left uk-text-primary"></i>
                    </div>
                    <div class="">
                        <template v-if="isOnlyUnavailable">
                            <p class="uk-text-primary">{{ $t('cart.partial-delivery-unavailable-info') }}</p>
                        </template>
                        <template v-else>
                            <p class="uk-text-primary">{{ $t('cart.partial-delivery-info') }}</p>
                            <BaseCheckbox
                                :label="$t('cart.partial-delivery-label')"
                                v-model="cartStore.cartData.partialDeliverySelected"
                                @update:modelValue="togglePartialDelivery"
                            />
                            <small class="uk-text-small">{{ $t('cart.partial-delivery-small') }}</small>
                        </template>
                    </div>
                </div//-->
                <div uk-spinner="ratio: 3" class="uk-position-center" v-if="!itemsQuantity"></div>
                <template
                    v-for="(items, state, index) in cartStore.cartItems"
                    :key="state"
                >
                    <h4 v-if="cartStore.cartData.partialDeliverySelected && itemsQuantity > 1">{{ $t('cart.partial-delivery-header', { count: index+1, quantity: itemsQuantity }) }}</h4>
                    <ItemList :item-list="items" />
                </template>
                <div class="uk-margin-large-top" v-if="cartStore.getavailableGiftItems.length > 0">
                    <SelectableGiftItems :items="cartStore.getavailableGiftItems" />
                </div>
                <div v-if="cartStore.giftItems.length > 0">
                    <h2>{{ $t('text.shop.giftItem') }}</h2>
                    <GiftItemList :item-list="cartStore.giftItems"></GiftItemList>
                </div>
            </div>
            <div class="uk-width-1-1 uk-width-1-3@m">
                <Summary :cart="cartStore.cartData"></Summary>
            </div>
        </template>
        <template v-else>
            <h2>{{ $t('text.shop.cart_empty') }}</h2>
        </template>
    </div>
</template>

<script>
import ItemList from "./ItemList.vue";
import Summary from "./Summary.vue";
import StickyElementMobile from "./StickyElementMobile.vue";
import {useCartStore} from "../../store/CartStore";
import GiftItemList from "./GiftItemList.vue";
import CartMobile from "./CartMobile.vue";
import BaseCheckbox from "../form/base/BaseCheckbox.vue";
import SelectableGiftItems from "./SelectableGiftItems.vue";

export default {
    name: "CartFullView",
    data() {
        return {
            loading: true,
            itemsQuantity: 0
        }
    },
    async created() {
        await this.cartStore.fetchCartData()
        await this.cartStore.hideCartItem()
        this.loading = false
    },
    computed: {
        isOnlyUnavailable() {
            const cartItems = this.cartStore.cartItems;
            return Object.keys(cartItems).length === 1 &&
                'available-not' in cartItems &&
                cartItems['available-not'].length > 0;
        },
        itemsQuantity() {
            return Object.keys(this.cartStore.cartItems).length
        }
    },
    methods: {
        async togglePartialDelivery() {
            // Wird mit Epic SAT-1559 nochmal überarbeitet
            // await this.cartStore.setPartialDelivery(this.cartStore.cartData.partialDeliverySelected)
        }
    },
    setup() {
        const cartStore = useCartStore()
        return {
            cartStore
        }
    },
    components: {
        SelectableGiftItems,
        CartMobile,
        GiftItemList,
        StickyElementMobile,
        Summary,
        ItemList
    },
}
</script>
<template>

    <div v-if="order">

        <h1 class="ws-text-sauterblau">{{ $t('text.user.profile.order.order-from-date') }} {{ order.OrderDate }}</h1>

        <p>{{ $t('text.user.profile.your-customer-number') }}: <span class="uk-text-bold">{{ userStore.getCustomerNumber }}</span></p>

        <p class="uk-padding-large uk-padding-remove-horizontal uk-padding-remove-bottom uk-text-bold">{{ $t('text.user.profile.order.total-price')}}: {{ order.TotalPriceString }}</p>

        <div class="uk-margin-large-top uk-grid-medium" uk-grid>

            <div class="uk-width-1-1 uk-width-1-3@m">

                <h5>{{ $t('text.user.profile.order-detail.billing-address')}}</h5>
                <p>
                    {{ order.BillingAddress.street }} {{ order.BillingAddress.streetNumber }}<br>
                    <span v-if="order.BillingAddress.streetExtra">{{ order.BillingAddress.streetExtra }}<br></span>
                    {{ order.BillingAddress.zip }} {{ order.BillingAddress.city }}<br>
                    {{ order.BillingAddress.country }}
                </p>

            </div>

            <div class="uk-width-1-1 uk-width-1-3@m">

                <h5>{{ $t('text.user.profile.order-detail.delivery-address')}}</h5>
                <p>
                    {{ order.DeliveryAddress.street }} {{ order.DeliveryAddress.streetNumber }}<br>
                    <span v-if="order.DeliveryAddress.streetExtra">{{ order.DeliveryAddress.streetExtra }}<br></span>
                    {{ order.DeliveryAddress.zip }} {{ order.DeliveryAddress.city }}<br>
                    {{ order.DeliveryAddress.country }}
                </p>

            </div>

            <div class="uk-width-1-1 uk-width-1-3@m">
                <h5>{{ $t('text.user.profile.order-detail.payment-method')}}</h5>
                <p>{{ $t(order.PaymentMethod) }}</p>
            </div>

        </div>

        <hr class="uk-hr">

        <div>
            <p>{{ $t('text.user.profile.order.order-number') }}: <span class="uk-text-uppercase">{{ order.OrderNumber }}</span></p>
            <p>
                {{ $t('text.user.profile.order.order-state') }}:
                <span
                    :class="'ws-order-state-' + order.OrderState"
                >
                        {{ $t(String(order.OrderStateText)) }}
                    </span>
            </p>
        </div>
        <div class="uk-hei">
            <div class="uk-flex uk-flex-wrap uk-flex-wrap-around uk-child-width-1-1 uk-child-width-1-3@m uk-child-width-1-4@l">
                <template v-if="order.TrackingLinks">
                    <div class="uk-margin-medium-top"
                        v-for="(trackingLink, index) in order.TrackingLinks"
                        :key="index"
                    >
                        <a
                            :href="trackingLink"
                            class="uk-link uk-text-secondary"
                            target="_blank"
                        >
                            <i class="fa-light fa-truck-clock"></i>
                            {{ $t('text.user.profile.tracking.tracking_link_name') }}
                        </a>
                    </div>
                </template>
                <div v-if="order.HasInvoice" class="uk-margin-medium-top">
                    <i class="fa-light fa-file-arrow-down uk-margin-small-right"></i>
                    <a :href="'/user/order/invoice/' + order.OrderId + '/pdf'">
                        {{ $t('text.user.profile.order-detail.download-invoice') }}
                    </a>
                </div>
            </div>
        </div>

        <div v-if="(getItems()).length > 0" class="uk-margin-large-top">
            <h5>{{ $t('text.user.profile.order-detail.order-item-list') }}</h5>
            <hr class="uk-hr">
            <OrderItem v-for="orderItem in getItems()" :order-currency="order.Currency" :order-item="orderItem"></OrderItem>
            <OrderItem v-for="orderItem in getItems(true)" :order-currency="order.Currency" :order-item="orderItem"></OrderItem>
        </div>

        <div v-if="(getItems(true)).length > 0" class="uk-margin-large-top">
            <h5>{{ $t('text.user.profile.order-detail.order-free-item-list') }}</h5>
            <hr class="uk-hr">
            <OrderItem v-for="orderItem in getGiftItems()" :order-currency="order.Currency" :order-item="orderItem"></OrderItem>
        </div>

        <div class="uk-margin-large-top">
            <a href="#" @click.prevent="userProfileStore.setCurrentOrderId(0)">{{ $t('text.user.profile.order.back-link') }}</a>
        </div>
    </div>

</template>

<script>

import { useUserStore } from "../../../../store/UserStore";
import { useUserProfileStore} from "../../../../store/UserProfileStore";
import OrderItem from "./OrderItem.vue";
import ApiService from "../../../../service/ApiService"

export default {
    name: 'OrderDetails',
    components: { OrderItem },
    props: {
        order: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            userStore: useUserStore(),
            userProfileStore: useUserProfileStore()
        }
    },
    methods: {
        getItems(isFree = false) {

            if (!this.order.Items) {
                return []
            }

            let items = []

            this.order.Items.forEach(
                (item) => {
                    if (
                        (isFree === true && Math.ceil(item.ProductPriceGross) === 0)
                        || (isFree === false && Math.ceil(item.ProductPriceGross) > 0)
                    ) {
                        items.push(item)
                    }
                }
            )

            return items

        },
        getGiftItems() {
            return this.order.GiftItems;
        }
    }
};
</script>

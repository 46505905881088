export default {
    track(data, actionKey) {
        if(!data.trackingCallback) {
            return;
        }

        // For debugging
        // console.log(actionKey)

        dataLayer.push({ ecommerce: null });
        eval(data.trackingCallback)
    },
    trackCheckout(data){
        this.track(data, data.current)
    },
    trackCheckoutByStep(number) {
        dataLayer.push({
            event: "checkout_step",
            checkout_step: number
        });
    },
}

// SAT-932
export const TrackingCheckoutStepCode = {
    checkOrder: 4,
    sendOder: 5,
}
<template>
    <div class="ws-cart-button-container uk-flex uk-flex-middle" uk-grid>
        <div v-if="isTextMode && showAmountInput" class="uk-width-1-1 uk-width-1-3@m uk-width-1-4@l">
            <AmountInput :initial-count="itemsCount" @onChange="(count) => itemsCount = count" />
        </div>
        <div v-if="isTextMode" class="uk-width-1-6@m uk-visible@l">&nbsp;</div>
        <div class="uk-width-1-1 uk-width-expand@m">
            <button
                type="button"
                @click="this.handleAddToCart"
                    :class="['uk-button', 'uk-button-default', 'uk-width-1-1']">
                <span v-if="isTextMode">{{ $t('text.shop.add-to-cart') }}</span>
                <span v-else><i class="fa-light fa-cart-shopping" /></span>
            </button>
        </div>
    </div>
</template>

<script>
import { useCartStore } from "../../store/CartStore"
import AmountInput from "./AmountInput.vue";

export default {
    name: "ButtonInput",
    components: {AmountInput},
    props: {
        pid: {
            type: Number,
            default: 0,
            required: true,
        },
        isInWishlist: {
            type: Boolean,
            default: false,
            required: false
        },
        showAmountInput: {
            type: Boolean,
            default: true,
            required: false
        }
    },
    data() {
        return {
            itemsCount: 1,
            clicked: ""
        }
    },
    setup() {
        const cartStore = useCartStore()
        return {
            cartStore
        }
    },
    methods: {
        async addToCart() {
            this.cartStore.addCartItem({pid: parseInt(this.pid), cartItemsCount: this.itemsCount, type: 'cart'}).then(() => {
                this.cartStore.fetchCartData({})
            })
        },
        async removeFromWishlist() {
            this.cartStore.removeCartItem({pid: parseInt(this.pid), type: 'wishlist'}).then(() => {
                this.cartStore.fetchCartData({}, 'wishlist')
            })
        },

        async handleAddToCart() {
            await this.addToCart()

            if (this.isInWishlist === true) {
                await this.removeFromWishlist()
            }
        }
    },
    computed: {
        isTextMode() {
            return (this.textmode && this.textmode === '1')
        }
    }
}
</script>
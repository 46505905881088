<template>
    <a :href="href" :class="cssClasses">
        <span class="uk-padding-remove-horizontal">
            <span class="uk-margin-small-right uk-padding-remove" v-if="loading" uk-spinner="ratio: 0.5"></span>
            <slot>Button text</slot>
        </span>
    </a>
</template>

<script>

export default {
    name: 'BaseButton',
    props: {
        displayMode: {
            type: String,
            default: 'default'
        },
        href: {
            type: String,
            required: true
        },
        fullWidth: {
            type: Boolean,
            default: false
        },
        loading: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        cssClasses() {
            const widthClass = this.fullWidth ? ' uk-width-1-1' : ''
            return 'uk-button uk-button-' + this.displayMode + widthClass + ' ' + (this.loading ? 'uk-disabled' : '')
        }
    }
}
</script>
<template>
    <div class="uk-flex uk-border-solid uk-border-primary ws-global-element-border">
        <div :class="{
            'uk-background-primary uk-light ws-cursor-pointer': count > 1,
            'uk-background-default uk-text-muted': count < 2
        }" class="uk-padding-small" @click="() => stepCount(-1)">
            <i class="fa-md fa-solid fa-square-minus"></i>
        </div>
        <div class="uk-flex uk-flex-middle uk-width-expand">
            <input
                class="uk-input uk-text-center uk-form-blank uk-margin-small-right uk-margin-small-left"
                type="text"
                v-model="count"
            />
            <div v-if="isTextMode" uk-dropdown="mode: click; pos: bottom-center; shift: false; flip: false; animation: slide-top; animate-out: true"
                 :id="'cartQuantityDropDown'"
                 class="uk-height-medium uk-overflow-auto uk-padding-small">
                <div class="uk-text-center">
                    <div v-for="count in [...Array(CartConstants.CartMaxSelectAmount).keys()].map(x => ++x)"
                         :key="count"
                    >
                        <button
                            @click.prevent="() => setCount(count)"
                            :class="['uk-button', 'uk-button-muted', 'uk-width-expand', 'uk-margin-small-bottom']"
                        >
                            {{ count }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div :class="{
            'uk-background-primary uk-light ws-cursor-pointer': count < CartConstants.CartMaxSelectAmount,
            'uk-background-default uk-text-muted': count > CartConstants.CartMaxSelectAmount-1
            }"
            class="uk-padding-small" @click="() => stepCount(1)">
            <i class="fa-md fa-solid fa-square-plus" />
        </div>
    </div>
</template>

<script>
import CartConstants from "../../constants/cartConstants";
import { dropdown } from 'uikit';

export default {
    name: "AmountInput",
    props: {
        initialCount: {
            type: String,
            required: true,
        },
        setTextMode: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            count: this.initialCount
        }
    },
    watch: {
        count(newVal) {
            const final = Math.max(1, Math.min(newVal, this.CartConstants.CartMaxSelectAmount));
            this.count = final;
            this.$emit('onChange', final);
        }
    },
    methods: {
        hideDropdown() {
            dropdown('#cartQuantityDropDown').hide();
        },
        stepCount(newValue) {
            this.count = this.count + newValue;
        },
        setCount(newValue) {
            this.count = newValue;
            this.hideDropdown()
        }
    },
    computed: {
        CartConstants() {
            return CartConstants
        },
        isTextMode() {
            return (this.textmode && this.textmode === '1') || this.setTextMode
        }
    }
}
</script>
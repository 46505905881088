<template>
    <div class="uk-margin-large-top">
        <h3>{{ $t('text.user.profile.personal-data.edit.header') }}</h3>
        <hr />
        <div class="uk-width-1-1 uk-width-1-2@m">
            <h4 class="uk-margin-medium-bottom uk-margin-large-top">{{ $t('text.user.profile.personal-data.edit.headline') }}</h4>
            <div class="uk-margin uk-grid-small uk-child-width-auto uk-grid">
                <label><input class="uk-radio" type="radio" :value="'male'" v-model="userStore.userData.Gender"> {{ $t('text.customer.salutation.male') }}</label>
                <label><input class="uk-radio" type="radio" :value="'female'" v-model="userStore.userData.Gender"> {{ $t('text.customer.salutation.female')}}</label>
                <label><input class="uk-radio" type="radio" :value="'diverse'" v-model="userStore.userData.Gender"> {{ $t('text.customer.salutation.diverse') }}</label>
            </div>
            <div class="uk-margin uk-grid-small uk-child-width-auto uk-grid uk-margin-large-bottom">
                <label v-for="type in userStore.userData.types">
                    <input class="uk-radio" type="radio" :value="type.value" v-model="userStore.userData.CustomerType"> {{ type.value }}
                </label>
            </div>
            <FloatingLabelInput
                v-model="firstName"
                :error-message="firstNameError"
                :label="$t('text.user.profile.personal-data.form.firstname')"
                type="input"
                :class="['uk-width-1-1 uk-margin-small-top uk-margin-remove-bottom']"
            />
            <FloatingLabelInput
                v-model="lastName"
                :error-message="lastNameError"
                :label="$t('text.user.profile.personal-data.form.lastname')"
                type="input"
                :class="['uk-width-1-1 uk-margin-medium-top uk-margin-remove-bottom']"
            />
            <FloatingLabelInput
                v-model="emailInput"
                :error-message="emailError"
                :label="$t('text.user.profile.personal-data.form.email')"
                type="email"
                :class="['uk-width-1-1 uk-margin-medium-top uk-margin-remove-bottom']"
            />
            <FloatingLabelInput
                v-model="emailConfirm"
                :error-message="emailConfirmError"
                :label="$t('text.user.profile.personal-data.form.emailConfirm')"
                type="email"
                :class="['uk-width-1-1 uk-margin-medium-top uk-margin-remove-bottom']"
            />
            <FloatingLabelInput
                v-model="phone"
                :error-message="phoneError"
                :label="$t('text.user.profile.personal-data.form.phone')"
                type="input"
                :class="['uk-width-1-1 uk-margin-medium-top uk-margin-remove-bottom']"
            />
            <BaseButton
                :href="'#'"
                :text="$t('text.user.profile.personal-data.form.text_button_submit')"
                :class="['uk-margin-large-top uk-padding-small']"
                @click="submitProfile"
            />
            <span class="uk-float-right uk-margin-small-top">{{ $t('text.checkout.note.mandatory-fields') }}</span>

            <div class="uk-margin-medium-top">
                <a class="" href="#" @click.prevent="closeEdit()">{{ $t('text.user.profile.personal-data.back_to_personal_data') }}</a>
            </div>
        </div>
    </div>
</template>
<script>
import {defineComponent} from 'vue'
import BaseButton from "../../../form/base/BaseButton.vue";
import FloatingLabelInput from "../../../form/base/FloatingLabelInput.vue";

import { useUserStore } from "../../../../store/UserStore";
import { useUserProfileStore } from "../../../../store/UserProfileStore";
import { useField, useForm } from 'vee-validate';
import { required, email } from '@vee-validate/rules';
import { notification } from "uikit";

export default defineComponent({
    name: "PersonalForm",
    components: {FloatingLabelInput, BaseButton},
    setup() {

        const { handleSubmit, meta } = useForm();
        const formSubmitting = handleSubmit((values) => values)

        const userStore = useUserStore()
        const userProfileStore = useUserProfileStore()

        const { value: firstName, errorMessage: firstNameError } = useField('firstName', required);
        const { value: lastName, errorMessage: lastNameError } = useField('lastName', required);
        const { value: emailInput, errorMessage: emailError } = useField('emailInput', [required, email]);
        const { value: emailConfirm, errorMessage: emailConfirmError } = useField('emailConfirm', [required, email, value => {
            if (emailInput.value !== value) {
                return 'E-Mail must match';
            }
            return true;
        }]);
        const { value: phone, errorMessage: phoneError } = useField('phone', required);

        return {
            firstName,
            firstNameError,
            lastName,
            lastNameError,
            emailInput,
            emailError,
            emailConfirm,
            emailConfirmError,
            phone,
            phoneError,
            userStore,
            userProfileStore,
            handleSubmit,
            formSubmitting,
            meta
        }
    },
    mounted() {
        if (!this.emailInput && this.userStore.userData.Email) {
            this.emailInput = this.userStore.userData.Email
        }
        if (!this.emailConfirm && this.userStore.userData.Email) {
            this.emailConfirm = this.userStore.userData.Email
        }
        if (!this.firstName && this.userStore.userData.Firstname) {
            this.firstName = this.userStore.userData.Firstname
        }
        if (!this.lastName && this.userStore.userData.Lastname) {
            this.lastName = this.userStore.userData.Lastname
        }
        if (!this.phone && this.userStore.userData.Phone) {
            this.phone = this.userStore.userData.Phone
        }
    },
    methods: {
        showNotification(message, status = "success") {
            const timeout = 5000
            notification({
                message: this.$t(message),
                status: status,
                pos: 'top-center',
                timeout: timeout
            })
            setTimeout(() => this.privacyError = false, timeout)
        },
        async submitProfile() {
            await this.formSubmitting();
            if (this.meta.valid) {
                this.userProfileStore.patchProfile({
                    firstName: this.firstName,
                    lastName: this.lastName,
                    email: this.emailInput,
                    phone: this.phone,
                    gender: this.userStore.userData.Gender,
                    customerType: this.userStore.userData.CustomerType
                })
                .then(() => {
                    this.userStore.userData.Email = this.emailInput;
                    this.userStore.userData.Firstname = this.firstName;
                    this.userStore.userData.Lastname = this.lastName;
                    this.userStore.userData.Phone = this.phone;

                    this.showNotification('text.user.profile.personal-data.form.text_button_submit_success')
                })
                .catch((error) => {
                    this.showNotification('text.user.profile.personal-data.form.text_button_submit_not_success', 'warning')
                })
                    .finally(() => this.userProfileStore.setPersonalDataEditMode());
            }
        },
        closeEdit() {
            this.userProfileStore.setPersonalDataEditMode()
        }
    }
})
</script>
<template>
    <div class="AccountApp">
        <ul class="uk-child-width-expand ws-tab" uk-tab="">
            <li class="uk-active uk-margin-small-top">
                <a href="#" class="ws-global-element-border">{{ $t("text.user_login_tab") }}</a>
            </li>
            <li class="uk-margin-small-top ws-global-element-border">
                <a href="#" class="ws-global-element-border">{{ $t("text.user_registration_tab") }}</a>
            </li>
        </ul>
        <ul class="uk-switcher">
            <li><UserLoginForm></UserLoginForm></li>
            <li><UserRegistrationForm></UserRegistrationForm></li>
        </ul>
    </div>
</template>

<script>
import UserLoginForm from "./UserLoginForm.vue";
import UserRegistrationForm from "./UserRegistrationForm.vue";

export default {
    name: 'AccountApp',
    components: {
        UserLoginForm,
        UserRegistrationForm
    }
};
</script>

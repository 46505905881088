<template>
    <div>
        <div :uk-grid="horizontal ? '' : null" :class="{'uk-width-auto': horizontal}">
            <div class="ws-radio-label" v-if="label">
                {{label}}<span v-if="required"><sup>*</sup></span>
            </div>
            <div v-for="(option, index) in options" class="ws-radio" :class="{'uk-width-auto': horizontal, 'error': !!errorMessage}" >
                <input
                    type="radio"
                    :checked="modelValue === option.value"
                    :value="option.value"
                    @change="$emit('update:modelValue', option.value)"
                    class="uk-radio uk-margin-small-right"
                    :id="uuid + '-' + index"
                    :aria-describedby="errorMessage ? uuid + '-' + index + '-error' : null"
                    :aria-invalid="errorMessage ? true : null"
                />
                <label :for="uuid + '-' + index" v-html="$t(option.name)"></label>
            </div>
        </div>
        <p
            class="ws-checkout-error-field uk-text-danger"
            v-if="errorMessage"
            :id="uuid + '-' + index + '-error'"
            aria-live="assertive"
        >
            {{ $t(errorMessage) }}
        </p>
    </div>
</template>

<script>
import UniqueID from "../../../includes/UniqueID.js";

export default {
    props: {
        label: {
            type: String,
            default: ''
        },
        modelValue: {
            type: [String, Number],
            default: ''
        },
        options: {
            type: Array,
            required: true
        },
        horizontal: {
            type: Boolean,
            default: true
        },
        errorMessage: {
            type: String,
            default: ''
        },
        required: {
            type: Boolean,
            default: true
        }
    },
    setup() {
        const uuid = UniqueID().getID()
        return {
            uuid
        }
    }
}
</script>
